import { Icon } from 'components/Icon'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

const ToolbarBreadcrumbsRoot = styled.div`
    align-items: center;
    display: flex;
    gap: 2px;

    svg {
        display: block;
    }

    > a {
        display: block;
        color: inherit !important;
    }
`

export function ToolbarBreadcrumbs({ children }: { children?: ReactNode }) {
    return <ToolbarBreadcrumbsRoot>{children}</ToolbarBreadcrumbsRoot>
}

const ToolbarBreadcrumbsSeparatorRoot = styled.div`
    align-items: center;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    height: 12px;
    justify-content: center;
    width: 12px;
`

export function ToolbarBreadcrumbsSeparator() {
    return (
        <ToolbarBreadcrumbsSeparatorRoot>
            <Icon name="chevronRight" />
        </ToolbarBreadcrumbsSeparatorRoot>
    )
}

export const ToolbarBreadcrumbsTextItem = styled.small<{ $dimm?: boolean }>`
    display: block;
    padding: 2px 6px;

    ${({ $dimm = false }) =>
        $dimm &&
        css`
            color: ${themeVariables.palettes.neutral700};
        `}
`
