import uniqueId from 'lodash/uniqueId'
import React, { MouseEvent, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

type SanitizedBarChartEntry = {
    color: string
    force: boolean
    id: string
    label: string
    value: number
    onClick?(
        event: MouseEvent<HTMLButtonElement>,
        entry: SanitizedBarChartEntry
    ): void
}

export type BarChartEntry =
    | number
    | {
          color?: string
          force?: boolean
          label?: string
          onClick?(
              event: MouseEvent<HTMLButtonElement>,
              entry: SanitizedBarChartEntry
          ): void
          value?: number
      }

const Bar = styled.div`
    border-radius: 3px;
    height: 6px;
    margin-bottom: 4px;
`

const BarButton = styled.button<{ $interactive?: boolean }>`
    appearance: none;
    border: 0;
    outline: 0 !important;
    background: none;
    padding: 0;
    text-align: left;

    ${({ $interactive = false }) =>
        !$interactive &&
        css`
            pointer-events: none;
        `}
`

const BarChartRoot = styled.div`
    display: grid;
    gap: 2px;
    height: 26px;
    overflow: visible;
    width: 100%;
    max-width: 180px;
`

function sanitizeEntry(entry: BarChartEntry): SanitizedBarChartEntry {
    return typeof entry === 'number'
        ? {
              color: themeVariables.palettes.neutral400,
              force: false,
              id: uniqueId('Data-'),
              label: `${entry}`,
              value: entry,
          }
        : {
              ...entry,
              color: entry.color || themeVariables.palettes.neutral400,
              force: entry.force || false,
              id: uniqueId('Data-'),
              label: entry.label || `${entry.value || 0}`,
              value: entry.value || 0,
          }
}

interface BarChartProps {
    data: BarChartEntry[]
    fallbackEntry?: BarChartEntry
}

export function BarChart({ data: dataProp, fallbackEntry }: BarChartProps) {
    const data = useMemo(
        () =>
            dataProp
                .map(sanitizeEntry)
                .filter(({ value, force }) => force || value),
        [dataProp]
    )

    const gridTemplateColumns =
        data.map(({ value }) => (value ? `${value}fr` : 'auto')).join(' ') ||
        undefined

    const finalData = data.length
        ? data
        : fallbackEntry
          ? [sanitizeEntry(fallbackEntry)]
          : []

    return (
        <BarChartRoot
            style={{
                gridTemplateColumns,
            }}
        >
            {finalData.map((entry) => (
                <BarButton
                    key={entry.id}
                    onClick={(e) => entry.onClick?.(e, entry)}
                    $interactive={!!entry.onClick}
                >
                    <Bar style={{ background: entry.color }} />
                    <small>{entry.label}</small>
                </BarButton>
            ))}
        </BarChartRoot>
    )
}
