import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { BsArrowLeftShort } from 'react-icons/bs'
import { FormCheck } from '../components/Form/FormCheck'
import { FormText } from '../components/Form/FormText'
import { CheckEmail } from '../components/CheckEmail'
import Logo from '../assets/images/icons/signin-logo.svg'
import { ErrorAlert } from '../components/ErrorAlert'
import { TermsAndConditionsModal } from '../components/TermsAndConditionsModal'

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Username is required')
        .min(4, 'Username should be at least 4 characters'),
    email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is required'),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Retyping password is required'),
    termsAccepted: yup
        .boolean()
        .oneOf([true], 'Accepting the terms is required'),
})

export const SignUp = () => {
    const [userEmail, setUserEmail] = useState('')
    const [submitForm, setSubmitForm] = useState(false)
    const [registerError, setRegisterError] = useState<string>('')
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const signup = (userData: {
        username: string
        email: string
        password: string
        termsAccepted: boolean
    }) => {
        // leaving this "empty" implementation after removing the GraphQL stuff
        setRegisterError('')
        setUserEmail(userData.email)
        navigate(`/activate-account/${'NEWLY_CREATED_USER_ID_HERE'}`)
    }

    const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setShowModal(true)
    }

    return (
        <section className="account">
            {!submitForm ? (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="w-100 text-center">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        alt="Silta logo"
                                        className="account__logo"
                                    />
                                </Link>
                                <h2 className="account__title">
                                    Create an account
                                </h2>
                                <p className="account__text">
                                    Enter the details to create your account
                                </p>
                            </div>
                            {registerError && (
                                <ErrorAlert error={registerError} />
                            )}
                            <div className="w-100">
                                <div>
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            email: '',
                                            password: '',
                                            passwordConfirmation: '',
                                            termsAccepted: false,
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            signup({
                                                username: values?.name,
                                                email: values?.email,
                                                password: values?.password,
                                                termsAccepted:
                                                    values?.termsAccepted,
                                            })
                                            // submit to backend
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <FormText
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Enter your name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                    className={
                                                        touched.name &&
                                                        errors.name
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Name"
                                                />

                                                <FormText
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={
                                                        touched.email &&
                                                        errors.email
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Email"
                                                />
                                                <FormText
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Create your password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    className={
                                                        touched.password &&
                                                        errors.password
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Password"
                                                    hint="Must be at least 8 characters"
                                                />
                                                <div className="mt-5">
                                                    <FormText
                                                        type="password"
                                                        name="passwordConfirmation"
                                                        id="passwordConfirmation"
                                                        placeholder="Verify your password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={
                                                            values.passwordConfirmation
                                                        }
                                                        className={
                                                            touched.passwordConfirmation &&
                                                            errors.passwordConfirmation
                                                                ? 'error'
                                                                : ''
                                                        }
                                                        label="Repeat password"
                                                    />
                                                </div>
                                                <FormCheck
                                                    id="termsAccepted"
                                                    name="termsAccepted"
                                                    value={
                                                        values.termsAccepted
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                    onChange={handleChange}
                                                    type="checkbox"
                                                    className={`
                            ${
                                touched.termsAccepted && errors.termsAccepted
                                    ? 'error-checkbox'
                                    : ''
                            }
                              my-1
                              `}
                                                    label={
                                                        <div className="label-terms">
                                                            <span>
                                                                I have read and
                                                                accept the{' '}
                                                            </span>
                                                            <button
                                                                className="label-terms__btn"
                                                                onClick={
                                                                    handleOpen
                                                                }
                                                            >
                                                                {' '}
                                                                terms and
                                                                conditions
                                                            </button>
                                                            <span>
                                                                {' '}
                                                                for the Silta
                                                                dApp
                                                                registration.
                                                            </span>
                                                        </div>
                                                    }
                                                    classNameCheck="no-bg-sigunp"
                                                />
                                                <div className="error-message-checkbox">
                                                    {touched.termsAccepted &&
                                                        errors.termsAccepted &&
                                                        errors.termsAccepted}
                                                </div>

                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="account__btn my-4"
                                                >
                                                    Sign Up
                                                </Button>
                                                <div className="mb-3 text-center">
                                                    Already have an
                                                    account?&nbsp;
                                                    <Link to="/login">
                                                        Log in
                                                    </Link>
                                                </div>
                                                {showModal && (
                                                    <TermsAndConditionsModal
                                                        show={showModal}
                                                        onHide={() =>
                                                            setShowModal(false)
                                                        }
                                                    />
                                                )}
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="login-back w-100 d-flex align-items-center justify-content-center">
                                    <Link to="/login">
                                        <BsArrowLeftShort />
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CheckEmail
                    userEmail={userEmail}
                    setSubmitForm={setSubmitForm}
                />
            )}
        </section>
    )
}
