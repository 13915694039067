import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EmailIcon from '../assets/images/icons/email-icon.svg'
import Arrow from '../assets/images/arrow_grey_noborder.svg'

export const CheckEmail = ({
    userEmail,
    setSubmitForm,
}: {
    userEmail: string
    setSubmitForm: (value: boolean) => void
}) => (
    <div className="container mt-5">
        <div className="row justify-content-center">
            <div className="col-sm-4 text-center">
                <div className="w-100">
                    <img
                        className="mb-4"
                        src={EmailIcon}
                        alt="email sent for verification"
                    />
                    <h2 className="account__title">Check your email</h2>
                    <p className="account__text">
                        We sent a verification link to <br />
                        {userEmail}
                    </p>
                </div>
                <div className="text-center mb-4 account__resend-email">
                    <span> Didn’t receive the email? </span>
                    {'  '}
                    <Link to="/">Click to resend</Link>
                </div>
                <Button
                    className="account__back"
                    onClick={() => setSubmitForm(false)}
                >
                    <img src={Arrow} alt="arrow" />
                    <span className="mx-3 account__backto-login">
                        Back to sign up
                    </span>
                </Button>
            </div>
        </div>
    </div>
)
