import React, { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Button } from '../Button'

export const Toolbar = styled.div<{
    $gap?: string
    $grow?: boolean
    $pad?: boolean
}>`
    align-items: center;
    border-bottom: 1px solid ${themeVariables.palettes.neutral300};
    display: flex;
    gap: ${({ $gap = '8px' }) => $gap};
    height: 40px;
    flex-grow: ${({ $grow = false }) => ($grow ? 1 : undefined)};
    padding: 0 40px;

    & & {
        border-bottom: 0;
        padding: 0;
    }
`

export const CustomButton = styled(Button)`
    height: 24px;
    display: flex;
    gap: 4px;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;

    svg {
        display: block;
    }
`

const ToolbarButtonLabel = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`

const ToolbarButtonInner = styled.div`
    text-wrap: nowrap;
`

export interface ToolbarButtonProps
    extends Omit<HTMLAttributes<HTMLButtonElement>, 'type'> {
    disabled?: boolean
    prepend?: ReactNode
    append?: ReactNode
    elevation?: 'none' | 'reduced'
}

export function ToolbarButton({
    append,
    children,
    disabled,
    elevation,
    prepend,
    ...props
}: ToolbarButtonProps) {
    return (
        <CustomButton
            {...props}
            disabled={disabled}
            type="button"
            $variant="secondary"
            $size="small"
            $elevation={elevation}
        >
            <ToolbarButtonLabel>
                {prepend}
                <ToolbarButtonInner>{children}</ToolbarButtonInner>
            </ToolbarButtonLabel>
            {append}
        </CustomButton>
    )
}
