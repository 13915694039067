import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import {
    Description,
    HiddenInput,
    Label,
    CheckboxFieldInner as RadioFieldInner,
} from './CheckboxField'
import { Radio, RadioParentCSS, RadioRoot } from './Radio'

const RadioFieldRoot = styled.label<{ $disabled?: boolean }>`
    cursor: pointer;
    display: block;
    width: max-content;

    ${RadioParentCSS}

    ${RadioRoot} {
        transform: translateY(2px);
    }

    ${({ $disabled = false }) =>
        $disabled &&
        css`
            cursor: default;
        `}
`

interface RadioFieldProps<T extends string = string> {
    checked?: boolean
    description?: ReactNode
    disabled?: boolean
    label?: ReactNode
    name?: string
    onChange?(value: T): void
    value: T
}

export function RadioField<T extends string = string>(
    props: RadioFieldProps<T>
) {
    const {
        checked = false,
        description,
        disabled = false,
        label,
        name,
        onChange,
        value,
    } = props

    return (
        <RadioFieldRoot $disabled={disabled}>
            <HiddenInput
                checked={checked}
                disabled={disabled}
                name={name}
                onChange={() => onChange?.(value)}
                type="radio"
                value={value}
            />
            <RadioFieldInner>
                <Radio checked={checked} disabled={disabled} />
                <div>
                    <Label>{label || value}</Label>
                    {!!description && <Description>{description}</Description>}
                </div>
            </RadioFieldInner>
        </RadioFieldRoot>
    )
}
