import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

type ButtonVariant = 'primary' | 'secondary' | 'destructive'
type ButtonSize = 'small' | 'medium' | 'large'

export const Button = styled.button<{
    $variant?: ButtonVariant
    $size?: ButtonSize
    disabled?: boolean
    $elevation?: 'default' | 'reduced' | 'none'
}>`
    --button-elevation-shadow: 0px 2px 5px 0px #3c425714;
    appearance: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 500;
    height: fit-content;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    ${({
        $variant,
        $elevation = $variant === 'secondary' ? 'default' : 'none',
    }) =>
        $elevation !== 'none' &&
        css`
            box-shadow:
                0px 1px 1px 0px #0000001f,
                0px 0px 0px 1px #3c425729,
                var(--button-elevation-shadow);
        `}

    ${({ $elevation }) =>
        $elevation === 'reduced' &&
        css`
            --button-elevation-shadow: 0 0 transparent;

            font-weight: 400 !important;
        `}

    &:active {
        box-shadow:
            0px 2px 5px 0px #40445214,
            0px 0px 0px 4px #0196ed5c,
            0px 0px 0px 1px #40445229,
            0px 1px 1px 0px #0000001f;
    }

    ${({ $size = 'medium' }) => {
        switch ($size) {
            case 'small':
                return `
                font-size: ${themeVariables.typography.fontSizes.caption};
                line-height: ${themeVariables.typography.lineHeight.caption};
                padding: 4px 8px;
            `
            case 'large':
                return `
                font-size: ${themeVariables.typography.fontSizes.large};
                line-height: ${themeVariables.typography.lineHeight.large};
                padding: 8px 16px;
            `
            case 'medium':
            default:
                return `
                font-size: ${themeVariables.typography.fontSizes.body};
                line-height: ${themeVariables.typography.lineHeight.body};
                padding: 4px 8px;
                `
        }
    }}

    ${({ $variant = 'primary', disabled }) => {
        switch ($variant) {
            case 'secondary':
                return `
                background-color: ${themeVariables.colors.backgroundSurface};
                border: none;
                margin: 1px;
                color: ${themeVariables.colors.primary};
                font-weight: 500;
                &:hover, &:active {
                    background-color: ${themeVariables.colors.backgroundContainer};
                }

            `
            case 'destructive':
                return `
                background-color: ${themeVariables.colors.critical};
                border-color: ${themeVariables.colors.critical};
                color: ${themeVariables.colors.backgroundSurface};                
                &:hover, &:active {
                    background-color: ${themeVariables.palettes.warning700};
                }
            `
            case 'primary':
            default:
                return `
                background-color: ${themeVariables.colors.brand};
                border-color: ${themeVariables.colors.brand};
                color: ${themeVariables.colors.backgroundSurface};
                &:hover, &:active {
                    background-color: ${disabled ? themeVariables.palettes.brand600 : themeVariables.palettes.brand700};
                }
            `
        }
    }}
`
