import React, { Fragment, ReactNode } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

const EntityPropertiesRoot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`

const PropName = styled.div`
    color: ${themeVariables.palettes.neutral700};
`

const PropValueRoot = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`

const PropIconWrap = styled.div`
    align-items: center;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    height: 16px;
    justify-content: center;
    width: 16px;

    svg {
        display: block;
    }
`

const IconPlaceholder = styled.div`
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background: ${themeVariables.palettes.neutral300};
`

interface EntityPropValueProps {
    icon?: ReactNode
    children: ReactNode
}

export function EntityPropValue({
    icon = <IconPlaceholder />,
    children,
}: EntityPropValueProps) {
    return (
        <PropValueRoot>
            <PropIconWrap>{icon}</PropIconWrap>
            <div>{children}</div>
        </PropValueRoot>
    )
}

export interface EntityProps {
    name: ReactNode
    value: {
        icon?: ReactNode
        content: ReactNode
    }
}

export function EntityProperties({
    entityProps,
}: {
    entityProps: EntityProps[]
}) {
    return (
        <EntityPropertiesRoot>
            {entityProps.map(({ name, value }, index) => (
                <Fragment key={index}>
                    <PropName>{name}</PropName>
                    <EntityPropValue icon={value.icon}>
                        {value.content}
                    </EntityPropValue>
                </Fragment>
            ))}
        </EntityPropertiesRoot>
    )
}
