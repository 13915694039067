import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'

export function PrivateRoute() {
    const { session } = useAuth()

    const location = useLocation()

    if (session?.sessionToken) {
        return <Outlet />
    }

    return (
        <Navigate
            to={{
                pathname: '/login',
            }}
            state={{
                from: location,
            }}
        />
    )
}
