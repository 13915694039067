import { useEffect } from 'react'
import { useUserQuery, useUserTeamsQuery } from 'utils/queries'
import { getCurrentTeamId, storeCurrentTeam, clearStoredTeam } from 'utils/team'
import { apiClient } from 'utils/clients'
import { getSessionToken } from 'utils/session'

export function ApiClientTeamSetter() {
    const sessionToken = getSessionToken()
    const { data: user } = useUserQuery({ enabled: !!sessionToken })
    const teams = useUserTeamsQuery({ enabled: !!sessionToken })

    useEffect(() => {
        if (!user?.id || !teams) return

        const storedTeamId = getCurrentTeamId(user.id)
        const team = teams.find((team) => team.id === storedTeamId) || teams[0]

        if (team) {
            apiClient.setSelectedTeam(team.id)
            storeCurrentTeam(user.id, team)
        } else {
            apiClient.setSelectedTeam(undefined)
            clearStoredTeam(user.id)
        }
    }, [user?.id, teams])

    return null
}
