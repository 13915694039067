import React, {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    ExpandedFilter,
    Filter,
    FilterOperator,
    WritableFilter,
} from '../types/filters'
import { useTransformedSearchParam } from './searchParams'

const FilterContext = createContext<Filter>({})

/**
 * Turns an array of filter entries into a proper `Filter` object.
 *
 * Each filter entry is a string containg an operator (`+` or `-`),
 * filter field, and the value. Examples:
 * - `+category:Finance` turns into `{ operator: 'is', value: ['Finance'] }`
 * - `-outcome:Amber` turns into `{ operator: 'is not', value: ['Amber'] }`
 *
 * It's an optimisation. Otherwise `filter` search param grows
 * unreasonably long for larger filters.
 */
function transformFilterEntries(entries: string[]) {
    const result: Filter = {}

    for (const entry of entries) {
        const match = entry.match(/^([+\-*])([a-z]+):(.*)?$/i)

        if (!match) {
            continue
        }

        const [, sign, key, value] = match as [unknown, string, string, string]

        const operator = getFilterOperatorFromSymbol(sign)

        let item = result[key]

        if (!item) {
            item = {
                operator,
                value: [value],
            }

            result[key] = item

            continue
        }

        if (item.operator !== operator) {
            continue
        }

        item.value.push(value)
    }

    return result
}

interface FilterProviderProps {
    children?: ReactNode
}

export function FilterProvider(props: FilterProviderProps) {
    const value = useTransformedSearchParam({
        key: 'filter',
        transform: transformFilterEntries,
    })

    return <FilterContext.Provider {...props} value={value} />
}

function getFilterOperatorSymbol(operator: FilterOperator): string {
    switch (operator) {
        case 'is':
            return '+'
        case 'is not':
            return '-'
        case 'contains':
            return '*'
        default:
            return '+'
    }
}

function getFilterOperatorFromSymbol(symbol: string): FilterOperator {
    switch (symbol) {
        case '+':
            return 'is'
        case '-':
            return 'is not'
        case '*':
            return 'contains'
        default:
            return 'is'
    }
}
/**
 * Takes a writable filter (verbose value or an array of strings)
 * and turns it into filter entries.
 *
 * Filters can be written in 2 ways:
 * - verbose way: `{ operator: 'is', value: ['foo', 'bar'] }`, or
 * - minimalistically: `['foo', 'bar']`.
 *
 * Note that the minimalistic way is only inclusive (operator set to `is`).
 */
export function flattenFilter<F extends WritableFilter<Filter>>(
    filter: F
): string[] {
    const entries: string[] = []

    for (const key in filter) {
        if (!Object.prototype.hasOwnProperty.call(filter, key)) {
            continue
        }

        const filterParams = filter[key]

        if (!filterParams) {
            continue
        }

        const [items, sign] =
            'operator' in filterParams
                ? [
                      filterParams.value,
                      getFilterOperatorSymbol(filterParams.operator),
                  ]
                : [filterParams, '+']

        for (const item of items) {
            entries.push(`${sign}${key}:${item}`)
        }
    }

    return entries
}

/**
 * Takes a filter and a set of keys, and gives an expanded version
 * of the filter used for filtering and comparison.
 */
export function expandFilter<K extends string>(filter: Filter, keys: K[]) {
    const result: ExpandedFilter = {}

    for (const key of keys) {
        const { operator, value: items } = filter[key] || {
            operator: 'is',
            value: [],
        }

        const current = result[key] || {
            operator,
            value: {},
        }

        for (const item of items) {
            current.value[item] = true
        }

        result[key] = current
    }

    return result as ExpandedFilter<K>
}

/**
 * Gives a callback for updating the value of the current `filter`
 * search param.
 *
 * The returned function takes either a completely new filter, or a callback
 * that modifies the existing one.
 */
export function useSetFilterCallback<K extends string>() {
    const [, setSearchParams] = useSearchParams()

    return useCallback(
        (
            filter:
                | WritableFilter<Filter<K>>
                | ((prevFilter: Filter<K>) => WritableFilter<Filter<K>>)
        ) => {
            setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                filter: flattenFilter(
                    typeof filter === 'function'
                        ? filter(transformFilterEntries(prev.getAll('filter')))
                        : filter
                ),
            }))
        },
        [setSearchParams]
    )
}

/**
 * Generates a filter with predefined and strictly typed keys.
 *
 * @returns An object containing `useValue`, `useCount`, and `useSetFilterCallback` hooks, plus santised `items`.
 * @example
 * const FooFilter = createFilter(['foo', { key: 'bar', label: 'Bar' }])
 * FooFilter.useValue() // current value
 * FooFilter.useCount() // number of keys carrying non-empty values
 * FooFilter.useSetFilterCallback()({ foo: ['lorem', 'ipsum'] })
 */
function createFilter<K extends string>(
    items: (K | { key: K; label?: string })[]
) {
    const keys: Partial<Record<string, true>> = {}

    for (const item of items) {
        keys[typeof item === 'string' ? item : item.key] = true
    }

    function useValue() {
        const filterValue = useContext(FilterContext)

        return useMemo(() => {
            const result: Filter<K> = {}

            for (const key in filterValue) {
                if (!Object.prototype.hasOwnProperty.call(filterValue, key)) {
                    continue
                }

                if (!keys[key]) {
                    /**
                     * We're not interested in this key. Doesn't belong to the current filter.
                     */
                    continue
                }

                result[key as K] = filterValue[key]
            }

            return result
        }, [filterValue])
    }

    function useCount() {
        return Object.keys(useValue()).length
    }

    return {
        useValue,
        useCount,
        useSetFilterCallback: useSetFilterCallback<K>,
        items: items.map((item) =>
            typeof item === 'string'
                ? { key: item, label: item }
                : { key: item.key, label: item.label || item.key }
        ),
    }
}

export const AssessmentAnswersFilter = createFilter([
    'category',
    'lead',
    'outcome',
    'status',
    { key: 'reviewStatus', label: 'Review status' },
])

export type AssessmentAnswersFilter = ReturnType<
    typeof AssessmentAnswersFilter.useValue
>

export const ModelQuestionsFilter = createFilter([
    'category',
    'subCategory',
    'content',
])

export type ModelQuestionsFilter = ReturnType<
    typeof ModelQuestionsFilter.useValue
>
