import React from 'react'
import { toaster } from 'toasterhea'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'

const InvalidReportTemplateModal = ({
    unknownError,
    ...props
}: { unknownError?: boolean } & Omit<BaseModalProps, 'children'>) => {
    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Error</EditorModalTitle>
                    <EditorModalCloseButton onClick={() => props.onResolve?.()}>
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    {unknownError ? (
                        <p>
                            An unknown error occurred while uploading the report
                            template.
                        </p>
                    ) : (
                        <>
                            <p>
                                The uploaded report template contains no prompts
                                for the AI. Prompts are marked by enclosing them
                                in square brackets. In reports generated from
                                the template, the prompts are replaced by the
                                AI&apos;s outputs. For example:
                            </p>
                            <p className="emphasized">
                                [Describe in greater detail the specifics of the
                                location of the project.]
                            </p>
                        </>
                    )}
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        onClick={() => {
                            props.onResolve?.()
                        }}
                    >
                        OK
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

export const invalidReportTemplateModal = toaster(
    InvalidReportTemplateModal,
    Layer.Modal
)
