import React, {
    AllHTMLAttributes,
    ChangeEvent,
    ReactNode,
    useEffect,
    useRef,
} from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { FormField, FormFieldProps } from './FormField'

const LineHeight = 1.5

const TextArea = styled.textarea<{
    $maxSize?: number
    $minSize?: number
    $resizable?: boolean
}>`
    box-sizing: border-box;
    background: transparent;
    border-radius: 0;
    border: 0;
    line-height: ${LineHeight}em;
    outline: 0 !important;
    padding: 10px 8px;
    width: 100%;
    min-height: ${({ $minSize = 3 }) =>
        `${Math.max(1, $minSize + 1) * LineHeight}em`};
    max-height: ${({ $maxSize }) =>
        $maxSize == null ? 'none' : `${Math.max(1, $maxSize) * LineHeight}em`};
    resize: ${({ $resizable = false }) => ($resizable ? 'vertical' : 'none')};

    &::placeholder {
        color: ${themeVariables.colors.secondary};
        opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Edge 12-18 */
        color: ${themeVariables.colors.secondary};
    }
`

interface TextAreaFieldProps
    extends Omit<AllHTMLAttributes<HTMLTextAreaElement>, 'label' | 'size'>,
        Pick<
            FormFieldProps,
            'label' | 'description' | 'errorMessage' | 'labelCta'
        > {
    autoSize?: boolean
    containerClassName?: string
    disabled?: boolean
    id?: string
    maxSize?: number
    minSize?: number
    onChange?(event: ChangeEvent<HTMLTextAreaElement>): void
    value?: string
    placeholder?: string
    bottomContent?: ReactNode
}

export function TextAreaField(props: TextAreaFieldProps) {
    const {
        autoSize = false,
        containerClassName,
        description,
        disabled = false,
        errorMessage,
        label,
        labelCta,
        maxSize,
        minSize,
        value = '',
        placeholder = 'Type here',
        bottomContent,
        ...rest
    } = props

    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(
        function autoUpdateTextAreaHeight() {
            const { current: textArea } = textAreaRef

            if (!textArea) {
                return
            }

            if (!autoSize) {
                Object.assign(textArea.style, {
                    height: undefined,
                })

                return
            }

            textArea.style.height =
                minSize == null
                    ? 'auto'
                    : `${Math.max(1, minSize + 1) * LineHeight}em`

            textArea.style.height = `${textArea.scrollHeight}px`
        },
        [value, autoSize, minSize]
    )

    return (
        <FormField
            className={containerClassName}
            description={description}
            errorMessage={errorMessage}
            id={props.id}
            label={label}
            labelCta={labelCta}
        >
            <TextArea
                {...rest}
                $maxSize={maxSize}
                $minSize={minSize}
                $resizable={!autoSize}
                disabled={disabled}
                placeholder={placeholder}
                ref={textAreaRef}
                value={value}
            />
            {bottomContent}
        </FormField>
    )
}
