import React from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

export const CheckboxRoot = styled.div<{
    $checked?: boolean
    $disabled?: boolean
}>`
    width: 16px;
    position: relative;
    height: 16px;

    &::before {
        --checkbox-focus-shadow: 0 0 transparent;

        background: ${themeVariables.colors.backgroundSurface};
        border: 1px solid ${themeVariables.palettes.neutral400};
        border-radius: 4px;
        box-shadow:
            0px 2px 5px rgba(48, 49, 61, 0.08),
            0px 1px 1px rgba(0, 0, 0, 0.12),
            var(--checkbox-focus-shadow);
        content: '';
        height: 15px;
        left: 0;
        top: 0;
        position: absolute;
        transform: translate(3.125%, 3.125%);
        width: 15px;
    }

    ${({ $checked = false }) =>
        $checked &&
        css`
            &::before {
                background: ${themeVariables.palettes.brand600};
                border-color: ${themeVariables.palettes.brand600};
            }
            svg path {
                stroke: white;
                fill: white;
            }
            svg rect {
                fill: white;
            }
        `}

    ${({ $disabled = false }) =>
        $disabled &&
        css`
            opacity: 0.5;
        `}
`

export const CheckboxParentCSS = css`
    &:focus ${CheckboxRoot}::before, &:focus-within ${CheckboxRoot}::before {
        --checkbox-focus-shadow: 0 0 0 4px rgba(1, 150, 237, 0.36);
    }
`

export const CheckboxActiveParentCSS = css`
    &:active ${CheckboxRoot}::before {
        --checkbox-focus-shadow: 0 0 0 4px rgba(1, 150, 237, 0.36);
    }
`

const CheckIcon = styled.svg`
    display: block;
    position: relative;
`

const FullCheckIcon = styled(CheckIcon)`
    transform: translate(3px, 3px);
`

const PartialCheckIcon = styled(CheckIcon)`
    transform: translate(4px, 7px);
`

export function Checkbox({
    checked = false,
    disabled = false,
    partial = false,
}) {
    return (
        <CheckboxRoot $checked={checked} $disabled={disabled}>
            {partial ? (
                <PartialCheckIcon
                    width="8"
                    height="2"
                    viewBox="0 0 8 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="8" height="2" rx="1" fill="transparent" />
                </PartialCheckIcon>
            ) : (
                <FullCheckIcon
                    width="11"
                    height="9"
                    viewBox="0 0 11 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        // eslint-disable-next-line max-len
                        d="M3.87424 7.53892L3.87457 7.53924C4.15279 7.8132 4.59793 7.81173 4.87436 7.53595L4.87439 7.53592L9.93895 2.48069L9.93949 2.48015C10.2172 2.20017 10.2172 1.74674 9.93949 1.46676C9.66125 1.18619 9.20968 1.18608 8.93131 1.46643C8.9312 1.46654 8.93109 1.46665 8.93098 1.46676L4.37472 6.00648L2.06607 3.73476C1.78767 3.45477 1.33637 3.455 1.05825 3.73545C0.780585 4.01543 0.780585 4.46886 1.05825 4.74884L1.05824 4.74884L1.05918 4.74977L3.87424 7.53892Z"
                        fill="transparent"
                        stroke="transparent"
                        strokeWidth="0.3"
                    />
                </FullCheckIcon>
            )}
        </CheckboxRoot>
    )
}
