import React, { ComponentType, ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import { useGlobalKeyDownEffect, useOnOutsideClickEffect } from 'utils/misc'
import { themeVariables } from '../themes/themeVariables'
import { Anchor } from './Anchor'

const OffsetX = '8px'

const DropdownContentWrap = styled.div`
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(var(--ai-dropdown-dx), var(--ai-dropdown-dy))
        translateX(
            min(
                0px,
                calc(
                    var(--ai-client-width) - var(--ai-dropdown-dx) - 100% -
                        ${OffsetX}
                )
            )
        )
        translateY(8px);
    z-index: 10;
`

export const DropdownContent = styled.div<{
    $minWidth?: string
    $maxWidth?: string
}>`
    background-color: ${themeVariables.colors.backgroundSurface};
    border-radius: 4px;
    box-shadow:
        0 0 0 1px #d5dbe1,
        0px 5px 15px 1px #00000014,
        0px 15px 35px 1px #3c42571f;
    min-width: ${({ $minWidth = '192px' }) => $minWidth};
    max-width: ${({ $maxWidth = '192px' }) => $maxWidth};
    overflow-y: auto;
    padding: 8px 0;
`

const anchorPlacement = (rect: DOMRect | undefined): [number, number] =>
    rect
        ? [
              (rect.x + window.scrollX) | 0,
              (rect.y + rect.height + window.scrollY) | 0,
          ]
        : [0, 0]

interface DropdownProps {
    trigger: ComponentType<{
        onClick(): void
        isOpen: boolean
    }>
    children?: ReactNode | ((dismiss: () => void) => ReactNode)
}

export const Dropdown = ({ trigger: Triggger, children }: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const contentRef = useRef<HTMLDivElement>(null)

    useOnOutsideClickEffect({
        containerRef: contentRef,
        callback: () => {
            setIsOpen(false)
        },
    })

    useGlobalKeyDownEffect(/escape/i, () => {
        setIsOpen(false)
    })

    return (
        <Anchor
            component={({ x, y }) =>
                isOpen ? (
                    <DropdownContentWrap
                        style={{
                            '--ai-dropdown-dx': `${x}px`,
                            '--ai-dropdown-dy': `${y}px`,
                            '--ai-client-width': `${document.documentElement.clientWidth}px`,
                        }}
                        ref={contentRef}
                    >
                        {typeof children === 'function'
                            ? children(() => {
                                  setIsOpen(false)
                              })
                            : children}
                    </DropdownContentWrap>
                ) : null
            }
            componentProps={{}}
            translate={anchorPlacement}
        >
            <Triggger onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
        </Anchor>
    )
}

export const DropdownItemSeparator = styled.div`
    height: 1px;
    background: ${themeVariables.colors.border};
    margin: 4px 0;
`
