import React, { useCallback } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import { route } from '../utils/routes'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import { DotdotdotButton } from '../components/DotdotdotButton'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../components/Form/Select/SelectItemRenderer'

import { Icon } from '../components/Icon'
import { useRequiredParam } from '../utils/misc'
import { useReportTemplateQuery } from '../utils/queries'
import { themeVariables } from '../themes/themeVariables'
import { DateRenderer } from '../components/renderers/DateRenderer'
import { Button } from '../components/Button'
import { useDeleteReportTemplate } from '../utils/mutations'
import { confirmModal } from '../components/modals/ConfirmationModal'
import { downloadReportTemplate } from '../utils/download'
import { useEditReportTemplateModal } from '../components/modals/EditReportTemplateModal'

const Pad = styled.div`
    padding: 40px;
`

const ButtonIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`

const TemplateContainer = styled.div`
    border-radius: 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    padding: 48px 40px;
    max-width: 770px;
    margin: 24px auto 0;
`

function DoSearch(searchTerm: string) {
    console.log('search!', searchTerm)
}

export function ReportTemplateShowPage() {
    const reportTemplateId = useRequiredParam('id')

    const { data: reportTemplate, isFetching } =
        useReportTemplateQuery(reportTemplateId)

    const triggerEditReportTemplate = useEditReportTemplateModal()

    const deleteTemplate = useDeleteReportTemplate()

    const triggerTemplateDelete = useCallback(async () => {
        try {
            await confirmModal.pop({
                title: 'Delete template',
                content: `Are you sure you want to delete "${reportTemplate?.name}" template?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                onResolve: () => {
                    deleteTemplate.mutate(reportTemplateId)
                },
            })
        } catch (_) {
            // do nothing
        }
    }, [reportTemplate?.name, deleteTemplate])

    return (
        <AccessDeniedHandler onSearch={DoSearch}>
            <Layout>
                <div>
                    <ListTopBar onSearch={DoSearch} />
                    <HorizontalLoadingIndicator
                        loading={isFetching || deleteTemplate.isPending}
                    />

                    <BreadcrumbsToolbar
                        currentPageName={reportTemplate?.name || ''}
                        listPage={{
                            name: 'Report Templates',
                            route: route('reportTemplates'),
                            icon: 'package',
                        }}
                        lhsAux={
                            <>
                                <Dropdown
                                    trigger={({ onClick }) => (
                                        <DotdotdotButton onClick={onClick} />
                                    )}
                                >
                                    {(dismiss) => (
                                        <DropdownContent>
                                            <SelectBasicItem
                                                type="button"
                                                disabled={
                                                    deleteTemplate.isPending
                                                }
                                                onClick={() => {
                                                    dismiss()
                                                    triggerTemplateDelete()
                                                }}
                                            >
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="trash" />
                                                </SelectBasicItemIconWrap>
                                                Delete template
                                            </SelectBasicItem>
                                        </DropdownContent>
                                    )}
                                </Dropdown>
                            </>
                        }
                    />
                    <Pad>
                        {reportTemplate && (
                            <TemplateContainer>
                                <h1>{reportTemplate.name}</h1>
                                <div
                                    className={cx(
                                        'secondary',
                                        'd-flex',
                                        'g-5',
                                        'm-b-40'
                                    )}
                                >
                                    Last updated:
                                    <DateRenderer
                                        value={reportTemplate.updatedAt}
                                    />
                                </div>
                                <div className={cx('d-flex', 'g-10')}>
                                    <Button
                                        className={cx('d-flex', 'g-5')}
                                        onClick={() =>
                                            downloadReportTemplate(
                                                reportTemplate
                                            )
                                        }
                                    >
                                        <ButtonIcon name="download" />
                                        Download
                                    </Button>
                                    <Button
                                        $variant="secondary"
                                        className={cx('d-flex', 'g-5')}
                                        onClick={() =>
                                            triggerEditReportTemplate(
                                                reportTemplate
                                            )
                                        }
                                    >
                                        <ButtonIcon name="edit" />
                                        Edit name
                                    </Button>
                                    <Button
                                        $variant="secondary"
                                        className={cx('d-flex', 'g-5')}
                                        disabled={deleteTemplate.isPending}
                                        onClick={triggerTemplateDelete}
                                    >
                                        <ButtonIcon name="trash" />
                                        Delete
                                    </Button>
                                </div>
                            </TemplateContainer>
                        )}
                    </Pad>
                </div>
            </Layout>
        </AccessDeniedHandler>
    )
}
