import { Session } from 'silta-ai-backend'
import z from 'zod'

const UserSessionSchema = z.object({
    email: z.string(),
    userId: z.string(),
    sessionToken: z.string(),
})

export type UserSession = z.infer<typeof UserSessionSchema>

const USER_SESSION_KEY = 'silta-ai-session'

export const isValidJSON = (json: string) => {
    try {
        JSON.parse(json)
        return true
    } catch (err) {
        return false
    }
}

export function toUserSession(email: string, session: Session): UserSession {
    return {
        email,
        userId: session.userId,
        sessionToken: session.id,
    }
}

export function storeSession(userSession: UserSession) {
    localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userSession))
}

export function clearStoredSession() {
    localStorage.removeItem(USER_SESSION_KEY)
}

export function getStoredSession(): UserSession | null {
    try {
        return z
            .string()
            .transform((input) => JSON.parse(input))
            .pipe(UserSessionSchema)
            .parse(localStorage.getItem(USER_SESSION_KEY))
    } catch (_) {
        // Session structure is invalid. Fallback to returning null.
    }

    return null
}

export const getSessionToken = (): string | null => {
    const parsedSession = getStoredSession()
    return parsedSession?.sessionToken ?? null
}
