export const themeVariables = {
    palettes: {
        brand100: 'var(--ai-color-palette-brand-100)',
        brand200: 'var(--ai-color-palette-brand-200)',
        brand300: 'var(--ai-color-palette-brand-300)',
        brand400: 'var(--ai-color-palette-brand-400)',
        brand500: 'var(--ai-color-palette-brand-500)',
        brand600: 'var(--ai-color-palette-brand-600)',
        brand700: 'var(--ai-color-palette-brand-700)',
        brand800: 'var(--ai-color-palette-brand-800)',
        brand900: 'var(--ai-color-palette-brand-900)',
        info100: 'var(--ai-color-palette-info-100)',
        info200: 'var(--ai-color-palette-info-200)',
        info300: 'var(--ai-color-palette-info-300)',
        info400: 'var(--ai-color-palette-info-400)',
        info500: 'var(--ai-color-palette-info-500)',
        info600: 'var(--ai-color-palette-info-600)',
        info700: 'var(--ai-color-palette-info-700)',
        info800: 'var(--ai-color-palette-info-800)',
        info900: 'var(--ai-color-palette-info-900)',
        success100: 'var(--ai-color-palette-success-100)',
        success200: 'var(--ai-color-palette-success-200)',
        success300: 'var(--ai-color-palette-success-300)',
        success400: 'var(--ai-color-palette-success-400)',
        success500: 'var(--ai-color-palette-success-500)',
        success600: 'var(--ai-color-palette-success-600)',
        success700: 'var(--ai-color-palette-success-700)',
        success800: 'var(--ai-color-palette-success-800)',
        success900: 'var(--ai-color-palette-success-900)',
        attention100: 'var(--ai-color-palette-attention-100)',
        attention200: 'var(--ai-color-palette-attention-200)',
        attention300: 'var(--ai-color-palette-attention-300)',
        attention400: 'var(--ai-color-palette-attention-400)',
        attention500: 'var(--ai-color-palette-attention-500)',
        attention600: 'var(--ai-color-palette-attention-600)',
        attention700: 'var(--ai-color-palette-attention-700)',
        attention800: 'var(--ai-color-palette-attention-800)',
        attention900: 'var(--ai-color-palette-attention-900)',
        warning100: 'var(--ai-color-palette-warning-100)',
        warning200: 'var(--ai-color-palette-warning-200)',
        warning300: 'var(--ai-color-palette-warning-300)',
        warning400: 'var(--ai-color-palette-warning-400)',
        warning500: 'var(--ai-color-palette-warning-500)',
        warning600: 'var(--ai-color-palette-warning-600)',
        warning700: 'var(--ai-color-palette-warning-700)',
        warning800: 'var(--ai-color-palette-warning-800)',
        warning900: 'var(--ai-color-palette-warning-900)',
        critical100: 'var(--ai-color-palette-critical-100)',
        critical200: 'var(--ai-color-palette-critical-200)',
        critical300: 'var(--ai-color-palette-critical-300)',
        critical400: 'var(--ai-color-palette-critical-400)',
        critical500: 'var(--ai-color-palette-critical-500)',
        critical600: 'var(--ai-color-palette-critical-600)',
        critical700: 'var(--ai-color-palette-critical-700)',
        critical800: 'var(--ai-color-palette-critical-800)',
        critical900: 'var(--ai-color-palette-critical-900)',
        neutral100: 'var(--ai-color-palette-neutral-100)',
        neutral200: 'var(--ai-color-palette-neutral-200)',
        neutral300: 'var(--ai-color-palette-neutral-300)',
        neutral400: 'var(--ai-color-palette-neutral-400)',
        neutral500: 'var(--ai-color-palette-neutral-500)',
        neutral600: 'var(--ai-color-palette-neutral-600)',
        neutral700: 'var(--ai-color-palette-neutral-700)',
        neutral800: 'var(--ai-color-palette-neutral-800)',
        neutral900: 'var(--ai-color-palette-neutral-900)',
    },
    colors: {
        backgroundSurface: 'var(--ai-color-background-surface)',
        backgroundContainer: 'var(--ai-color-background-container)',
        border: 'var(--ai-color-border)',
        primary: 'var(--ai-color-primary)',
        secondary: 'var(--ai-color-secondary)',
        disabled: 'var(--ai-color-disabled)',
        brand: 'var(--ai-color-brand)',
        info: 'var(--ai-color-info)',
        success: 'var(--ai-color-success)',
        attention: 'var(--ai-color-attention)',
        warning: 'var(--ai-color-warning)',
        critical: 'var(--ai-color-critical)',
    },
    typography: {
        fontSizes: {
            heading1: 'var(--ai-typography-heading-1-font-size)',
            heading2: 'var(--ai-typography-heading-2-font-size)',
            heading3: 'var(--ai-typography-heading-3-font-size)',
            subheading: 'var(--ai-typography-subheading-font-size)',
            body: 'var(--ai-typography-body-font-size)',
            large: 'var(--ai-typography-body-large-font-size)',
            caption: 'var(--ai-typography-caption-font-size)',
        },
        fontWeight: {
            normal: 'normal',
            bold: 'var(--ai-typography-body-emphasized-font-weight)',
            emphasized:
                'var(--ai-typography-body-large-emphasized-font-weight)',
        },
        lineHeight: {
            body: 'var(--ai-typography-body-line-height)',
            large: 'var(--ai-typography-body-large-line-height)',
            caption: 'var(--ai-typography-caption-line-height)',
        },
    },
} as const
