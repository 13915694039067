import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Checkbox, CheckboxParentCSS, CheckboxRoot } from './Checkbox'

export const HiddenInput = styled.input`
    opacity: 0;
    position: absolute;
`

export const CheckboxFieldInner = styled.div`
    display: flex;
    gap: 12px;
`

export const Label = styled.div`
    color: ${themeVariables.palettes.neutral900};
    font-weight: ${themeVariables.typography.fontWeight.bold};
`

export const Description = styled.div`
    color: ${themeVariables.palettes.neutral700};
`

const CheckboxFieldRoot = styled.label<{ $disabled?: boolean }>`
    cursor: pointer;
    display: block;
    width: max-content;

    ${CheckboxParentCSS}

    ${CheckboxRoot} {
        transform: translateY(2px);
    }

    ${({ $disabled = false }) =>
        $disabled &&
        css`
            cursor: default;
        `}
`

type CheckboxFieldProps = {
    checked?: boolean
    disabled?: boolean
    onChange?(value: boolean): void
    partial?: boolean
} & (
    | {
          description?: ReactNode
          label: ReactNode
      }
    | { description?: undefined; label?: undefined }
)

export function CheckboxField(props: CheckboxFieldProps) {
    const {
        checked = false,
        description,
        disabled = false,
        label,
        onChange,
        partial = false,
    } = props

    return (
        <CheckboxFieldRoot $disabled={disabled}>
            <HiddenInput
                checked={checked}
                disabled={disabled}
                onChange={() => onChange?.(!checked)}
                type="checkbox"
            />
            <CheckboxFieldInner>
                <Checkbox
                    checked={checked}
                    disabled={disabled}
                    partial={partial}
                />
                {!!label && (
                    <div>
                        <Label>{label}</Label>
                        {!!description && (
                            <Description>{description}</Description>
                        )}
                    </div>
                )}
            </CheckboxFieldInner>
        </CheckboxFieldRoot>
    )
}
