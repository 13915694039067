import {
    Dropdown,
    DropdownContent,
    DropdownItemSeparator,
} from 'components/Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from 'components/Form/Select/SelectItemRenderer'
import { Icon } from 'components/Icon'
import { ToolbarButton } from 'components/Toolbar'
import React, { MouseEvent } from 'react'
import { Answer } from 'silta-ai-backend'
import { ReviewStatus } from 'types/answers'
import { useIsAnswerBeingUpdated, useUpdateAnswer } from 'utils/mutations'

interface ReviewStatusButtonProps {
    onClick?(e: MouseEvent<HTMLButtonElement>): void
    reviewStatus: ReviewStatus
    className?: string
}

export function ReviewStatusButton({
    onClick,
    reviewStatus,
    className,
}: ReviewStatusButtonProps) {
    return (
        <ToolbarButton
            onClick={onClick}
            elevation="reduced"
            prepend={
                <>
                    {reviewStatus === 'ReadyForReview' && (
                        <Icon name="answerReadyForReview" />
                    )}
                    {reviewStatus === 'Approved' && (
                        <Icon name="answerAccepted" />
                    )}
                    {reviewStatus === 'Rejected' && (
                        <Icon name="answerRejected" />
                    )}
                </>
            }
            className={className}
        >
            {reviewStatus === 'ReadyForReview' ? (
                <>Ready for review</>
            ) : (
                reviewStatus
            )}
        </ToolbarButton>
    )
}

interface ReviewStatusDropdownProps {
    answer: Answer
    triggerClassName?: string
}

export function ReviewStatusDropdown({
    answer,
    triggerClassName,
}: ReviewStatusDropdownProps) {
    const { reviewStatus } = answer

    const updateAnswer = useUpdateAnswer(answer)

    const isBeingUpdated = useIsAnswerBeingUpdated(answer.id)

    if (reviewStatus === 'NotReadyForReview') {
        return <></>
    }

    return (
        <Dropdown
            trigger={({ onClick }) => (
                <ReviewStatusButton
                    className={triggerClassName}
                    reviewStatus={reviewStatus}
                    onClick={onClick}
                />
            )}
        >
            {(dismiss) => (
                <DropdownContent
                    $minWidth="112px"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <SelectBasicItem
                        disabled={reviewStatus === 'Approved' || isBeingUpdated}
                        onClick={() => {
                            dismiss()

                            updateAnswer.mutate({ reviewStatus: 'Approved' })
                        }}
                    >
                        <SelectBasicItemIconWrap>
                            <Icon name="checkCircle" />
                        </SelectBasicItemIconWrap>
                        Approve
                    </SelectBasicItem>
                    <SelectBasicItem
                        disabled={reviewStatus === 'Rejected' || isBeingUpdated}
                        onClick={() => {
                            dismiss()

                            updateAnswer.mutate({ reviewStatus: 'Rejected' })
                        }}
                    >
                        <SelectBasicItemIconWrap>
                            <Icon name="xCircle" />
                        </SelectBasicItemIconWrap>
                        Reject
                    </SelectBasicItem>
                    <DropdownItemSeparator />
                    <SelectBasicItem
                        disabled={
                            reviewStatus === 'ReadyForReview' || isBeingUpdated
                        }
                        onClick={() => {
                            dismiss()

                            updateAnswer.mutate({
                                reviewStatus: 'ReadyForReview',
                            })
                        }}
                    >
                        <SelectBasicItemIconWrap>
                            <Icon name="slashCircle" />
                        </SelectBasicItemIconWrap>
                        Clear
                    </SelectBasicItem>
                </DropdownContent>
            )}
        </Dropdown>
    )
}
