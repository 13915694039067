import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { themeVariables } from '../../../themes/themeVariables'
import {
    SelectItemRendererProps,
    SelectItemType,
    SelectableOption,
    SelectableOptionGroup,
} from '../../../types/form'
import { Checkbox, CheckboxActiveParentCSS } from '../Checkbox'

export const SelectItemGroupContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
`

export const SelectItemGroupHeader = styled.span`
    display: block;
    padding: 0 12px;
    font-size: ${themeVariables.typography.fontSizes.caption};
    color: ${themeVariables.colors.secondary};
    text-transform: uppercase;
`

export const SelectBasicItemIconWrap = styled.div`
    align-items: center;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    height: 16px;
    justify-content: center;
    width: 16px;
`

export const SelectBasicItem = styled.button<{ $selected?: boolean }>`
    background-color: ${themeVariables.colors.backgroundSurface};
    border: none;
    width: 100%;
    margin: 0;
    text-align: left;
    padding: 6px 12px;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${themeVariables.palettes.neutral200};
    }

    &:active {
        background-color: ${themeVariables.palettes.neutral400};
    }

    &:disabled {
        background-color: ${themeVariables.colors.backgroundSurface} !important;
        cursor: default;
    }

    &:disabled ${SelectBasicItemIconWrap} {
        opacity: 0.4;
    }

    ${({ $selected = false }) =>
        $selected &&
        `
        background-color: ${themeVariables.palettes.brand100};
        color: ${themeVariables.palettes.brand700};
    `}
`

export const SelectCheckboxItem = styled.button<{ $selected?: boolean }>`
    background-color: ${themeVariables.colors.backgroundSurface};
    border: none;
    width: 100%;
    margin: 0;
    text-align: left;
    padding: 6px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
        background-color: ${themeVariables.colors.backgroundContainer};
    }
    &:active {
        background-color: ${themeVariables.palettes.neutral400};
    }
    ${({ $selected = false }) =>
        $selected &&
        `
        background-color: ${themeVariables.colors.backgroundContainer};        
    `}
    ${CheckboxActiveParentCSS}
`

const SelectItem = ({
    option,
    selected,
    type,
    onChange,
    itemLabelRenderer,
}: {
    option: SelectableOption
    selected: boolean
    type: SelectItemType
    onChange: (value: string) => void
    itemLabelRenderer?: (option: SelectableOption) => ReactNode
}) => {
    switch (type) {
        case 'checkbox':
            return (
                <SelectCheckboxItem
                    type="button"
                    $selected={selected}
                    onClick={(event) => {
                        event.stopPropagation()
                        onChange(option.value)
                    }}
                >
                    <Checkbox checked={selected} />
                    {itemLabelRenderer
                        ? itemLabelRenderer(option)
                        : option.label}
                </SelectCheckboxItem>
            )
        case 'normal':
        default:
            return (
                <SelectBasicItem
                    key={option.value}
                    type="button"
                    onClick={(event) => {
                        event.stopPropagation()
                        onChange(option.value)
                    }}
                    $selected={selected}
                >
                    {itemLabelRenderer
                        ? itemLabelRenderer(option)
                        : option.label}
                </SelectBasicItem>
            )
    }
}

export const SelectItemRenderer = ({
    option,
    onChange,
    type,
    value,
    itemLabelRenderer,
}: SelectItemRendererProps) => {
    const isSelected = useCallback(
        (option: SelectableOption): boolean =>
            Array.isArray(value)
                ? value.includes(option.value)
                : option.value === value,
        [value]
    )
    return (
        <>
            {(option as SelectableOptionGroup).options ? (
                <SelectItemGroupContainer key={option.label}>
                    <SelectItemGroupHeader>
                        {option.label}
                    </SelectItemGroupHeader>
                    {(option as SelectableOptionGroup).options.map(
                        (singleOption) => (
                            <SelectItem
                                key={singleOption.value}
                                option={singleOption}
                                selected={isSelected(singleOption)}
                                type={type}
                                onChange={onChange}
                                itemLabelRenderer={itemLabelRenderer}
                            />
                        )
                    )}
                </SelectItemGroupContainer>
            ) : (
                <SelectItem
                    option={option as SelectableOption}
                    selected={isSelected(option as SelectableOption)}
                    type={type}
                    onChange={onChange}
                    itemLabelRenderer={itemLabelRenderer}
                />
            )}
        </>
    )
}
