import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { DataRoom, Model, Project } from 'silta-ai-backend'
import { apiClient } from 'utils/clients'
import { array, object, string } from 'yup'
import { Button } from '../../components/Button'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Select } from '../../components/Form/Select/Select'
import { TextField } from '../../components/Form/TextField'
import { Icon } from '../../components/Icon'
import { InfoBox } from '../../components/InfoBox'
import {
    useAssessmentDraft,
    useUpdateAssessmentDraft,
} from '../../state/AssessmentDraft.state'
import { themeVariables } from '../../themes/themeVariables'
import { useCreateAssessment } from '../../utils/mutations'
import { route } from '../../utils/routes'
import { useUsersQuery } from '../../utils/queries'

const AssesmentCreateValidationSchema = object().shape({
    name: string().required('Field is required'),
    project: string().required('Field is required'),
    modelId: string().optional(),
    precedentDataRoomIds: array(string()).min(0),
})

export default function AssessmentCreatePage() {
    const navigate = useNavigate()

    const [projectSearchTerm, setProjectSearchTerm] = useState('')
    const [modelSearchTerm, setModelSearchTerm] = useState('')
    const [precedentsSearchTerm, setPrecedentsSearchTerm] = useState('')

    const updateAssessmentDraft = useUpdateAssessmentDraft()

    const createAssessment = useCreateAssessment()

    const {
        name,
        projectId,
        modelId,
        precedentDataRoomIds,
        questions,
        assignedToId,
    } = useAssessmentDraft()

    const { search } = useLocation()

    const [projects, setProjects] = useState<Project[]>([])

    const [models, setModels] = useState<Model[]>([])

    const [precedentsDataRooms, setPrecedentsDataRooms] = useState<DataRoom[]>(
        []
    )

    const { data: users, isFetching: isUsersLoading } = useUsersQuery({})

    const projectOptions = useMemo(() => {
        if (!projectSearchTerm) {
            return projects
        }
        return projects.filter((project) =>
            project.name.toLowerCase().includes(projectSearchTerm.toLowerCase())
        )
    }, [projects, projectSearchTerm])

    const modelOptions = useMemo(() => {
        if (!modelSearchTerm) {
            return models
        }
        return models.filter((model) =>
            model.name.toLowerCase().includes(modelSearchTerm.toLowerCase())
        )
    }, [models, modelSearchTerm])

    const precedentsOptions = useMemo(() => {
        if (!precedentsSearchTerm) {
            return precedentsDataRooms
        }
        return precedentsDataRooms.filter((dataRoom) =>
            dataRoom.name
                .toLowerCase()
                .includes(precedentsSearchTerm.toLowerCase())
        )
    }, [precedentsDataRooms, precedentsSearchTerm])

    const selectedProjectIdDataRoomId = useMemo(
        () =>
            projectId
                ? projects.find((project) => project.id === projectId)
                      ?.dataRoomId
                : undefined,
        [projectId, projects]
    )

    const projectLabels = useMemo(() => {
        const labels: Record<string, string> = {}
        projects.forEach((project) => {
            labels[project.id] = project.name
        })
        return labels
    }, [projects])

    const modelLabels = useMemo(
        () =>
            models.map((model) => ({
                label: model.name,
                value: model.id,
            })),
        [models]
    )

    const precedentsLabels = useMemo(() => {
        const labels: Record<string, string> = {}
        precedentsDataRooms.forEach((dataRoom) => {
            labels[dataRoom.id] = dataRoom.name
        })
        return labels
    }, [precedentsDataRooms])

    const handleModelChange = async (newModelId: string | undefined) => {
        if (!newModelId) {
            updateAssessmentDraft({
                modelId: undefined,
                questions: [],
                filteredQuestions: [],
                selectedCategories1: [],
                selectedCategories2: [],
            })
            return
        }
        try {
            const model = await apiClient.getModel(newModelId)
            updateAssessmentDraft({
                modelId: newModelId,
                questions: model.questions,
                filteredQuestions: model.questions,
                selectedCategories1: [],
                selectedCategories2: [],
            })
        } catch (err) {
            console.error(`Error fetching model (${newModelId}): ${err}`)
        }
    }

    // Init Projects
    useEffect(() => {
        const getProjects = async () => {
            const fetchedProjects = await apiClient.getProjects()
            setProjects(fetchedProjects)

            // Pre-select the projectId given in query params, if any
            const query = new URLSearchParams(search)
            const projectId = query.get('projectId')
            if (projectId) {
                const project = fetchedProjects.find(
                    (project) => project.id === projectId
                )
                if (project) {
                    updateAssessmentDraft({
                        projectId: project.id,
                    })
                }
            }
        }
        getProjects()
    }, [])

    // Init Models
    useEffect(() => {
        const getModels = async () => {
            const models = await apiClient.getModels()
            setModels(models)
        }
        getModels()
    }, [])

    // Init Precedents Data Rooms
    useEffect(() => {
        const getPrecedentDataRooms = async () => {
            const dataRooms = await apiClient.getDataRooms()
            setPrecedentsDataRooms(dataRooms)
        }
        getPrecedentDataRooms()
    }, [])

    return (
        <FullPageLayout
            breadcrumbs={[{ label: 'Create assessment' }]}
            closeButtonLink={route('assessments')}
            headerCTAContent={
                <div className={cx('d-flex', 'g-10')}>
                    <Button
                        onClick={() => {
                            navigate(route('assessments'))
                        }}
                        $variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            createAssessment.mutate()
                        }}
                        $variant="primary"
                        disabled={
                            !name || !projectId || createAssessment.isPending
                        }
                    >
                        <div className={cx('d-flex', 'align-items-center')}>
                            <Icon name="plus" />
                            <span className="m-l-5">Create assessment</span>
                        </div>
                    </Button>
                </div>
            }
        >
            <div
                className={cx(
                    'main-container',
                    'd-flex',
                    'm-t-60',
                    'm-l-auto',
                    'm-r-auto'
                )}
            >
                <Formik
                    onSubmit={() =>
                        navigate(route('assessmentCreateQuestions'))
                    }
                    initialValues={{}}
                    validationSchema={AssesmentCreateValidationSchema}
                >
                    <Form className="w-100">
                        <Field name="name" id="name">
                            {({
                                field,
                                form,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <TextField
                                    {...field}
                                    value={name || ''}
                                    containerClassName="m-b-30"
                                    label={<h3>Assessment title</h3>}
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    placeholder="Enter a title for the assessment"
                                    onChange={(e) => {
                                        field.onChange(e)
                                        updateAssessmentDraft({
                                            name: e.target.value,
                                        })
                                    }}
                                />
                            )}
                        </Field>
                        <Field name="project" id="project">
                            {({
                                field,
                                form,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    value={projectId}
                                    valueLabels={projectLabels}
                                    containerClassName="m-b-30"
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    label={<h3>Project to assess</h3>}
                                    options={projectOptions.map((project) => ({
                                        value: project.id,
                                        label: project.name,
                                    }))}
                                    onSearchChange={setProjectSearchTerm}
                                    placeholder="Select a project to assess"
                                    onChange={(selection: string) => {
                                        form.setFieldValue(
                                            field.name,
                                            selection
                                        )
                                        // field.onChange(selection || '')
                                        updateAssessmentDraft({
                                            projectId: selection,
                                        })
                                    }}
                                />
                            )}
                        </Field>
                        <Field name="modelId" id="modelId">
                            {({
                                field,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    value={modelId}
                                    valueLabels={modelLabels}
                                    containerClassName="m-b-30"
                                    label={
                                        <h3>Evaluation Criteria To Apply</h3>
                                    }
                                    additionalLabel="Optional"
                                    options={modelOptions.map((model) => ({
                                        value: model.id,
                                        label: model.name,
                                    }))}
                                    onSearchChange={setModelSearchTerm}
                                    placeholder="Select a model to apply"
                                    onChange={(selection: string) =>
                                        handleModelChange(selection)
                                    }
                                />
                            )}
                        </Field>
                        <Field
                            name="precedentDataRoomIds"
                            id="precedentDataRoomIds"
                        >
                            {({
                                field,
                            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            any) => (
                                <Select
                                    {...field}
                                    multiple={true}
                                    value={precedentDataRoomIds}
                                    valueLabels={precedentsLabels}
                                    containerClassName="m-b-30"
                                    label={<h3>Precedents Databases</h3>}
                                    additionalLabel="Optional"
                                    disabled={!projectId}
                                    options={precedentsOptions
                                        .filter(
                                            (dataRoom) =>
                                                dataRoom.id !==
                                                selectedProjectIdDataRoomId
                                        )
                                        .map((dataRoom) => ({
                                            value: dataRoom.id,
                                            label: dataRoom.name,
                                        }))}
                                    onSearchChange={setPrecedentsSearchTerm}
                                    placeholder="Connect precedents databases"
                                    onChange={(
                                        selection: string[] | undefined
                                    ) =>
                                        updateAssessmentDraft({
                                            precedentDataRoomIds:
                                                selection || [],
                                        })
                                    }
                                />
                            )}
                        </Field>
                        <Field name="assignedToId" id="assignedToId">
                            {({
                                field,
                                form,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            }: any) => (
                                <Select
                                    {...field}
                                    value={assignedToId}
                                    // containerClassName="m-b-30"
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    label={<h3>Lead</h3>}
                                    options={(users || []).map((user) => ({
                                        value: user.id,
                                        label: user.name,
                                    }))}
                                    additionalLabel="Optional"
                                    placeholder="Select a Assessment Lead"
                                    onChange={(selection: string) => {
                                        form.setFieldValue(
                                            field.name,
                                            selection
                                        )
                                        field.onChange(selection || '')
                                        updateAssessmentDraft({
                                            assignedToId: selection,
                                        })
                                    }}
                                    disabled={isUsersLoading}
                                />
                            )}
                        </Field>
                        <InfoBox
                            iconName="info"
                            borderColor="transparent"
                            backgroundColor={themeVariables.palettes.info100}
                            textColor={themeVariables.colors.primary}
                            iconColor={themeVariables.palettes.brand600}
                            className={cx('m-t-30', 'm-b-40')}
                        >
                            If you don&apos;t select a model, your assessment
                            will start empty and you can add questions manually.
                        </InfoBox>

                        <Button
                            $variant="secondary"
                            type="button"
                            $size="large"
                            disabled={!(projectId && name)}
                            className="w-100"
                            onClick={() => {
                                navigate(route('assessmentCreateQuestions'))
                            }}
                        >
                            {questions?.length || 0} questions
                        </Button>
                    </Form>
                </Formik>
            </div>
        </FullPageLayout>
    )
}
