import React, { SVGAttributes } from 'react'
import styled from 'styled-components'

interface CirclePathProps extends Omit<SVGAttributes<SVGPathElement>, 'd'> {
    cx?: number
    cy?: number
    r?: number
    width?: number
}

function DonutPath({
    cx = 0,
    cy = 0,
    r = 10,
    width = 2,
    ...props
}: CirclePathProps) {
    const r2 = r - width

    return (
        <path
            {...props}
            d={`
                M ${cx}, ${cy}
                m -${r}, 0
                a ${r},${r} 0 1,0 ${2 * r},0
                a ${r},${r} 0 1,0 -${2 * r},0 z
                M ${cx}, ${cy}
                m -${r2}, 0
                a ${r2},${r2} 0 1,0 ${2 * r2},0
                a ${r2},${r2} 0 1,0 -${2 * r2},0 z
            `}
        />
    )
}

const CircularProgressIndicatorRoot = styled.svg`
    display: block;
`

interface CircularProgressIndicatorProps {
    color?: string
    progress?: number
    radius?: number
    ringRadius?: number
    ringWidth?: number
}

export function CircularProgressIndicator({
    color = 'currentColor',
    progress: progressProp = 0.85,
    radius: radiusProp = 5,
    ringRadius = 8,
    ringWidth = 2,
}: CircularProgressIndicatorProps) {
    const progress = Math.max(0, Math.min(1, progressProp))

    const radius = Math.max(radiusProp, ringRadius)

    const centerX = radius

    const centerY = radius

    const startAngle = -0.5 * Math.PI // Start from top

    const endAngle = startAngle + 2 * progress * Math.PI // End angle based on progress

    const startX = centerX + radiusProp * Math.cos(startAngle)

    const startY = centerY + radiusProp * Math.sin(startAngle)

    const endX = centerX + radiusProp * Math.cos(endAngle)

    const endY = centerY + radiusProp * Math.sin(endAngle)

    const largeArcFlag = progress > 0.5 ? 1 : 0 // Use large arc if angle > 180 degrees

    const path = [
        `M ${centerX} ${centerY}`,
        `L ${startX} ${startY}`,
        `A ${radiusProp} ${radiusProp} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        'Z',
    ].join(' ')

    return (
        <CircularProgressIndicatorRoot width={radius * 2} height={radius * 2}>
            <DonutPath
                fill={color}
                cx={centerX}
                cy={centerY}
                r={ringRadius}
                width={ringWidth}
                fillRule="evenodd"
            />
            {progress < 1 ? (
                <path d={path} fill={color} />
            ) : (
                <circle cx={centerX} cy={centerY} r={radiusProp} fill={color} />
            )}
        </CircularProgressIndicatorRoot>
    )
}
