import React from 'react'
import styled from 'styled-components'
import { DotdotdotButton } from 'components/DotdotdotButton'
import { Dropdown, DropdownContent } from 'components/Dropdown'
import { EvaluationProrityEditor } from 'components/EvaluationProrityEditor'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from 'components/Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from 'components/HorizontalLoadingIndicator'
import { Icon } from 'components/Icon'
import { ListTopBar } from 'components/ListTopBar'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import { useRequiredParam } from 'utils/misc'
import { route } from 'utils/routes'
import { Layout } from '../components/Layout'
import '../css/ModelShow.css'
import '../css/Spinner.css'
import { useModelQuery } from '../utils/queries'
import { ModelQuestions } from '../components/ModelQuestions'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import { useDeleteModel } from '../utils/mutations'
import { confirmModal } from '../components/modals/ConfirmationModal'

function DoSearch() {}

const ServerIcon = styled.div.attrs((props) => ({
    ...props,
    children: <Icon name="server" />,
}))`
    svg {
        width: 14px;
        height: 14px;
    }
`

const RootInner = styled.div`
    display: grid;
    gap: 48px;
    margin: 0 auto;
    max-width: 778px;
`

const Root = styled.div`
    padding: 40px 40px 96px;
`

export function ModelShowPage() {
    const id = useRequiredParam('id')

    const modelQuery = useModelQuery(id)

    const model = modelQuery.data || undefined

    const deleteModel = useDeleteModel()

    return (
        <AccessDeniedHandler onSearch={DoSearch}>
            <Layout>
                <ListTopBar onSearch={DoSearch} />
                <HorizontalLoadingIndicator
                    loading={modelQuery.isFetching || deleteModel.isPending}
                />
                {model && (
                    <>
                        <BreadcrumbsToolbar
                            currentPageName={model.name}
                            listPage={{
                                name: 'Evaluation Criteria',
                                route: route('models'),
                                icon: <ServerIcon />,
                            }}
                            lhsAux={
                                <>
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent $maxWidth="560px">
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={async () => {
                                                        try {
                                                            await confirmModal.pop(
                                                                {
                                                                    title: 'Delete question',
                                                                    content:
                                                                        'Are you sure you want to delete this model?',
                                                                    cancelButtonText:
                                                                        'Cancel',
                                                                    confirmButtonText:
                                                                        'Delete',
                                                                    onResolve:
                                                                        () => {
                                                                            deleteModel.mutate(
                                                                                model
                                                                            )
                                                                        },
                                                                }
                                                            )
                                                        } catch (error) {
                                                            // do nothing
                                                        }
                                                        dismiss()
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="trash" />
                                                    </SelectBasicItemIconWrap>
                                                    Delete Evaluation Criteria
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </>
                            }
                        />
                        <Root>
                            <RootInner>
                                <h1>{model.name}</h1>
                                <div>
                                    <h3>Priority Level</h3>
                                    <EvaluationProrityEditor modelId={id} />
                                </div>
                                <ModelQuestions />
                            </RootInner>
                        </Root>
                    </>
                )}
            </Layout>
        </AccessDeniedHandler>
    )
}
