import React from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

export const RadioRoot = styled.div<{
    $checked?: boolean
    $disabled?: boolean
}>`
    height: 16px;
    position: relative;
    width: 16px;

    &::before {
        --radio-focus-shadow: 0 0 transparent;

        background: transparent;
        border: 1px solid ${themeVariables.palettes.neutral400};
        border-radius: 50%;
        box-shadow:
            0px 2px 5px rgba(48, 49, 61, 0.08),
            0px 1px 1px rgba(0, 0, 0, 0.12),
            var(--radio-focus-shadow);
        content: '';
        height: 15px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translate(3.125%, 3.125%);
        width: 15px;
    }

    &::after {
        background: ${themeVariables.palettes.neutral100};
        border-radius: 50%;
        box-shadow:
            0px 2px 5px rgba(48, 49, 61, 0.08),
            0px 1px 1px rgba(0, 0, 0, 0.12);
        content: '';
        height: 6px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: translate(5px, 5px);
        width: 6px;
    }

    ${({ $checked = false }) =>
        $checked &&
        css`
            &::before {
                background: ${themeVariables.palettes.brand600};
                border-color: ${themeVariables.palettes.brand600};
            }

            &::after {
                opacity: 1;
            }
        `}

    ${({ $disabled = false }) =>
        $disabled &&
        css`
            opacity: 0.5;
        `}
`

export const RadioParentCSS = css`
    &:focus ${RadioRoot}::before, &:focus-within ${RadioRoot}::before {
        --radio-focus-shadow: 0 0 0 4px rgba(1, 150, 237, 0.36);
    }
`

export function Radio({ checked = false, disabled = false }) {
    return <RadioRoot $checked={checked} $disabled={disabled} />
}
