import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { toaster } from 'toasterhea'
import { Layer } from 'utils/layers'
import { themeVariables } from '../../themes/themeVariables'
import { RejectionReason } from '../../utils/exceptions'
import { BaseButton } from '../BaseButton'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { BaseModal, BaseModalProps } from './BaseModal'

const Root = styled.div`
    max-width: 400px;
    width: 90vw;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 16px;
`
const Title = styled.h3`
    margin: 0;
`

const CloseButton = styled(BaseButton)`
    color: ${themeVariables.colors.secondary};
    padding: 8px;
    transform: translateX(8px);
    &:hover {
        background-color: inherit;
    }
`

const ContentContainer = styled.div`
    padding: 0 24px;
    color: ${themeVariables.colors.secondary};
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px 24px 24px;
`
export interface ModalProps extends Omit<BaseModalProps, 'children'> {
    title?: string
    content: ReactNode
    confirmButtonText: string
    cancelButtonText?: string
}

export const ConfirmationModal = ({
    content,
    title = 'Untitled modal',
    confirmButtonText,
    cancelButtonText,
    ...props
}: ModalProps) => (
    <BaseModal {...props}>
        <Root>
            <TitleContainer>
                <Title>{title}</Title>
                <CloseButton
                    onClick={() =>
                        props.onReject?.(RejectionReason.CloseButton)
                    }
                >
                    <Icon name="close2" />
                </CloseButton>
            </TitleContainer>
            <ContentContainer>{content}</ContentContainer>
            <ButtonsContainer>
                {cancelButtonText && (
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        {cancelButtonText}
                    </Button>
                )}
                <Button
                    autoFocus
                    onClick={() => {
                        props.onResolve?.(true)
                    }}
                >
                    {confirmButtonText}
                </Button>
            </ButtonsContainer>
        </Root>
    </BaseModal>
)

export const confirmModal = toaster(ConfirmationModal, Layer.Modal)
