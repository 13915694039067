import React from 'react'
import { Alert } from 'react-bootstrap'

export const ErrorAlert = ({ error }: { error: string }) => (
    <div>
        <Alert variant="danger" className="error-message-login">
            There are errors in this page <p>{error}</p>
        </Alert>
    </div>
)
