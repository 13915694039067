import { useState, useEffect } from 'react'
import { useUserQuery, useUserTeamsQuery, resetAllQueries } from 'utils/queries'
import { getCurrentTeamId, storeCurrentTeam } from 'utils/team'
import { apiClient } from 'utils/clients'

export function useTeamSelection() {
    const { data: user } = useUserQuery()
    const teams = useUserTeamsQuery()
    const [currentTeamId, setCurrentTeamId] = useState<string | null>(null)

    useEffect(() => {
        if (!user?.id || !teams) return
        const storedTeamId = getCurrentTeamId(user.id)
        const isValidTeam = teams.some((team) => team.id === storedTeamId)
        setCurrentTeamId(isValidTeam ? storedTeamId : teams[0]?.id ?? null)
    }, [user?.id, teams])

    const switchTeam = (teamId: string) => {
        if (!user?.id || !teams) return

        const selectedTeam = teams.find((team) => team.id === teamId)
        if (!selectedTeam) {
            console.error(`Team with id ${teamId} not found`)
            return
        }

        storeCurrentTeam(user.id, selectedTeam)
        setCurrentTeamId(teamId)
        apiClient.setSelectedTeam(teamId)

        // Reset all queries so that the access is checked again
        resetAllQueries()
    }

    return {
        currentTeamId,
        teams,
        switchTeam,
    }
}
