import { QueryClientProvider } from '@tanstack/react-query'
import { AssessmentPageLayout } from 'components/AssessmentPageLayout'
import { AssessmentShowAnswersPage } from 'pages/AssessmentShowAnswers'
import { AssessmentShowReportsPage } from 'pages/AssessmentShowReports'
import { AssessmentShowSummaryPage } from 'pages/AssessmentShowSummary'
import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Container } from 'toasterhea'
import 'reset-css'
import { FilterProvider } from 'utils/filters'
import { queryClient } from 'utils/clients'
import { PrivateRoute } from './components/PrivateRoute'
import { ScrollToTop } from './components/ScrollToTop'
import { AccActivate } from './pages/AccActivate'
import { AnswerShowPage } from './pages/AnswerShow'
import AssessmentCreatePage from './pages/AssessmentCreate/AssessmentCreate'
import { AssessmentCreateQuestions } from './pages/AssessmentCreate/AssessmentCreateQuestions'
import { AssessmentDraftPage } from './pages/AssessmentCreate/AssessmentDraftPage'
import AssessmentListPage from './pages/AssessmentList'
import DataRoomListPage from './pages/DataRoomList'
import DataRoomShowPage from './pages/DataRoomShow'
import { Forgot } from './pages/Forgot'
import { Login } from './pages/Login'
import { ModelListPage } from './pages/ModelList'
import { ModelShowPage } from './pages/ModelShow'
import ProjectCreatePage from './pages/ProjectCreate'
import ProjectListPage from './pages/ProjectList'
import ProjectShowPage from './pages/ProjectShow'
import { ReportListPage } from './pages/ReportList'
import { ReportShowPage } from './pages/ReportShow'
import { ReportTemplateListPage } from './pages/ReportTemplateList'
import { ReportTemplateShowPage } from './pages/ReportTemplateShow'
import { Reset } from './pages/Reset'
import { SignUp } from './pages/SignUp'
import { AuthProvider } from './providers/AuthProvider'
import { GlobalStyle } from './styles/globalStyle'
import './styles/styles.scss'
import { CURRENT_THEME } from './utils/currentTheme'
import { route } from './utils/routes'
import { Layer } from './utils/layers'
import { ProjectPageLayout } from './components/ProjectPageLayout'
import ProjectDataRoomShowPage from './pages/ProjectDataRoomShow'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage'
import { TermsAndConditionsRoute } from './components/TermsAndConditionsRoute'
import { ApiClientTeamSetter } from './components/ApiClientTeamSetter'

import(`./themes/${CURRENT_THEME}/style.css`)

const root = createRoot(document.getElementById('root')!)

interface ProvidersProps {
    children?: ReactNode
}

function Providers({ children }: ProvidersProps) {
    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <Router>
                    <FilterProvider>
                        <AuthProvider>{children}</AuthProvider>
                    </FilterProvider>
                </Router>
            </RecoilRoot>
        </QueryClientProvider>
    )
}

root.render(
    <Providers>
        <GlobalStyle />
        <ApiClientTeamSetter />
        <Routes>
            <Route path="/" element={<Navigate to={route('assessments')} />} />
            <Route element={<PrivateRoute />}>
                <Route
                    path={route('termsAndConditions')}
                    element={<TermsAndConditionsPage />}
                />
                <Route element={<TermsAndConditionsRoute />}>
                    <Route element={<AssessmentDraftPage />}>
                        <Route
                            path={route('assessmentCreate')}
                            element={<AssessmentCreatePage />}
                        />
                        <Route
                            path={route('assessmentCreateQuestions')}
                            element={<AssessmentCreateQuestions />}
                        />
                    </Route>
                    <Route element={<AssessmentPageLayout />}>
                        <Route
                            path={route('assessment', ':id')}
                            element={<AssessmentShowSummaryPage />}
                        />
                        <Route
                            path={route('assessmentAnswers', ':id')}
                            element={<AssessmentShowAnswersPage />}
                        />
                        <Route
                            path={route('assessmentReports', ':id')}
                            element={<AssessmentShowReportsPage />}
                        />
                    </Route>
                    <Route
                        path={route('assessments')}
                        element={<AssessmentListPage />}
                    />
                    <Route
                        path={route('answer', ':assessmentId', ':answerId')}
                        element={<AnswerShowPage />}
                    />
                    <Route
                        path={route('dataRooms')}
                        element={<DataRoomListPage />}
                    />
                    <Route
                        path={route('dataRoom', ':id')}
                        element={<DataRoomShowPage />}
                    />
                    <Route
                        path={route('projects')}
                        element={<ProjectListPage />}
                    />
                    <Route
                        path={route('projectCreate')}
                        element={<ProjectCreatePage />}
                    />
                    <Route element={<ProjectPageLayout />}>
                        <Route
                            path={route('project', ':id')}
                            element={<ProjectShowPage />}
                        />
                        <Route
                            path={route('projectDataRoom', ':id')}
                            element={<ProjectDataRoomShowPage />}
                        />
                    </Route>
                    <Route path={route('models')} element={<ModelListPage />} />
                    <Route
                        path={route('model', ':id')}
                        element={<ModelShowPage />}
                    />
                    <Route
                        path={route('reports')}
                        element={<ReportListPage />}
                    />
                    <Route
                        path={route('report', ':id')}
                        element={<ReportShowPage />}
                    />
                    <Route
                        path={route('reportTemplates')}
                        element={<ReportTemplateListPage />}
                    />
                    <Route
                        path={route('reportTemplate', ':id')}
                        element={<ReportTemplateShowPage />}
                    />
                </Route>
            </Route>
            <Route path="/activate-account/:id" element={<AccActivate />} />
            <Route path={route('login')} element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/password-reset/:token" element={<Reset />} />
        </Routes>
        <Container id={Layer.Modal} />
        <ScrollToTop />
    </Providers>
)
