import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

const DateRendererRoot = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
`

type DateFormat = 'LLL d, yyyy' | 'd MMM yyyy, H:mm'

interface DateRendererProps {
    value: Date
    format?: DateFormat
}

export function DateRenderer({
    value,
    format: formatProp = 'LLL d, yyyy',
}: DateRendererProps) {
    return <DateRendererRoot>{format(value, formatProp)}</DateRendererRoot>
}
