import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { ProjectDraft } from '../types/projects'

export const emptyProjectDraftDraft: ProjectDraft = {
    country: '',
    description: '',
    name: '',
    customId: '',
    sector: '',
    assignedToId: '',
}

const projectDraft = atom<ProjectDraft>({
    key: 'projectDraft',
    default: emptyProjectDraftDraft,
})

export const useProjectDraft = (): ProjectDraft => useRecoilValue(projectDraft)

export const useUpdateProjectDraft = (): ((
    partialState: Partial<ProjectDraft>
) => void) => {
    const setDraft = useSetRecoilState(projectDraft)

    return (partialState: Partial<ProjectDraft>) =>
        setDraft((state) => ({ ...state, ...partialState }))
}
