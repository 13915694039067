import React, { ReactNode } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormCheckType } from 'react-bootstrap/esm/FormCheck'
import Hint from '../../assets/images/icons/help.svg'

interface FormCheckProps {
    value: string
    name: string
    id: string
    type: FormCheckType
    label: ReactNode
    disabled?: boolean
    className?: string
    classNameCheck?: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    hint?: {
        title: string
        text: string
    }
    checked?: boolean
}

export const FormCheck = ({
    value,
    name,
    id,
    type,
    label,
    disabled,
    className = '',
    classNameCheck,
    onChange,
    onBlur,
    hint,
    checked,
}: FormCheckProps) => {
    const IsChecked = value === label

    const checkBoxClassName = classNameCheck
        ? 'no-bg-signup'
        : value === 'true'
          ? 'clicked'
          : ''

    return (
        <Form.Group
            controlId={id}
            className={`d-flex align-items-baseline flex-row silta-form-check ${
                disabled ? 'disabled' : ''
            } ${className}`}
        >
            <Form.Check
                id={id}
                name={name}
                type={type}
                label={label}
                className={checkBoxClassName}
                value={label as string}
                onChange={onChange}
                checked={checked || (type === 'radio' && IsChecked) || false}
                onBlur={onBlur}
                disabled={disabled || false}
            />
            {hint && (
                <span className="ms-2" style={{ position: 'relative' }}>
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip
                                id={`tooltip-${id}`}
                                style={{ opacity: 1, position: 'absolute' }}
                            >
                                <p className="btn-tooltip-name">{hint.title}</p>
                                <p className="btn-tooltip-description">
                                    {hint.text}
                                </p>
                            </Tooltip>
                        }
                    >
                        <img src={Hint} alt="Help icon" />
                    </OverlayTrigger>
                </span>
            )}
        </Form.Group>
    )
}
