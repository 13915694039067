import React, { useMemo } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { Outcome } from 'silta-ai-backend'
import { useModelQuery } from '../utils/queries'
import { useRequiredParam } from '../utils/misc'
import { themeVariables } from '../themes/themeVariables'
import { Button } from './Button'
import { Icon } from './Icon'
import { Separator } from './Separator'
import { Dropdown, DropdownContent } from './Dropdown'
import { DotdotdotButton } from './DotdotdotButton'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { ModelQuestionsFilter } from '../utils/filters'
import {
    filterQuestionsByCategory,
    useFilteredModelQuestions,
} from '../utils/models'
import { ModelQuestionsFilterDropdown } from './ModelQuestionsFilterDropdown'
import { getUniqueCategoryOptions } from '../utils/categories'
import { useModelQuestionEditorModal } from './modals/ModelQuestionEditor'
import { useDeleteQuestion } from '../utils/mutations'
import { confirmModal } from './modals/ConfirmationModal'

const Question = styled.div`
    background-color: ${themeVariables.colors.backgroundContainer};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 32px;
`

const QuestionContentWrap = styled.div`
    border-radius: 8px;
    background-color: ${themeVariables.colors.backgroundSurface};
    padding: 20px;
`

const CategoryDisplay = styled.div`
    border-radius: 4px;
    border: 1px solid ${themeVariables.colors.border};
    padding: 4px 10px;
    font-weight: 500;
`

const QuestionOutcomeGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 8px;
`
const QuestionOutcome = styled.div`
    display: contents; // Use display: contents to allow grid items to span multiple rows
    & > div {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid ${themeVariables.colors.border}; // Add bottom border to each row
    }
    &:last-child > div {
        border-bottom: none; // Remove bottom border from the last row
        padding-bottom: 8px;
    }
`

const QuestionOutcomeDotContainer = styled.div`
    padding-right: 40px !important;
`

const QuestionOutcomeDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
`

const ZeroState = styled.div`
    color: ${themeVariables.palettes.neutral700};
    padding: 96px 0;
    text-align: center;
`

export const ModelQuestions = () => {
    const id = useRequiredParam('id')
    const modelQuery = useModelQuery(id)
    const openEditorModal = useModelQuestionEditorModal()
    const deleteQuestion = useDeleteQuestion()
    const model = modelQuery.data || undefined
    const outcomesById = useMemo<Record<string, Outcome>>(() => {
        return (
            model?.outcomes.reduce(
                (acc: Record<string, Outcome>, outcome: Outcome) => {
                    acc[outcome.id] = outcome
                    return acc
                },
                {}
            ) || {}
        )
    }, [model])
    const unfilteredQuestions = model?.questions || []
    const filter = ModelQuestionsFilter.useValue()
    const questions = useFilteredModelQuestions({
        questions: unfilteredQuestions,
        filter,
        outcomesById,
    })

    const questionNumbers = useMemo(() => {
        const lut: Record<string, number> = {}

        model?.questions.forEach((q, index) => {
            lut[q.id] = index + 1
        })

        return lut
    }, [model])

    const occuringCategories = useMemo(
        getUniqueCategoryOptions(unfilteredQuestions, 'category1'),
        [unfilteredQuestions]
    )
    const occuringSubCategories = useMemo(
        getUniqueCategoryOptions(
            filterQuestionsByCategory(
                unfilteredQuestions,
                filter.category?.value || [],
                'category1'
            ),
            'category2'
        ),
        [unfilteredQuestions, filter.category?.value]
    )

    return (
        <div>
            <div
                className={cx(
                    'd-flex',
                    'justify-content-between',
                    'align-items-center'
                )}
            >
                <h3>Questions</h3>
                <div className={cx('d-flex', 'align-items-center', 'g-10')}>
                    <ModelQuestionsFilterDropdown
                        occurringCategories={occuringCategories}
                        occurringSubCategories={occuringSubCategories}
                    />
                    <Button
                        $variant="secondary"
                        className={cx('d-flex', 'align-items-center', 'g-10')}
                        onClick={() => {
                            if (model) {
                                openEditorModal(model)
                            }
                        }}
                    >
                        <Icon name="plus" />
                        Add question
                    </Button>
                </div>
            </div>
            <Separator className={cx('m-b-30', 'm-t-30')} />
            {model && questions.length > 0 ? (
                <div>
                    {questions.map((question) => (
                        <Question key={question.id}>
                            <QuestionContentWrap>
                                <div
                                    className={cx(
                                        'd-flex',
                                        'align-items-center',
                                        'justify-content-between',
                                        'm-b-15'
                                    )}
                                >
                                    <span className="emphasized">
                                        Question {questionNumbers[question.id]}
                                    </span>
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                                flip
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        dismiss()
                                                        openEditorModal(
                                                            model,
                                                            question,
                                                            questionNumbers[
                                                                question.id
                                                            ]
                                                        )
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="edit" />
                                                    </SelectBasicItemIconWrap>
                                                    Edit
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={async () => {
                                                        dismiss()
                                                        try {
                                                            await confirmModal.pop(
                                                                {
                                                                    title: 'Delete question',
                                                                    content:
                                                                        'Are you sure you want to delete this question?',
                                                                    cancelButtonText:
                                                                        'Cancel',
                                                                    confirmButtonText:
                                                                        'Delete',
                                                                    onResolve:
                                                                        () =>
                                                                            deleteQuestion.mutate(
                                                                                question
                                                                            ),
                                                                }
                                                            )
                                                        } catch (error) {
                                                            // do nothing
                                                        }
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="trash" />
                                                    </SelectBasicItemIconWrap>
                                                    Delete
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </div>
                                <div>{question.content}</div>
                                {(question.category1 || question.category2) && (
                                    <div
                                        className={cx(
                                            'd-flex',
                                            'g-10',
                                            'm-t-25'
                                        )}
                                    >
                                        {question.category1 && (
                                            <CategoryDisplay>
                                                {question.category1}
                                            </CategoryDisplay>
                                        )}
                                        {question.category2 && (
                                            <CategoryDisplay>
                                                {question.category2}
                                            </CategoryDisplay>
                                        )}
                                    </div>
                                )}
                            </QuestionContentWrap>
                            {question.criteria.length > 0 && (
                                <QuestionOutcomeGrid>
                                    {question.criteria
                                        .sort((a, b) => {
                                            const indexA =
                                                model.outcomes.findIndex(
                                                    (outcome) =>
                                                        outcome.id ===
                                                        a.outcomeId
                                                )
                                            const indexB =
                                                model.outcomes.findIndex(
                                                    (outcome) =>
                                                        outcome.id ===
                                                        b.outcomeId
                                                )
                                            return indexA - indexB
                                        })
                                        .map((criterion) => (
                                            <QuestionOutcome key={criterion.id}>
                                                <QuestionOutcomeDotContainer>
                                                    <QuestionOutcomeDot
                                                        style={{
                                                            backgroundColor:
                                                                outcomesById[
                                                                    criterion
                                                                        .outcomeId
                                                                ]?.color,
                                                        }}
                                                    />
                                                    <span>
                                                        {
                                                            outcomesById[
                                                                criterion
                                                                    .outcomeId
                                                            ]?.label
                                                        }
                                                    </span>
                                                </QuestionOutcomeDotContainer>
                                                <div>{criterion.criterion}</div>
                                            </QuestionOutcome>
                                        ))}
                                </QuestionOutcomeGrid>
                            )}
                        </Question>
                    ))}
                </div>
            ) : (
                <ZeroState>No questions</ZeroState>
            )}
        </div>
    )
}
