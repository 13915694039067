// Copied from the client's website
export const sectors = [
    'Agriculture, natural resources and rural development',
    'Education',
    'Energy',
    'Finance',
    'Health',
    'Health and social protection',
    'Industry and trade',
    'Information and communication technology',
    'Multisector',
    'Public sector management',
    'Transport',
    'Transport and ICT',
    'Water and other urban infrastructure and services',
]
