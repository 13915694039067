import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { TextAreaField } from './TextAreaField'
import { Button } from '../Button'
import { Dropdown, DropdownContent } from '../Dropdown'
import { themeVariables } from '../../themes/themeVariables'
import { Separator } from '../Separator'
import { RadioField } from './RadioField'
import { Icon } from '../Icon'
import { BaseButton } from '../BaseButton'

const DropdownsContainer = styled.div`
    display: flex;
    gap: 5px;
    padding: 8px 10px;
`

const CategoryDropdownContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const CategoryDropdownContentPad = styled.div`
    padding: 8px 16px;
`

const CategoryDropdownInput = styled.input`
    border: none;
    background-color: transparent;
    outline: none;
    color: ${themeVariables.colors.primary};
    width: 100%;
    ::placeholder {
        color: ${themeVariables.colors.secondary};
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: ${themeVariables.colors.secondary};
    }
`

const CategoryDropdownOptions = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
    list-style-type: none;
    margin: 0;
    li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

const CreateNewCategory = styled(BaseButton)`
    padding: 6px 8px;
    color: ${themeVariables.colors.primary};
    background-color: ${themeVariables.colors.backgroundContainer};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`

export type QuestionFormValue = {
    question: string
    category?: string
    subCategory?: string
}

type QuestionFormCategory = {
    name: string
    questionCount: number
}

// It can work both with categories and sub categories!
const CategoryDropdownContent = ({
    placeholder,
    addCategoryCTAtext,
    categories = [],
    category,
    onCategoryChange,
    dismiss,
}: {
    placeholder: string
    addCategoryCTAtext: string
    categories?: QuestionFormCategory[]
    category?: string
    onCategoryChange: (category: string) => void
    dismiss: () => void
}) => {
    const [categorySearchTerm, setCategorySearchTerm] = useState('')
    const filteredCategories = useMemo(() => {
        const categoriesToFilter = [...categories]
        if (category && categories.findIndex((c) => c.name === category) < 0) {
            categoriesToFilter.unshift({
                name: category,
                questionCount: 1,
            })
        }
        return categoriesToFilter
            ? categoriesToFilter.filter((category) =>
                  category.name
                      .toLowerCase()
                      .includes(categorySearchTerm.toLowerCase())
              )
            : []
    }, [categories, categorySearchTerm, category])

    return (
        <CategoryDropdownContentRoot>
            <CategoryDropdownContentPad>
                <CategoryDropdownInput
                    placeholder={placeholder}
                    autoFocus
                    value={categorySearchTerm || ''}
                    onChange={(e) => setCategorySearchTerm(e.target.value)}
                />
            </CategoryDropdownContentPad>
            <Separator />
            {filteredCategories.length > 0 && (
                <CategoryDropdownContentPad>
                    <CategoryDropdownOptions>
                        {filteredCategories.map((cat) => (
                            <li key={cat.name}>
                                <RadioField
                                    value={cat.name}
                                    label={
                                        <span>
                                            {cat.name}
                                            <small
                                                className={cx(
                                                    'secondary',
                                                    'm-l-5'
                                                )}
                                            >
                                                {cat.questionCount} question
                                                {cat.questionCount > 1
                                                    ? 's'
                                                    : ''}
                                            </small>
                                        </span>
                                    }
                                    checked={cat.name === category}
                                    onChange={() => {
                                        dismiss()
                                        onCategoryChange(cat.name)
                                    }}
                                />
                            </li>
                        ))}
                    </CategoryDropdownOptions>
                </CategoryDropdownContentPad>
            )}
            {filteredCategories.length === 0 && (
                <CategoryDropdownContentPad>
                    <CreateNewCategory
                        type="button"
                        disabled={!categorySearchTerm}
                        onClick={() => {
                            dismiss()
                            onCategoryChange(categorySearchTerm)
                        }}
                    >
                        <Icon name="plus" />
                        <div>
                            <span className="emphasized">
                                {`${addCategoryCTAtext}: `}
                            </span>
                            <span className="secondary">
                                {categorySearchTerm}
                            </span>
                        </div>
                    </CreateNewCategory>
                </CategoryDropdownContentPad>
            )}
        </CategoryDropdownContentRoot>
    )
}

export const QuestionForm = ({
    label,
    value,
    onChange,
    categories,
    subCategories,
    errorMessage,
    displayCategorySelection = true,
}: {
    value: QuestionFormValue
    onChange: (value: QuestionFormValue) => void
    label?: string
    categories?: QuestionFormCategory[]
    subCategories?: QuestionFormCategory[]
    errorMessage?: string
    displayCategorySelection?: boolean
}) => (
    <TextAreaField
        label={label}
        placeholder="Enter your question here"
        value={value.question || ''}
        autoSize
        minSize={2}
        maxSize={7}
        errorMessage={errorMessage}
        onChange={(e) => {
            onChange({
                ...value,
                question: e.target.value,
            })
        }}
        bottomContent={
            displayCategorySelection && (
                <DropdownsContainer>
                    <Dropdown
                        trigger={({ onClick }) => (
                            <Button
                                type="button"
                                onClick={onClick}
                                $variant="secondary"
                            >
                                {value.category || 'Add Category'}
                            </Button>
                        )}
                    >
                        {(dismiss) => (
                            <DropdownContent
                                $minWidth="320px"
                                $maxWidth="360px"
                            >
                                <CategoryDropdownContent
                                    categories={categories}
                                    category={value.category}
                                    onCategoryChange={(category) => {
                                        onChange({
                                            ...value,
                                            category,
                                        })
                                    }}
                                    dismiss={dismiss}
                                    placeholder="Add Category..."
                                    addCategoryCTAtext="Create new category"
                                />
                            </DropdownContent>
                        )}
                    </Dropdown>
                    <Dropdown
                        trigger={({ onClick }) => (
                            <Button
                                type="button"
                                onClick={onClick}
                                $variant="secondary"
                            >
                                {value.subCategory || 'Add Sub Category'}
                            </Button>
                        )}
                    >
                        {(dismiss) => (
                            <DropdownContent
                                $minWidth="320px"
                                $maxWidth="360px"
                            >
                                <CategoryDropdownContent
                                    categories={subCategories}
                                    category={value.subCategory}
                                    onCategoryChange={(subCategory) => {
                                        onChange({
                                            ...value,
                                            subCategory,
                                        })
                                    }}
                                    dismiss={dismiss}
                                    placeholder="Add Sub Category..."
                                    addCategoryCTAtext="Create new sub category"
                                />
                            </DropdownContent>
                        )}
                    </Dropdown>
                </DropdownsContainer>
            )
        }
    />
)
