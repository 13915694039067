import { Button } from 'components/Button'
import {
    DotdotdotButton,
    DotdotdotButtonRoot,
} from 'components/DotdotdotButton'
import {
    Dropdown,
    DropdownContent,
    DropdownItemSeparator,
} from 'components/Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from 'components/Form/Select/SelectItemRenderer'
import { TextField } from 'components/Form/TextField'
import { Icon } from 'components/Icon'
import { confirmModal } from 'components/modals/ConfirmationModal'
import uniqueId from 'lodash/uniqueId'
import React, { useEffect, useRef, useState } from 'react'
import { Outcome } from 'silta-ai-backend'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { useIsShiftPressed } from 'utils/misc'
import {
    useCreateOutcome,
    useDeleteOutcome,
    useIsOutcomeBeingCreated,
    useIsOutcomeBeingDeleted,
    useIsOutcomeBeingUpdated,
    useIsOutcomeOrderBeingUpdated,
    useUpdateOutcome,
    useUpdateOutcomeOrder,
} from 'utils/mutations'
import { useOutcomesQuery } from 'utils/queries'

const TextButton = styled.button`
    appearance: none;
    background: 0;
    border: 0;
    outline: 0 !important;
    color: inherit;
    padding: 0 8px;
    height: 30px;

    &:focus {
        text-decoration: underline;
    }

    &:disabled {
        opacity: 0.5;
    }
`

const SecondaryText = styled.span`
    color: ${themeVariables.colors.secondary};
`

const ColorItem = styled.button`
    width: 32px;
    height: 32px;
    background: none;
    border: 0;
    position: relative;

    &::after {
        background: currentColor;
        width: 24px;
        border-radius: 50%;
        height: 24px;
        position: absolute;
        left: 50%;
        content: '';
        top: 50%;
        transform: translate(-50%, -50%);
    }
`

const colors = [
    '#a3acba',
    '#6a7383',
    '#30313d',
    '#00ade0',
    '#5faf26',
    '#4746f3',
    '#fbb215',
    '#e5512c',
    '#df1b41',
]

const AvailableColors = styled.div`
    display: flex;
    gap: 8px;
    padding: 0 8px;
`

const ColorButton = styled.button`
    appearance: none;
    background: ${themeVariables.colors.backgroundContainer};
    border: 0;
    border-radius: 4px;
    cursor: default;
    display: block;
    height: 40px;
    position: relative;
    width: 40px;

    &::after {
        background: currentColor;
        border-radius: 50%;
        content: '';
        height: 16px;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translate(-50%, -50%);
    }
`

const ColorColumn = styled.div`
    margin-right: 10px;
    width: 40px;
`

const NameColumn = styled.div`
    flex-grow: 1;
`

const Row = styled.form<{ $editable?: boolean }>`
    border-bottom: 1px solid ${themeVariables.colors.border};
    align-items: center;
    display: flex;
    gap: 10px;
    height: 72px;

    em {
        font-weight: ${themeVariables.typography.fontWeight.emphasized};
    }

    ${DotdotdotButtonRoot} {
        height: 28px;
        width: 28px;
    }

    button[type='submit'] {
        align-items: center;
        display: flex;
        gap: 6px;
    }

    button[type='submit'] svg {
        width: 14px;
        height: 14px;
    }

    ${({ $editable = false }) =>
        $editable &&
        css`
            ${ColorColumn} {
                margin-right: 0;
            }

            ${ColorButton} {
                background: ${themeVariables.colors.backgroundSurface};
                box-shadow: 0 0 0 1px ${themeVariables.colors.border};
            `}
`

interface OutcomeDraft {
    color: string
    id: string
    modelId: string
    name: string
    outcome: Outcome | null
    sortOrder: number
}

interface OutcomeDraftEditorProps {
    nextDraft: OutcomeDraft | undefined
    onCancel?(): void
    onCreate?(transientId: string, outcomeId: string): void
    onReorder?(fromIndex: number, toIndex: number): void
    onUpdate?(outcome: Outcome): void
    previousDraft: OutcomeDraft | undefined
    value: OutcomeDraft
}

function OutcomeDraftEditor({
    onCancel,
    onCreate,
    value,
    onUpdate,
    onReorder,
    previousDraft,
    nextDraft,
}: OutcomeDraftEditorProps) {
    const { id, color, name, outcome, sortOrder, modelId } = value

    const [rawName, setRawName] = useState(name)

    const [rawColor, setRawColor] = useState(color)

    useEffect(
        function setNameFromUpstream() {
            setRawName(name)
        },
        [name]
    )

    useEffect(
        function setColorFromUpstream() {
            setRawColor(color)
        },
        [color]
    )

    const [editable, setEditable] = useState(!outcome)

    const createOutcome = useCreateOutcome(id)

    const isBeingCreated = useIsOutcomeBeingCreated(id)

    const updateOutcome = useUpdateOutcome(id)

    const isBeingUpdated = useIsOutcomeBeingUpdated(id)

    const deleteOutcome = useDeleteOutcome(modelId, id)

    const isBeingDeleted = useIsOutcomeBeingDeleted(id)

    const busy = isBeingCreated || isBeingUpdated || isBeingDeleted

    function cancel() {
        if (busy) {
            return
        }

        setEditable(false)

        setRawName(name)

        setRawColor(color)

        onCancel?.()
    }

    async function deleteWithConfirmation() {
        try {
            await confirmModal.pop({
                title: 'Confirm',
                content: `Are you sure you want to delete "${name}"?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            })

            deleteOutcome.mutate()
        } catch (_) {
            // Aborted.
        }
    }

    const submittable = !!(
        !busy &&
        editable &&
        (rawName || outcome) &&
        rawColor &&
        (!outcome || outcome.label !== rawName || outcome.color !== rawColor)
    )

    const shiftPressed = useIsShiftPressed()

    const isOrderBeingUpdated = useIsOutcomeOrderBeingUpdated(modelId)

    return (
        <Row
            $editable={editable}
            onSubmit={(e) => {
                e.preventDefault()

                if (!submittable) {
                    return
                }

                if (!outcome) {
                    createOutcome.mutate(
                        {
                            outcome: {
                                color: rawColor,
                                label: rawName,
                                modelId,
                                sortOrder,
                            },
                        },
                        {
                            onSuccess(outcome) {
                                onCreate?.(id, outcome.id)
                            },
                        }
                    )

                    return
                }

                if (/^\s*$/.test(rawName)) {
                    deleteWithConfirmation()

                    return
                }

                updateOutcome.mutate(
                    {
                        color: rawColor,
                        label: rawName,
                    },
                    {
                        onSuccess(outcome) {
                            onUpdate?.(outcome)

                            setEditable(false)
                        },
                    }
                )
            }}
        >
            <ColorColumn>
                <Dropdown
                    trigger={({ onClick }) => (
                        <ColorButton
                            type="button"
                            style={{ color: rawColor }}
                            onClick={() => {
                                if (busy) {
                                    return
                                }

                                setEditable(true)

                                onClick()
                            }}
                        />
                    )}
                >
                    {(dismiss) => (
                        <DropdownContent $maxWidth="none">
                            <AvailableColors>
                                {colors.map((color) => (
                                    <ColorItem
                                        key={color}
                                        value={color}
                                        style={{ color }}
                                        onClick={() => {
                                            dismiss()

                                            setRawColor(color)
                                        }}
                                    />
                                ))}
                            </AvailableColors>
                        </DropdownContent>
                    )}
                </Dropdown>
            </ColorColumn>
            <NameColumn
                onDoubleClick={() => {
                    if (!editable && !busy) {
                        setEditable(true)
                    }
                }}
            >
                {editable ? (
                    <TextField
                        readOnly={busy}
                        placeholder="Priority Level"
                        autoFocus
                        value={rawName}
                        onChange={(e) => {
                            setRawName(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                cancel()
                            }
                        }}
                    />
                ) : (
                    <em>{name}</em>
                )}
            </NameColumn>
            {editable ? (
                <>
                    <TextButton
                        disabled={busy}
                        type="button"
                        onClick={() => {
                            cancel()
                        }}
                    >
                        Cancel
                    </TextButton>
                    <Button type="submit" disabled={!submittable || busy}>
                        Save
                        {busy && <Icon name="spinner" />}
                    </Button>
                </>
            ) : (
                <div>
                    <Dropdown
                        trigger={({ onClick }) => (
                            <DotdotdotButton flip onClick={onClick} />
                        )}
                    >
                        {(dismiss) => (
                            <DropdownContent $minWidth="160px">
                                <SelectBasicItem
                                    disabled={busy}
                                    type="button"
                                    onClick={() => {
                                        dismiss()

                                        setEditable(true)
                                    }}
                                >
                                    <SelectBasicItemIconWrap>
                                        <Icon name="edit" />
                                    </SelectBasicItemIconWrap>
                                    Edit
                                </SelectBasicItem>
                                <SelectBasicItem
                                    disabled={busy}
                                    type="button"
                                    onClick={async () => {
                                        dismiss()

                                        deleteWithConfirmation()
                                    }}
                                >
                                    <SelectBasicItemIconWrap>
                                        <Icon name="trash" />
                                    </SelectBasicItemIconWrap>
                                    Delete
                                </SelectBasicItem>
                                {previousDraft || nextDraft ? (
                                    <DropdownItemSeparator />
                                ) : (
                                    <></>
                                )}
                                {!!previousDraft && (
                                    <SelectBasicItem
                                        disabled={busy || isOrderBeingUpdated}
                                        type="button"
                                        onClick={(e) => {
                                            dismiss()

                                            onReorder?.(
                                                sortOrder,
                                                e.shiftKey ? 0 : sortOrder - 1
                                            )
                                        }}
                                    >
                                        {shiftPressed ? (
                                            <>
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="topArrow" />
                                                </SelectBasicItemIconWrap>
                                                Move to top
                                            </>
                                        ) : (
                                            <>
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="upArrow" />
                                                </SelectBasicItemIconWrap>
                                                Move up
                                            </>
                                        )}
                                    </SelectBasicItem>
                                )}
                                {!!nextDraft && (
                                    <SelectBasicItem
                                        disabled={busy || isOrderBeingUpdated}
                                        type="button"
                                        onClick={async (e) => {
                                            dismiss()

                                            onReorder?.(
                                                sortOrder,
                                                e.shiftKey
                                                    ? Infinity
                                                    : sortOrder + 1
                                            )
                                        }}
                                    >
                                        {shiftPressed ? (
                                            <>
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="bottomArrow" />
                                                </SelectBasicItemIconWrap>
                                                Move to bottom
                                            </>
                                        ) : (
                                            <>
                                                <SelectBasicItemIconWrap>
                                                    <Icon name="downArrow" />
                                                </SelectBasicItemIconWrap>
                                                Move down
                                            </>
                                        )}
                                    </SelectBasicItem>
                                )}
                            </DropdownContent>
                        )}
                    </Dropdown>
                </div>
            )}
        </Row>
    )
}

interface EvaluationProrityEditorProps {
    modelId: string
}

function getDraft(outcome: Outcome): OutcomeDraft {
    const { id, label: name, color, sortOrder } = outcome

    return {
        color,
        id,
        modelId: outcome.modelId,
        name,
        outcome,
        sortOrder,
    }
}

function getDrafts(outcomes: Outcome[]): OutcomeDraft[] {
    return outcomes.map(getDraft).map((draft, sortOrder) => ({
        ...draft,
        sortOrder,
    }))
}

export function EvaluationProrityEditor(props: EvaluationProrityEditorProps) {
    const { modelId } = props

    const { data: outcomes } = useOutcomesQuery(modelId)

    const [drafts, setDrafts] = useState<OutcomeDraft[]>(
        getDrafts(outcomes || [])
    )

    const bindingsRef = useRef<Partial<Record<string, string>>>({})

    useEffect(
        function updateDraftsFromOutcomes() {
            if (!outcomes) {
                return
            }

            setDrafts((current) => {
                const outcomeIdToOutcome: Record<string, Outcome> = {}

                for (const outcome of outcomes) {
                    outcomeIdToOutcome[outcome.id] = outcome
                }

                const result: OutcomeDraft[] = []

                for (const draft of current) {
                    if (!draft.outcome) {
                        const boundId = bindingsRef.current[draft.id]

                        if (!boundId) {
                            result.push(draft)

                            continue
                        }

                        const boundOutcome = outcomeIdToOutcome[boundId]

                        if (boundOutcome) {
                            result.push(getDraft(boundOutcome))

                            delete outcomeIdToOutcome[boundId]
                        }

                        continue
                    }

                    const outcome = outcomeIdToOutcome[draft.outcome.id]

                    if (outcome) {
                        result.push(getDraft(outcome))

                        delete outcomeIdToOutcome[draft.outcome.id]
                    }
                }

                result.push(...getDrafts(Object.values(outcomeIdToOutcome)))

                result.sort((a, b) => a.sortOrder - b.sortOrder)

                return result.map((draft, sortOrder) => ({
                    ...draft,
                    sortOrder,
                }))
            })
        },
        [outcomes]
    )

    const updateOrder = useUpdateOutcomeOrder(modelId)

    return (
        <>
            <Row>
                <ColorColumn>
                    <SecondaryText>Color</SecondaryText>
                </ColorColumn>
                <NameColumn>
                    <SecondaryText>Name</SecondaryText>
                </NameColumn>
                <div>
                    <Button
                        type="button"
                        $variant="secondary"
                        onClick={() => {
                            setDrafts((current) => [
                                ...current,
                                {
                                    color: 'gray',
                                    id: uniqueId('OutcomeDraft-'),
                                    modelId,
                                    name: '',
                                    outcome: null,
                                    persisted: false,
                                    sortOrder:
                                        (current[current.length - 1]
                                            ?.sortOrder || 0) + 1,
                                },
                            ])
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <Icon name="plus" />
                            <span className="m-l-5">Add Priority Level</span>
                        </div>
                    </Button>
                </div>
            </Row>
            {drafts.map((draft, index) => (
                <OutcomeDraftEditor
                    key={draft.id}
                    value={draft}
                    onCancel={() => {
                        if (draft.outcome) {
                            return
                        }

                        setDrafts((current) =>
                            current.filter(({ id }) => id !== draft.id)
                        )
                    }}
                    previousDraft={drafts[index - 1]}
                    nextDraft={drafts[index + 1]}
                    onCreate={(transientId, newOutcomeId) => {
                        bindingsRef.current[transientId] = newOutcomeId
                    }}
                    onUpdate={(outcome) => {
                        setDrafts((current) =>
                            current.map((draft) =>
                                draft.id === outcome.id
                                    ? getDraft(outcome)
                                    : draft
                            )
                        )
                    }}
                    onReorder={(fromIndex, toIndex) => {
                        const newDrafts: OutcomeDraft[] = []

                        const draftAtFromIndex = drafts[fromIndex]

                        if (!draftAtFromIndex) {
                            return
                        }

                        function push(draft: OutcomeDraft) {
                            newDrafts.push({
                                ...draft,
                                sortOrder: newDrafts.length,
                            })
                        }

                        const cleanToIndex = Math.min(
                            drafts.length - 1,
                            toIndex
                        )

                        for (let i = 0; i < drafts.length; i += 1) {
                            const draft = drafts[i]

                            if (!draft) {
                                continue
                            }

                            if (i === fromIndex) {
                                continue
                            }

                            if (i !== cleanToIndex) {
                                push(draft)

                                continue
                            }

                            const queue = [draft, draftAtFromIndex]

                            if (cleanToIndex < fromIndex) {
                                queue.reverse()
                            }

                            for (const item of queue) {
                                push(item)
                            }
                        }

                        setDrafts(newDrafts)

                        const directives: { id: string; sortOrder: number }[] =
                            []

                        for (const draft of newDrafts) {
                            if (draft.outcome) {
                                directives.push({
                                    id: draft.id,
                                    sortOrder: draft.sortOrder,
                                })
                            }
                        }

                        updateOrder.mutate(directives)
                    }}
                />
            ))}
        </>
    )
}
