import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDebounce } from '../../../utils/useDebounce'
import { themeVariables } from '../../../themes/themeVariables'
import { Icon } from '../../Icon'
import {
    SelectableOption,
    SelectableOptionGroup,
    SelectValueRendererProps,
} from '../../../types/form'

const SelectValue = styled.span<{ $isPlaceholder: boolean }>`
    ${({ $isPlaceholder }) =>
        $isPlaceholder &&
        `
        color: ${themeVariables.colors.secondary};
    `}
`

const SelectInputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const SearchInput = styled.input`
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    flex: 1;
    outline: none;

    &:disabled {
        background-color: ${themeVariables.colors.backgroundContainer};
        cursor: not-allowed;
    }
`

const SelectedOptionsContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    overflow: auto;
    margin-right: 5px;
`

const SelectedOption = styled.div`
    display: flex;
    align-items: center;
    gap: 0 4px;
    background-color: ${themeVariables.palettes.neutral300};
    border-radius: 3px;
    padding: 0 4px;
`

const SelectedOptionRemoveButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 4px;
`

export const SelectValueRenderer = ({
    value,
    valueLabels,
    options,
    onRemove,
    placeholderText,
    onSearchChange,
    searchDebounceTime,
    multiple = false,
    disabled,
}: SelectValueRendererProps) => {
    const debouncedOnSearch = useDebounce(
        onSearchChange || (() => {}),
        searchDebounceTime
    )

    const findLabel = useCallback(
        (
            options: (SelectableOption | SelectableOptionGroup)[],
            searchedValue: string
        ) => {
            if (valueLabels && valueLabels[searchedValue]) {
                return valueLabels[searchedValue]
            }
            for (const option of options) {
                const items = 'options' in option ? option.options : [option]

                for (const item of items) {
                    if (item.value === searchedValue) {
                        return item.label
                    }
                }
            }

            return undefined
        },
        [options]
    )

    return (
        <>
            {multiple ? (
                <SelectInputContainer>
                    {!!value && value.length > 0 && (
                        <SelectedOptionsContainer>
                            {(value as string[]).map((singleValue) => (
                                <SelectedOption key={singleValue}>
                                    {findLabel(options, singleValue)}
                                    <SelectedOptionRemoveButton
                                        type="button"
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            onRemove([singleValue])
                                        }}
                                    >
                                        <Icon name="close" />
                                    </SelectedOptionRemoveButton>
                                </SelectedOption>
                            ))}
                        </SelectedOptionsContainer>
                    )}
                    {onSearchChange ? (
                        <SearchInput
                            type="text"
                            placeholder={
                                (value as string[]).length === 0
                                    ? placeholderText
                                    : undefined
                            }
                            onChange={(e) => debouncedOnSearch(e.target.value)}
                            disabled={disabled}
                        />
                    ) : (
                        <>
                            {(!value || !value.length) && (
                                <SelectValue $isPlaceholder>
                                    {placeholderText}
                                </SelectValue>
                            )}
                        </>
                    )}
                </SelectInputContainer>
            ) : (
                <>
                    {onSearchChange && !value?.length ? (
                        <SearchInput
                            type="text"
                            placeholder={placeholderText}
                            onChange={(e) => debouncedOnSearch(e.target.value)}
                            disabled={disabled}
                        />
                    ) : (
                        <SelectValue
                            $isPlaceholder={
                                !findLabel(options, value as string)
                            }
                        >
                            {findLabel(options, value as string) ||
                                placeholderText}
                        </SelectValue>
                    )}
                </>
            )}
        </>
    )
}
