import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios'
import {
    Answer,
    AnswerWithRelations,
    AssessmentListItem,
    AssessmentWithRelations,
    CreateAnswerRequest,
    CreateAssessmentRequest,
    CreateDataRoomRequest,
    CreateModelRequest,
    CreateOutcomeCriterionRequest,
    CreateOutcomeRequest,
    CreateProjectRequest,
    CreateQuestionRequest,
    CreateReportRequest,
    DataRoom,
    DataRoomDocument,
    DataRoomListItem,
    GenericListParams,
    ListAnswersParams,
    ListAssessmentsParams,
    ListDataRoomDocumentsParams,
    ListDataRoomsParams,
    ListReportsParams,
    ModelFilterParams,
    ModelListItem,
    ModelWithRelations,
    Outcome,
    OutcomeCriterion,
    OutcomeCriterionListParams,
    Project,
    ProjectWithRelations,
    Question,
    Report,
    ReportListItem,
    ReportTemplate,
    ReportWithRelations,
    Session,
    UpdateAnswerRequest,
    UpdateAssessmentRequest,
    UpdateDataRoomRequest,
    UpdateModelRequest,
    UpdateOutcomeCriterionRequest,
    UpdateOutcomeRequest,
    UpdateProjectRequest,
    UpdateQuestionRequest,
    UpdateReportRequest,
    UpdateReportTemplateRequest,
    UpdateSortOrderRequest,
    User,
    UserWithRelations,
} from 'silta-ai-backend'
import { FormData } from 'formdata-node'

export type ApiClientOptions = {
    baseURL?: string
    timeout?: number
    sessionToken?: string
    selectedTeamId?: string
    onSessionExpired?: () => void
}

/**
 * WARNING: no type safety here at the moment - need to manually make sure that the API
 * is actually rendering an object of the type expected here!
 */
export class ApiClient {
    private client: AxiosInstance
    private options: ApiClientOptions
    private baseURL: string
    private sessionToken?: string
    private selectedTeamId?: string
    private accessDeniedHandler?: () => void

    constructor(options: ApiClientOptions = {}) {
        const { timeout = 60000, baseURL = 'http://localhost:8938' } = options

        const headers: Partial<RawAxiosRequestHeaders> = {
            'Content-Type': 'application/json',
        }
        this.options = options
        this.baseURL = baseURL
        this.sessionToken = options.sessionToken
        this.selectedTeamId = options.selectedTeamId
        this.client = axios.create({
            baseURL,
            timeout,
            headers,
        })
        this.client.interceptors.request.use((config) => {
            const headers = this.getRequestHeaders()
            Object.entries(headers).forEach(([key, value]) =>
                config.headers.set(key, value)
            )
            return config
        })
        this.client.interceptors.response.use(
            (response) => response, // no-op, only interested in errors
            (error) => {
                if (
                    error.response &&
                    error.response.status === 401 &&
                    this.options.onSessionExpired
                ) {
                    this.options.onSessionExpired()
                }
                if (
                    error.response &&
                    error.response.status === 403 &&
                    this.accessDeniedHandler
                ) {
                    this.accessDeniedHandler()
                }
                return Promise.reject(error)
            }
        )
    }

    getBaseURL(): string {
        return this.baseURL
    }

    getRequestHeaders() {
        const headers: Record<string, string> = {}
        if (this.sessionToken) {
            headers.Authorization = `Bearer ${this.sessionToken}`
        }
        if (this.selectedTeamId) {
            headers['x-silta-team'] = this.selectedTeamId
        }
        return headers
    }

    getSessionToken() {
        return this.sessionToken
    }

    setSessionToken(value: string | undefined): void {
        this.sessionToken = value
    }

    getSelectedTeam(): string | undefined {
        return this.selectedTeamId
    }

    setSelectedTeam(teamId: string | undefined): void {
        this.selectedTeamId = teamId
    }

    public setAccessDeniedHandler(handler: (() => void) | undefined) {
        this.accessDeniedHandler = handler
    }

    /*
     * PROJECTS
     */

    async createProject(
        request: CreateProjectRequest
    ): Promise<ProjectWithRelations> {
        const response = await this.client.post('/projects', request)
        return response.data
    }

    async getProjects(params: GenericListParams = {}): Promise<Project[]> {
        const response = await this.client.get('/projects', { params })
        return response.data
    }

    async getProject(id: string): Promise<ProjectWithRelations> {
        const response = await this.client.get(`/projects/${id}`)
        return response.data
    }

    async updateProject(
        id: string,
        req: UpdateProjectRequest
    ): Promise<ProjectWithRelations> {
        const response = await this.client.patch(`/projects/${id}`, req)
        return response.data
    }

    async deleteProject(id: string): Promise<void> {
        await this.client.delete(`/projects/${id}`)
    }

    /*
     * MODELS
     */

    async createModel(
        request: CreateModelRequest
    ): Promise<ModelWithRelations> {
        const response = await this.client.post('/models', request)
        return response.data
    }

    async getModels(params: GenericListParams = {}): Promise<ModelListItem[]> {
        const response = await this.client.get('/models', { params })
        return response.data
    }

    async getModel(id: string): Promise<ModelWithRelations> {
        const response = await this.client.get(`/models/${id}`)
        return response.data
    }

    async updateModel(
        id: string,
        req: UpdateModelRequest
    ): Promise<ModelWithRelations> {
        const response = await this.client.patch(`/models/${id}`, req)
        return response.data
    }

    async deleteModel(id: string): Promise<void> {
        await this.client.delete(`/models/${id}`)
    }

    /*
     * OUTCOMES
     */

    async getOutcome(id: string): Promise<Outcome> {
        const response = await this.client.get(`/outcomes/${id}`)
        return response.data
    }

    async getOutcomes(params: ModelFilterParams): Promise<Outcome[]> {
        const response = await this.client.get(`/outcomes`, { params })
        return response.data
    }

    async createOutcome(request: CreateOutcomeRequest): Promise<Outcome> {
        const response = await this.client.post(`/outcomes`, request)
        return response.data
    }

    async updateOutcome(
        id: string,
        request: UpdateOutcomeRequest
    ): Promise<Outcome> {
        const response = await this.client.patch(`/outcomes/${id}`, request)
        return response.data
    }

    async deleteOutcome(id: string): Promise<void> {
        await this.client.delete(`/outcomes/${id}`)
    }

    async updateOutcomeOrder(request: UpdateSortOrderRequest): Promise<void> {
        await this.client.put(`/outcomes/order`, request)
    }

    /*
     * QUESTIONS
     */

    async getQuestion(id: string): Promise<Question> {
        const response = await this.client.get(`/questions/${id}`)
        return response.data
    }

    async getQuestions(params: ModelFilterParams): Promise<Outcome[]> {
        const response = await this.client.get(`/questions`, { params })
        return response.data
    }

    async createQuestion(request: CreateQuestionRequest): Promise<Question> {
        const response = await this.client.post(`/questions`, request)
        return response.data
    }

    async updateQuestion(
        id: string,
        request: UpdateQuestionRequest
    ): Promise<Question> {
        const response = await this.client.patch(`/questions/${id}`, request)
        return response.data
    }

    async deleteQuestion(id: string): Promise<void> {
        await this.client.delete(`/questions/${id}`)
    }

    /*
     * OUTCOME CRITERIA
     */

    async getOutcomeCriterion(id: string): Promise<OutcomeCriterion> {
        const response = await this.client.get(`/outcomeCriteria/${id}`)
        return response.data
    }

    async getOutcomeCriteria(
        params: OutcomeCriterionListParams
    ): Promise<Outcome[]> {
        const response = await this.client.get(`/outcomeCriteria`, { params })
        return response.data
    }

    async createOutcomeCriterion(
        request: CreateOutcomeCriterionRequest
    ): Promise<OutcomeCriterion> {
        const response = await this.client.post(`/outcomeCriteria`, request)
        return response.data
    }

    async updateOutcomeCriterion(
        id: string,
        request: UpdateOutcomeCriterionRequest
    ): Promise<OutcomeCriterion> {
        const response = await this.client.patch(
            `/outcomeCriteria/${id}`,
            request
        )
        return response.data
    }

    async deleteOutcomeCriterion(id: string): Promise<void> {
        await this.client.delete(`/outcomeCriteria/${id}`)
    }

    /*
     * DATA ROOMS
     */

    async createDataRoom(request: CreateDataRoomRequest): Promise<DataRoom> {
        const response = await this.client.post('/dataRooms', request)
        return response.data
    }

    async getDataRooms(
        params: ListDataRoomsParams = {}
    ): Promise<DataRoomListItem[]> {
        const response = await this.client.get('/dataRooms', { params })
        return response.data
    }

    async getDataRoom(id: string): Promise<DataRoom> {
        const response = await this.client.get(`/dataRooms/${id}`)
        return response.data
    }

    async updateDataRoom(
        id: string,
        req: UpdateDataRoomRequest
    ): Promise<DataRoom> {
        const response = await this.client.patch(`/dataRooms/${id}`, req)
        return response.data
    }

    async deleteDataRoom(id: string): Promise<void> {
        await this.client.delete(`/dataRooms/${id}`)
    }

    /*
     * DATA ROOM DOCUMENTS
     * Notes:
     * - Creation via DataRoomDocumentUploader.tsx
     * - Updating not supported at the moment (no updatable fields)
     */

    async getDataRoomDocuments(
        params: ListDataRoomDocumentsParams
    ): Promise<DataRoomDocument[]> {
        const response = await this.client.get('/dataRoomDocuments', { params })
        return response.data
    }

    async getDataRoomDocument(id: string): Promise<DataRoomDocument> {
        const response = await this.client.get(`/dataRoomDocuments/${id}`)
        return response.data
    }

    async downloadDataRoomDocument(doc: DataRoomDocument): Promise<Blob> {
        const response = await this.client.get(
            `/dataRoomDocuments/${doc.id}/download`,
            {
                responseType: 'blob',
            }
        )
        return response.data
    }

    async deleteDataRoomDocument(id: string): Promise<void> {
        await this.client.delete(`/dataRoomDocuments/${id}`)
    }

    /*
     * USER
     */

    async getUser(): Promise<UserWithRelations> {
        const response = await this.client.get('/users/me')
        return response.data
    }

    async getUsers(params: GenericListParams = {}): Promise<User[]> {
        const response = await this.client.get('/users', { params })
        return response.data
    }

    async acceptTermsAndConditions(): Promise<User> {
        const response = await this.client.post(
            '/users/me/acceptTermsAndConditions'
        )
        return response.data
    }

    /*
     * ASSESSMENTS
     */

    async createAssessment(
        request: CreateAssessmentRequest
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.post('/assessments', request)
        return response.data
    }

    async getAssessments(
        params: ListAssessmentsParams = {}
    ): Promise<AssessmentListItem[]> {
        const response = await this.client.get('/assessments', { params })
        return response.data
    }

    async getAssessment(id: string): Promise<AssessmentWithRelations> {
        const response = await this.client.get(`/assessments/${id}`)
        return response.data
    }

    async updateAssessment(
        id: string,
        req: UpdateAssessmentRequest
    ): Promise<AssessmentWithRelations> {
        const response = await this.client.patch(`/assessments/${id}`, req)
        return response.data
    }

    async startAssessment(id: string): Promise<AssessmentWithRelations> {
        const response = await this.client.post(`/assessments/${id}/start`)
        return response.data
    }

    async deleteAssessment(id: string): Promise<void> {
        await this.client.delete(`/assessments/${id}`)
    }

    /*
     * ANSWERS
     */

    async startAnswer(id: string): Promise<AssessmentWithRelations> {
        const response = await this.client.post(`/answers/${id}/start`)
        return response.data
    }

    async getAnswer(id: string): Promise<AnswerWithRelations> {
        const response = await this.client.get(`/answers/${id}`)
        return response.data
    }

    async getAnswers(params: ListAnswersParams): Promise<Answer[]> {
        const response = await this.client.get(`/answers`, { params })
        return response.data
    }

    async updateAnswer(
        id: string,
        request: UpdateAnswerRequest
    ): Promise<AnswerWithRelations> {
        const response = await this.client.patch(`/answers/${id}`, request)
        return response.data
    }

    async deleteAnswer(id: string): Promise<void> {
        await this.client.delete(`/answers/${id}`)
    }

    async createAnswer(
        request: CreateAnswerRequest
    ): Promise<AnswerWithRelations> {
        const response = await this.client.post(`/answers`, request)
        return response.data
    }

    /**
     * Reports
     */

    async getReport(id: string): Promise<ReportWithRelations> {
        const response = await this.client.get(`/reports/${id}`)
        return response.data
    }

    async getReports(
        params: ListReportsParams = {}
    ): Promise<ReportListItem[]> {
        const response = await this.client.get(`/reports`, { params })
        return response.data
    }

    async startReport(id: string): Promise<ReportWithRelations> {
        const response = await this.client.post(`/reports/${id}/start`)
        return response.data
    }

    async deleteReport(id: string): Promise<void> {
        try {
            await this.client.delete(`/reports/${id}`)
        } catch (error) {
            console.error('Error deleting report:', error)
            throw error
        }
    }

    async createReport(request: CreateReportRequest): Promise<Report> {
        const response = await this.client.post(`/reports`, request)
        return response.data
    }

    async updateReport(
        id: string,
        request: UpdateReportRequest
    ): Promise<Report> {
        const response = await this.client.patch(`/reports/${id}`, request)
        return response.data
    }

    async downloadReport(id: string): Promise<Blob> {
        const response = await this.client.get(`/reports/${id}/download`, {
            responseType: 'blob',
        })
        return response.data
    }

    /**
     * ReportTemplate
     */

    async getReportTemplate(id: string): Promise<ReportTemplate> {
        const response = await this.client.get(`/reportTemplates/${id}`)
        return response.data
    }

    async getReportTemplates(
        params: GenericListParams = {}
    ): Promise<ReportTemplate[]> {
        const response = await this.client.get('/reportTemplates', { params })
        return response.data
    }

    async uploadReportTemplate(
        file: Blob,
        fileName: string
    ): Promise<ReportTemplate> {
        const formData = new FormData()
        formData.append('files', file, fileName)
        const response = await this.client.post(`/reportTemplates`, formData, {
            timeout: 300000,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    }

    async updateReportTemplate(
        id: string,
        request: UpdateReportTemplateRequest
    ): Promise<ReportTemplate> {
        const response = await this.client.patch(
            `/reportTemplates/${id}`,
            request
        )
        return response.data
    }

    async deleteReportTemplate(id: string): Promise<void> {
        await this.client.delete(`/reportTemplates/${id}`)
    }

    async downloadReportTemplate(id: string): Promise<Blob> {
        const response = await this.client.get(
            `/reportTemplates/${id}/download`,
            {
                responseType: 'blob',
            }
        )
        return response.data
    }

    /**
     * Session
     */
    async login(email: string, password: string): Promise<Session> {
        const response = await this.client.post('/sessions', {
            email,
            password,
        })

        // expiresAt is serialized as a string
        const session: Session = {
            ...response.data,
            expiresAt: new Date(response.data.expiresAt),
        }

        this.sessionToken = session.id

        return session
    }

    async logout(): Promise<void> {
        await this.client.delete('/sessions')
        this.sessionToken = undefined
    }
}
