import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

export const Category = styled.small`
    align-items: center;
    color: ${themeVariables.palettes.neutral700};
    background-color: ${themeVariables.colors.backgroundSurface};
    border-radius: 4px;
    box-shadow: 0 0 0 1px ${themeVariables.palettes.neutral400};
    display: flex;
    line-height: 20px;
    padding: 0 6px;
    font-weight: 500;
`

export const Categories = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 16px;

    &:empty {
        display: none;
    }
`
