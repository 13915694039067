import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { useReportTemplatesQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { route } from '../utils/routes'
import { useUploadReportTemplate } from '../utils/mutations'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'

export function ReportTemplateListPage() {
    const [search, setSearch] = useState<string>('')
    const reportTemplatesQuery = useReportTemplatesQuery({
        search,
    })
    const reportTemplates = reportTemplatesQuery.data || []

    const navigate = useNavigate()

    const uploadReportTemplate = useUploadReportTemplate()

    const uploadTemplate = useCallback(() => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.doc, .docx'
        fileInput.click()
        fileInput.onchange = (e) => {
            const file = (e.target as HTMLInputElement)?.files?.[0]
            if (file) {
                uploadReportTemplate.mutate(file)
            }
        }
    }, [uploadReportTemplate])

    return (
        <Layout>
            <ListTopBar onSearch={(query) => setSearch(query)} />
            <HorizontalLoadingIndicator
                loading={uploadReportTemplate.isPending}
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Report Templates</h1>
                <Button
                    $size="medium"
                    onClick={uploadTemplate}
                    disabled={uploadReportTemplate.isPending}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">Create report template</span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        width: 'minmax(min-content, 90%)',
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (template) => (
                            <DateRenderer value={template.updatedAt} />
                        ),
                    },
                ]}
                data={reportTemplates}
                onRowClick={(template) => {
                    navigate(route('reportTemplate', template.id))
                }}
                stickyHeader
            />
        </Layout>
    )
}
