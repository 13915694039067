import { useEffect, useMemo, useState } from 'react'
import { AssessmentWithRelations, DataRoom, Outcome } from 'silta-ai-backend'
import uniqBy from 'lodash/uniqBy'
import { themeVariables } from 'themes/themeVariables'
import { apiClient } from './clients'
import { useExtract } from './misc'
import { invalidateAssessmentQuery, useAssessmentQuery } from './queries'

/**
 * Extracts an array of outcomes from an assessment.
 */
export function useAssessmentOutcomes(
    assessment: AssessmentWithRelations | null
) {
    return useExtract(assessment, (assessment) => {
        if (!assessment) {
            return []
        }

        const { model } = assessment

        if (!model) {
            return []
        }

        return model.outcomes
    })
}

/**
 * Groups `Outcome[]` by `Outcome#id` for easy access.
 */
export function useAssessmentOutcomesById(
    assessment: AssessmentWithRelations | null
) {
    const outcomes = useAssessmentOutcomes(assessment)

    return useMemo(() => {
        const result: Record<string, Outcome | undefined> = {}

        for (const outcome of outcomes) {
            result[outcome.id] = outcome
        }

        return result
    }, [outcomes])
}

/**
 * Counts `Assessment#answers` with `running` state.
 */
export function getRunningAnswersCount(
    assessment: AssessmentWithRelations | null
): number {
    if (!assessment) {
        return 0
    }

    return assessment.answers.reduce(
        (sum, answer) => sum + Number(answer.status === 'running'),
        0
    )
}

/**
 * Hook that determines if any answer within a given assessment is running.
 */
export function useIsRunning(assessment: AssessmentWithRelations | null) {
    return useMemo(() => getRunningAnswersCount(assessment) > 0, [assessment])
}

/**
 * Polls a given assessment to check if any of its answers is in `running` state.
 * It invalidates associated queries if the "running" queue is empty.
 */
export function useRunningAnswersPollEffect(
    assessment: AssessmentWithRelations | null
) {
    const { id: assessmentId = undefined } = assessment || {}

    const count = getRunningAnswersCount(assessment)
    const summaryStatus = assessment?.summaryStatus

    useEffect(
        function pollAssessmentIfRunningAnswers() {
            let mounted = true

            if (assessmentId && (count || summaryStatus === 'running')) {
                ;(async () => {
                    for (;;) {
                        try {
                            const innerAssessment =
                                await apiClient.getAssessment(assessmentId)

                            if (!mounted) {
                                return
                            }

                            if (
                                count !==
                                    getRunningAnswersCount(innerAssessment) ||
                                innerAssessment.summaryStatus !== summaryStatus
                            ) {
                                invalidateAssessmentQuery(assessmentId)

                                return
                            }
                        } catch (e) {
                            console.warn('Getting assessment failed', e)
                        }

                        await new Promise<void>((resolve) => {
                            setTimeout(resolve, 5000)
                        })

                        if (!mounted) {
                            return
                        }
                    }
                })()
            }

            return () => {
                mounted = false
            }
        },
        [assessmentId, count, summaryStatus]
    )
}

export function getColorForProgress(value: number): string {
    if (!value) {
        return themeVariables.colors.secondary
    }

    return `hsl(${value * 120} 50% 50%)`
}

export const useGetDataRoomsWithDocumentsNewerThanAssessment = (
    assessmentId: string
) => {
    const { data: assessment = null } = useAssessmentQuery({ assessmentId })
    const [dataRoomsWithNewDocuments, setDataRoomsWithNewDocuments] = useState<
        DataRoom[]
    >([])

    useEffect(() => {
        ;(async () => {
            if (
                assessment?.project?.dataRoomId &&
                assessment?.precedentDataRooms &&
                assessment?.updatedAt
            ) {
                const assessmentTimestamp = new Date(
                    assessment.updatedAt
                ).getTime()

                // extract the data room ids
                const dataRoomIds = [
                    assessment.project.dataRoomId,
                    ...assessment.precedentDataRooms.map((dr) => dr.id),
                ]
                // fetch the last document from the data rooms - the sorting on the backend is descending by updateDate
                const dataRooms: DataRoom[] = (
                    await Promise.all(
                        dataRoomIds.map((id) => apiClient.getDataRoom(id))
                    )
                ).flat()

                setDataRoomsWithNewDocuments([])

                // find data rooms that were updated later than the assessment
                dataRooms.forEach((dataRoom) => {
                    if (
                        new Date(dataRoom.updatedAt).getTime() >
                        assessmentTimestamp
                    ) {
                        setDataRoomsWithNewDocuments((current) =>
                            uniqBy([...current, dataRoom], (dr) => dr.id)
                        )
                    }
                })
            }
        })()
    }, [assessment])

    return dataRoomsWithNewDocuments
}
