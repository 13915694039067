import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import difference from 'lodash/difference'
import { ModelWithRelations } from 'silta-ai-backend'
import { useNavigate } from 'react-router'
import { route } from '../../utils/routes'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Icon } from '../../components/Icon'
import { themeVariables } from '../../themes/themeVariables'
import { Select } from '../../components/Form/Select/Select'
import { getUniqueCategoryOptionsWithCount } from '../../utils/categories'
import { SelectableOption } from '../../types/form'
import { QuestionElement } from '../../components/QuestionElement'
import {
    useAssessmentDraft,
    useUpdateAssessmentDraft,
} from '../../state/AssessmentDraft.state'
import { useCreateAssessment } from '../../utils/mutations'
import { Button } from '../../components/Button'
import { filterQuestionsByCategory } from '../../utils/models'

const FiltersContainer = styled.div`
    padding: 9px 24px 14px;
    border-bottom: 1px solid ${themeVariables.colors.border};
`

const FiltersWrap = styled.div`
    display: flex;
    gap: 16px;
    width: 50%;

    > * {
        flex: 1;
    }
`

interface CategoryOption extends SelectableOption {
    count: number
}

const itemLabelRenderer = (option: CategoryOption): ReactNode => (
    <>
        {option.label}{' '}
        <span className={cx('secondary', 'm-l-5')}>
            {option.count} questions
        </span>
    </>
)

export const AssessmentCreateQuestions = () => {
    const updateAssessmentDraft = useUpdateAssessmentDraft()

    const navigate = useNavigate()

    const { questions, filteredQuestions, name, projectId } =
        useAssessmentDraft()

    const createAssessment = useCreateAssessment()

    const [selectedCategories1, setSelectedCategories1] = useState<string[]>([])
    const [selectedCategories2, setSelectedCategories2] = useState<string[]>([])

    const category1Options = useMemo<CategoryOption[]>(
        () => getUniqueCategoryOptionsWithCount(questions || [], 'category1'),
        [questions]
    )
    const category2Options = useMemo<CategoryOption[]>(() => {
        const filtered = filterQuestionsByCategory(
            questions as ModelWithRelations['questions'],
            selectedCategories1,
            'category1'
        )
        return getUniqueCategoryOptionsWithCount(filtered || [], 'category2')
    }, [questions, selectedCategories1])

    useEffect(() => {
        if (!questions) {
            return
        }
        let filtered: ModelWithRelations['questions'] = [
            ...questions,
        ] as ModelWithRelations['questions']
        filtered = filterQuestionsByCategory(
            filtered,
            selectedCategories1,
            'category1'
        )
        filtered = filterQuestionsByCategory(
            filtered,
            selectedCategories2,
            'category2'
        )
        updateAssessmentDraft({
            filteredQuestions: filtered || [],
        })
    }, [questions, selectedCategories1, selectedCategories2])

    useEffect(() => {
        const nonOccuringCategories2 = selectedCategories2.filter(
            (option) => !category2Options.find((o) => o.value === option)
        )
        if (nonOccuringCategories2.length > 0) {
            setSelectedCategories2(
                difference(selectedCategories2, nonOccuringCategories2)
            )
        }
    }, [selectedCategories1, selectedCategories2, category2Options])

    return (
        <FullPageLayout
            breadcrumbs={[
                { label: 'Create assessment', link: route('assessmentCreate') },
                { label: 'Questions' },
            ]}
            closeButtonLink={route('assessmentCreate')}
            headerCTAContent={
                <div className={cx('d-flex', 'g-10')}>
                    <Button
                        onClick={() => {
                            navigate(route('assessments'))
                        }}
                        $variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            createAssessment.mutate()
                        }}
                        $variant="primary"
                        disabled={
                            !name || !projectId || createAssessment.isPending
                        }
                    >
                        <div className={cx('d-flex', 'align-items-center')}>
                            <Icon name="plus" />
                            <span className="m-l-5">Create assessment</span>
                        </div>
                    </Button>
                </div>
            }
        >
            <FiltersContainer>
                <FiltersWrap>
                    <Select
                        label="Filter by category"
                        placeholder="Select a category"
                        multiple={true}
                        options={category1Options}
                        value={selectedCategories1}
                        containerClassName="w-100"
                        onChange={(selection: string[] | undefined) =>
                            setSelectedCategories1(selection || [])
                        }
                        itemLabelRenderer={(option: SelectableOption) =>
                            itemLabelRenderer(option as CategoryOption)
                        }
                        disabled={questions.length === 0}
                    />

                    <Select
                        label="Filter by sub category"
                        placeholder="Select a sub category"
                        multiple={true}
                        options={category2Options}
                        value={selectedCategories2}
                        containerClassName="w-100"
                        onChange={(selection: string[] | undefined) =>
                            setSelectedCategories2(selection || [])
                        }
                        itemLabelRenderer={(option: SelectableOption) =>
                            itemLabelRenderer(option as CategoryOption)
                        }
                        disabled={selectedCategories1.length === 0}
                    />
                </FiltersWrap>
            </FiltersContainer>
            <div
                className={cx(
                    'main-container',
                    'm-t-40',
                    'm-l-auto',
                    'm-r-auto'
                )}
            >
                <h3 className="m-b-25">
                    {filteredQuestions?.length || 0} questions selected
                </h3>
                <div className="p-b-20">
                    {filteredQuestions?.map((question, index) => (
                        <QuestionElement
                            key={index}
                            number={index + 1}
                            content={question.content}
                            category1={question.category1 || ''}
                            category2={question.category2 || ''}
                            className="m-b-20"
                        />
                    ))}
                </div>
            </div>
        </FullPageLayout>
    )
}
