import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

export const NavTabs = styled.div`
    background: ${themeVariables.palettes.neutral200};
    border-radius: 4px;
    display: flex;
    gap: 8px;
    padding: 1px;
    box-shadow: 0 0 0 0.25px ${themeVariables.palettes.neutral400};
    user-select: none;

    a {
        appearance: none;
        background: none;
        color: ${themeVariables.palettes.neutral900} !important;
        display: block;
        padding: 1px 8px;
        border-radius: 3px;
    }

    a.active {
        background: ${themeVariables.palettes.neutral100};
    }
`
