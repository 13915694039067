import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Icon } from './Icon'

export const TableRoot = styled.div`
    border-top: 1px solid ${themeVariables.colors.border};
    display: grid;
    background-color: ${themeVariables.colors.backgroundSurface};
`

const TableCell = styled.div<{
    $columnIndex: number
    $columnsCount: number
    $isHovered?: boolean
    $interactive?: boolean
    $noHorizontalPadding?: boolean
}>`
    background: ${themeVariables.palettes.neutral100};
    padding: 9px;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${themeVariables.colors.border};

    ${({ $columnIndex, $columnsCount, $noHorizontalPadding }) => {
        if (!$noHorizontalPadding && $columnIndex === 0)
            return 'padding-left: 40px;'
        if (!$noHorizontalPadding && $columnIndex === $columnsCount - 1)
            return 'padding-right: 20px;'
        return ''
    }};

    ${({ $isHovered }) =>
        $isHovered &&
        `background-color: ${themeVariables.colors.backgroundContainer};`}

    ${({ $interactive = false }) =>
        $interactive &&
        css`
            cursor: pointer;
        `}
`

const TableHeaderCell = styled(TableCell)<{ $sticky?: boolean }>`
    font-size: ${themeVariables.typography.fontSizes.caption};
    min-height: 43px;
    color: ${themeVariables.colors.secondary};

    ${({ $sticky = false }) =>
        $sticky &&
        css`
            position: sticky;
            top: 0;
            z-index: 1;
            background: rgb(255 255 255 / 0.85);
            backdrop-filter: blur(4px);
        `}
`

interface Column<R> {
    key: string
    render?: ((row: R, hovered: boolean) => ReactNode) | ReactNode
    width?: number | string
    title?: string
}

type Row<D> = D extends (infer E)[] ? E : never

interface TableProps<D> {
    columns: Column<Row<D>>[]
    data: D
    onRowClick?(row: Row<D>, index: number): void
    stickyHeader?: boolean
    noHorizontalPadding?: boolean
}

export function Table<D extends Record<string, unknown>[]>({
    data,
    columns,
    onRowClick,
    stickyHeader,
    noHorizontalPadding,
}: TableProps<D>) {
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)

    const gridTemplateColumns = columns
        .map(({ width = 1 }) =>
            typeof width === 'number' ? `${width}fr` : width
        )
        .join(' ')

    return (
        <TableRoot
            style={{
                gridTemplateColumns,
            }}
        >
            {columns.map((column, columnIndex) => (
                <TableHeaderCell
                    key={column.key}
                    $columnIndex={columnIndex}
                    $columnsCount={columns.length}
                    $sticky={stickyHeader}
                    $noHorizontalPadding={noHorizontalPadding}
                >
                    {column.title}&zwnj;
                </TableHeaderCell>
            ))}
            {data.map((row, rowIndex) =>
                columns.map(({ render, key: columnKey }, columnIndex) => (
                    <TableCell
                        key={`${columnKey}-${rowIndex}`}
                        $columnIndex={columnIndex}
                        $columnsCount={columns.length}
                        onMouseEnter={() => {
                            setHoveredRowIndex(rowIndex)
                        }}
                        onMouseLeave={() => {
                            setHoveredRowIndex(null)
                        }}
                        $isHovered={hoveredRowIndex === rowIndex}
                        onClick={() => {
                            onRowClick?.(row as Row<D>, rowIndex)
                        }}
                        $interactive={!!onRowClick}
                        $noHorizontalPadding={noHorizontalPadding}
                    >
                        {typeof render === 'function'
                            ? render(
                                  row as Row<D>,
                                  hoveredRowIndex === rowIndex
                              )
                            : render != null
                              ? render
                              : columnKey in row
                                ? String(row[columnKey])
                                : '-'}
                    </TableCell>
                ))
            )}
        </TableRoot>
    )
}

export const TableNoContentContainer = styled.div`
    max-width: 400px;
    padding: 22px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

export const TableNoContentIconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${themeVariables.palettes.brand100};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
`

export const TableNoContentIcon = styled(Icon)`
    color: ${themeVariables.colors.brand};
    width: 20px;
    height: 20px;
    svg {
        width: 20px;
        height: 20px;
    }
`
