import React from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from './Icon'

const RunningLabelContainer = styled.div`
    margin-left: 12px;
    background: ${themeVariables.palettes.attention100};
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${themeVariables.palettes.attention800};
    padding: 2px 6px 2px 8px;
    border-radius: 10px;

    svg {
        width: 12px;
        height: 12px;
    }
`

export const RunningLabel = () => {
    return (
        <RunningLabelContainer>
            <small>Running</small> <Icon name="spinner" />
        </RunningLabelContainer>
    )
}
