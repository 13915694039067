import { QueryClient } from '@tanstack/react-query'
import { ApiClient } from 'silta-ai-client'
import { clearStoredSession, getSessionToken } from './session'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 0,
        },
    },
})

const storedSessionToken = getSessionToken()

export const apiClient = new ApiClient({
    baseURL: process.env.REACT_APP_AI_API_URL,
    sessionToken: storedSessionToken ?? undefined,
    onSessionExpired: () => {
        clearStoredSession()
        window.location.reload()
    },
})
