import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { ReportTemplate } from 'silta-ai-backend'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useUpdateReportTemplate } from '../../utils/mutations'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'

interface EditReportTemplateModalProps
    extends Omit<BaseModalProps, 'children'> {
    reportTemplate: ReportTemplate
    onConfirm: (newName: string) => Promise<void>
}

const EditReportTemplateModal = ({
    reportTemplate,
    onConfirm,
    ...props
}: EditReportTemplateModalProps) => {
    const [newName, setNewName] = useState(reportTemplate.name)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Edit report template</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <TextField
                        label="Name"
                        value={newName}
                        onChange={(e) => {
                            setNewName(e.target.value)
                        }}
                        errorMessage={!newName ? 'Name is required' : undefined}
                    />
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await onConfirm(newName)
                                props.onResolve?.()
                            } catch (e) {
                                console.error('Error saving report template', e)
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                        disabled={
                            !newName ||
                            newName === reportTemplate.name ||
                            isLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const editModal = toaster(EditReportTemplateModal, Layer.Modal)

export const useEditReportTemplateModal = () => {
    const updateReportTemplate = useUpdateReportTemplate()

    return useCallback(
        async (reportTemplate: ReportTemplate) => {
            try {
                await editModal.pop({
                    reportTemplate,
                    onConfirm: async (newName) => {
                        await updateReportTemplate.mutateAsync({
                            id: reportTemplate.id,
                            name: newName,
                        })
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [updateReportTemplate]
    )
}
