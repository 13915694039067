import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

const OutcomeDot = styled.div`
    background: ${themeVariables.palettes.neutral300};
    border-radius: 50%;
    height: 12px;
    width: 12px;
    flex-shrink: 0;
`

const OutcomeTileLinkRoot = styled(Link)`
    background: ${themeVariables.palettes.neutral200};
    border-radius: 8px;
    color: inherit !important;
    display: block;
    min-width: 100px;
    padding: 32px 24px;

    h2 {
        line-height: normal;
        margin: 0;
    }
`

export const OutcomeTileGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
    gap: 16px;
    padding: 0 40px;
`

const OutcomeTileLinkInner = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    font-weight: 500;
    margin-bottom: 8px;
`

const OutcomeLabel = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
`

interface OutcomeTileLinkProps {
    color?: string
    label?: string
    answerCount?: ReactNode
    to: string
}

export function OutcomeTileLink({
    color = themeVariables.palettes.neutral300,
    label = 'Label',
    answerCount = 0,
    to,
}: OutcomeTileLinkProps) {
    return (
        <OutcomeTileLinkRoot to={to}>
            <OutcomeTileLinkInner>
                <OutcomeDot
                    style={{
                        background: color,
                    }}
                />
                <OutcomeLabel>{label}</OutcomeLabel>
            </OutcomeTileLinkInner>
            <h2>{answerCount}</h2>
        </OutcomeTileLinkRoot>
    )
}
