import { Question } from 'silta-ai-backend'

export const DEFAULT_CATEGORIES = [
    'Authorization',
    'Completion',
    'Construction',
    'Dispute Resolution',
    'Financial ',
    'Financial',
    'Force Majeure',
    'Market ',
    'Operation',
    'Political',
    'Supply',
    'Technical & Design',
    'Transaction',
]

export const DEFAULT_SUBCATEGORIES = [
    'Bank Account',
    'Borrowings',
    'Business Activities',
    'Change in Law',
    'Climate Risk',
    'Construction Contract',
    'Construction Cost ',
    'Contractor Financial Capacity',
    'Contractor Identification',
    'Contractor Track Record',
    'Contractual Structure',
    'Currency',
    'Debt and Liability Structure',
    'Environmental Compliance',
    'Equipment',
    'Equity Risk',
    'Equity',
    'Expropriation',
    'Feedstock or Production Resources',
    'Financial Model',
    'Financial Statement',
    'General',
    'Group Structure and Related Parties',
    'Insurance',
    'Key Personnel',
    'Labor',
    'Lendings',
    'Offtake Agreement ',
    'Offtaker',
    'Operation Contract',
    'Operator Financial Capacity',
    'Operator Identification',
    'Operator Track Record',
    'Project Cost ',
    'Project Entity Track Record',
    'Project Management Structure',
    'Project Plan',
    'Project Site',
    'Project Timeline',
    'Revenue Structure',
    'Sales Agreement ',
    'Sales Revenue',
    'Security',
    'Shareholder  Track Record',
    'Social Compliance',
    'Structure',
    'Tax',
    'Technology Choice',
    'Utilities',
]

export const getUniqueCategoryOptions =
    (questions: Question[], field: 'category1' | 'category2') => () => {
        const categoryCounts = questions.reduce(
            (acc: Record<string, number>, question: Question) => {
                const category = question[field]
                if (category) {
                    acc[category] = (acc[category] || 0) + 1
                }
                return acc
            },
            {} as Record<string, number>
        )

        return Object.entries(categoryCounts)
            .sort(([catA], [catB]) => catA.localeCompare(catB)) // Sorts alphabetically by category name
            .map(([category, count]) => ({
                value: category,
                label: `${category} (${count})`,
            }))
    }

export const getUniqueCategoryOptionsWithCount = (
    questions: Question[],
    field: 'category1' | 'category2'
) => {
    const categoryCounts = questions.reduce(
        (acc: Record<string, number>, question: Question) => {
            const category = question[field]
            if (category) {
                acc[category] = (acc[category] || 0) + 1
            }
            return acc
        },
        {} as Record<string, number>
    )

    return Object.entries(categoryCounts)
        .sort(([catA], [catB]) => catA.localeCompare(catB)) // Sorts alphabetically by category name
        .map(([category, count]) => ({
            value: category,
            label: category,
            count,
        }))
}
