import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useUserQuery } from '../utils/queries'
import { Icon } from './Icon'
import { route } from '../utils/routes'
import { themeVariables } from '../themes/themeVariables'

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

const Spinner = styled(Icon)`
    width: 50px;
    height: 50px;
    color: ${themeVariables.colors.brand};
    svg {
        width: 50px;
        height: 50px;
    }
`

export const TermsAndConditionsRoute = () => {
    const { data: user = null, isLoading } = useUserQuery()
    const userHasAcceptedTheTerms = useMemo<boolean>(() => {
        if (user == null || !user?.termsAcceptedAt) {
            return false
        }
        const parsedAcceptanceDate = new Date(user.termsAcceptedAt)
        const now = DateTime.now().toUTC().toJSDate()
        return parsedAcceptanceDate.getTime() < now.getTime()
    }, [user])
    const location = useLocation()
    return (
        <>
            {isLoading && (
                <SpinnerContainer>
                    <Spinner name="spinner" />
                </SpinnerContainer>
            )}
            {!isLoading && user && (
                <>
                    {userHasAcceptedTheTerms ? (
                        <Outlet />
                    ) : (
                        <Navigate
                            to={{
                                pathname: route('termsAndConditions'),
                            }}
                            state={{
                                from: location,
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
