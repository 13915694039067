import React from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'
import { useDebounce } from '../utils/useDebounce'
import { Icon } from './Icon'

const TopBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 40px;
    border-bottom: 1px solid ${themeVariables.palettes.neutral300};
`

const Input = styled.input`
    background-color: transparent;
    border: none;
    padding: 10px 8px 10px 30px;
    width: 100%;
    outline: none;
    ::placeholder {
        color: ${themeVariables.colors.secondary};
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: ${themeVariables.colors.secondary};
    }
`

const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
`

const SearchIcon = styled(Icon)`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: ${themeVariables.colors.secondary};
`

export const ListTopBar = ({
    onSearch,
    debounceTime = 100,
}: {
    /**
     * Receives undefined if we're not searching for anything. Never receives ''.
     */
    onSearch?: (query: string) => void
    debounceTime?: number
}) => {
    const debouncedOnSearch = useDebounce(onSearch || (() => {}), debounceTime)

    return (
        <TopBarContainer>
            <InputContainer>
                {onSearch ? (
                    <>
                        <SearchIcon name="search" />
                        <Input
                            placeholder="Search"
                            onChange={(e) =>
                                // e.target.value is falsey if the string is empty
                                debouncedOnSearch(e.target.value)
                            }
                        />
                    </>
                ) : null}
            </InputContainer>
        </TopBarContainer>
    )
}
