import React, { ReactNode, createContext, useContext } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

const SidebarRoot = styled.div`
    border-left: 1px solid ${themeVariables.palettes.neutral300};
    box-sizing: content-box;
    width: 15vw;
    min-width: 280px;
`

interface SidebarProps {
    children?: ReactNode
}

const ShowSidebarContext = createContext<boolean>(false)

interface ShowSidebarProviderProps {
    value: boolean
    children?: ReactNode
}

export function ShowSidebarProvider(props: ShowSidebarProviderProps) {
    return <ShowSidebarContext.Provider {...props} />
}

export function Sidebar(props: SidebarProps) {
    return <SidebarRoot {...props} />
}

export function useShowSidebar() {
    return useContext(ShowSidebarContext)
}

export const SidebarHost = styled.div`
    display: flex;
`

const SidebarSectionRoot = styled.div`
    padding: 24px;

    * + & {
        border-top: 1px solid ${themeVariables.palettes.neutral300};
    }
`

const SidebarSectionTitle = styled.div<{ $emphasized?: boolean }>`
    color: ${themeVariables.palettes.neutral700};
    margin-bottom: 8px;

    ${({ $emphasized = false }) =>
        $emphasized &&
        css`
            font-weight: 500;
            margin-bottom: 20px;
        `}
`

interface SidebarSectionProps {
    title: string
    children?: ReactNode
    emphasized?: boolean
}

export function SidebarSection({
    title,
    emphasized = false,
    children,
}: SidebarSectionProps) {
    return (
        <SidebarSectionRoot>
            <SidebarSectionTitle $emphasized={emphasized}>
                {title}
            </SidebarSectionTitle>
            {children}
        </SidebarSectionRoot>
    )
}
