import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { FormText } from '../components/Form/FormText'
import Logo from '../assets/images/icons/signin-logo.svg'
import { ErrorAlert } from '../components/ErrorAlert'
import EmailVerified from '../assets/images/icons/email-verified.svg'

const validationSchema = yup.object({
    password: yup
        .string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is required'),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Retyping password is required'),
})

export const Reset = () => {
    const [submitForm, setSubmitForm] = useState(false)
    const [resetError, setResetError] = useState<string>('')
    const { token } = useParams<{ token?: string }>()

    const resetPassword = ({
        newPassword,
        passwordResetToken,
    }: {
        newPassword?: string
        passwordResetToken?: string
    }) => {
        // leaving this "empty" implementation after removing the GraphQL stuff
        setResetError('There was an error resetting your password')
        setSubmitForm(true)
        console.log('resetPassword', newPassword, passwordResetToken)
    }

    return (
        <section className="account">
            {!submitForm ? (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="w-100 text-center">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        alt="Silta logo"
                                        className="account__logo"
                                    />
                                </Link>
                                <h2 className="account__title">
                                    Set a new password
                                </h2>
                                <p className="account__text" />
                            </div>
                            {resetError && <ErrorAlert error={resetError} />}
                            <div className="w-100">
                                <div>
                                    <Formik
                                        initialValues={{
                                            password: '',
                                            passwordConfirmation: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            resetPassword({
                                                passwordResetToken: token,
                                                newPassword: values?.password,
                                            })
                                            // submit to backend
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <FormText
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Create a password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    className={
                                                        touched.password &&
                                                        errors.password
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Password*"
                                                />

                                                <FormText
                                                    type="password"
                                                    name="passwordConfirmation"
                                                    id="passwordConfirmation"
                                                    placeholder="Verify Password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={
                                                        values.passwordConfirmation
                                                    }
                                                    className={
                                                        touched.passwordConfirmation &&
                                                        errors.passwordConfirmation
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Repeat password*"
                                                    hint="Must be at least 8 characters"
                                                />

                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="account__btn mt-4 mb-3"
                                                >
                                                    Reset password
                                                </Button>
                                                <div className="mb-3 text-center">
                                                    <Link to="/login">
                                                        Back to login
                                                    </Link>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-sm-4 text-center">
                            <img
                                className="mb-4"
                                src={EmailVerified}
                                alt="email verified"
                            />
                            <h2 className="account__title">Password Reset</h2>
                            <p className="account__text">
                                Your password has been successfully reset. Click
                                below to login.
                            </p>
                            <Link to="/login">
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="account__btn mt-4 mb-3"
                                >
                                    Login
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}
