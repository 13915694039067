import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'
import { useWindowResizeEffect } from '../utils/useWindowResizeEffect'

const AccordionContainer = styled.div<{ $isOpen: boolean }>`
    width: 100%;
    border: 1px solid ${themeVariables.colors.border};
    border-radius: 8px;
    padding: ${({ $isOpen }) => ($isOpen ? '0 20px 20px' : '0 20px')};
`

const AccordionTitle = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px 0;
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
`

const Chevron = styled.div<{ $isOpen: boolean }>`
    width: 0;
    height: 0;
    margin-right: 9px;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent
        ${themeVariables.colors.secondary};
    transition: transform 0.3s ease-in-out;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`

const AccordionContent = styled.div<{ $isOpen: boolean }>`
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: ${({ $isOpen }) => ($isOpen ? 'auto' : '0')};
`

export const Accordion = ({
    title,
    children,
    className,
}: {
    title: React.ReactNode
    children: React.ReactNode
    className?: string
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    useWindowResizeEffect(() => {
        if (contentRef.current?.firstChild instanceof HTMLElement) {
            contentRef.current.style.height = isOpen
                ? `${contentRef.current.firstChild.scrollHeight}px`
                : '0'
        }
    }, [isOpen])

    return (
        <AccordionContainer $isOpen={isOpen} className={className}>
            <AccordionTitle onClick={toggleAccordion}>
                <Chevron $isOpen={isOpen} />
                {title}
            </AccordionTitle>
            <AccordionContent ref={contentRef} $isOpen={isOpen}>
                <div>{children}</div>
            </AccordionContent>
        </AccordionContainer>
    )
}
