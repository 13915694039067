import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import {
    emptyAssessmentDraft,
    useUpdateAssessmentDraft,
} from '../../state/AssessmentDraft.state'

export const AssessmentDraftPage = () => {
    const updateAssessmentDraft = useUpdateAssessmentDraft()

    useEffect(
        () => () => {
            updateAssessmentDraft(emptyAssessmentDraft)
        },
        [updateAssessmentDraft]
    )

    return <Outlet />
}
