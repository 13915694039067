import { useEffect, useRef } from 'react'

export const useWindowResizeEffect = (
    onResize: () => void,
    deps: unknown[] = []
) => {
    const onResizeRef = useRef(onResize)

    if (onResizeRef.current !== onResize) {
        onResizeRef.current = onResize
    }

    useEffect(() => {
        function fn() {
            onResizeRef.current()
        }
        fn()
        window.addEventListener('resize', fn)
        return () => {
            window.removeEventListener('resize', fn)
        }
    }, [...deps])
}
