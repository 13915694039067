import debounce from 'lodash/debounce'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any) => any>(
    callback: T,
    debounceTime: number = 100
): T =>
    useMemo(
        () => debounce(callback, debounceTime) as unknown as T,
        [callback, debounceTime]
    )
