import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { BsArrowLeftShort } from 'react-icons/bs'
import { FormText } from '../components/Form/FormText'
import { CheckEmail } from '../components/CheckEmail'
import Logo from '../assets/images/icons/signin-logo.svg'
import { ErrorAlert } from '../components/ErrorAlert'

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
})

export const Forgot = () => {
    const [userEmail, setUserEmail] = useState('')
    const [submitForm, setSubmitForm] = useState(false)
    const [forgotError, setForgotError] = useState('')

    const resetPassword = (email?: string) => {
        // leaving this "empty" implementation after removing the GraphQL stuff
        setForgotError('')
        console.log('resetPassword', email)
    }

    return (
        <section className="account">
            {!submitForm ? (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="w-100 text-center">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        alt="Silta logo"
                                        className="account__logo"
                                    />
                                </Link>
                                <h2 className="account__title">
                                    Forgot password?
                                </h2>
                                <p className="account__text">
                                    Enter your email and we&apos;ll send you
                                    reset instructions
                                </p>
                            </div>
                            {forgotError && <ErrorAlert error={forgotError} />}
                            <div className="w-100">
                                <div>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            resetPassword(values?.email)

                                            setUserEmail(values?.email)
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <FormText
                                                    type="email"
                                                    name="email"
                                                    id="email" // Added id property
                                                    placeholder="Enter your email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={
                                                        touched.email &&
                                                        errors.email
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    label="Email*"
                                                />
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="account__btn mt-4 mb-3"
                                                >
                                                    Reset password
                                                </Button>
                                                <div className="my-3 text-center">
                                                    <div className="login-back w-100 d-flex align-items-center justify-content-center">
                                                        <Link to="/login">
                                                            <BsArrowLeftShort />
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CheckEmail
                    userEmail={userEmail}
                    setSubmitForm={setSubmitForm}
                />
            )}
        </section>
    )
}
