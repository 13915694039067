import { ReactNode } from 'react'
import { Answer, AssessmentWithRelations } from 'silta-ai-backend'
import { useExtract } from 'utils/misc'

interface AssessmentAnswerCountProps {
    assessment: AssessmentWithRelations
    isEligible(answer: Answer): boolean
}

export function AssessmentAnswerCount({
    assessment,
    isEligible,
}: AssessmentAnswerCountProps): ReactNode {
    return useExtract(assessment, ({ answers }) =>
        answers.reduce((sum, answer) => sum + Number(isEligible(answer)), 0)
    )
}
