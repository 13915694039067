import React, { ReactNode } from 'react'
import { FilterButton } from './FilterButton'

interface DerivableFilterToggleProps {
    onToggle(newValue: boolean): void
    value: boolean
}

export interface FilterToggleProps extends DerivableFilterToggleProps {
    count?: number
}

export function FilterToggle({ value, onToggle, count }: FilterToggleProps) {
    return (
        <>
            {value ? (
                <FilterButton
                    onClick={() => {
                        onToggle(false)
                    }}
                >
                    <small>Hide Filter</small>
                </FilterButton>
            ) : (
                <FilterButton
                    count={count || undefined}
                    onClick={() => {
                        onToggle(true)
                    }}
                >
                    <small>Filter</small>
                </FilterButton>
            )}
        </>
    )
}

export type DerivedFilterToggle = (
    props: DerivableFilterToggleProps
) => ReactNode
