import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Icon, IconName } from '../Icon'
import { Toolbar } from '.'
import {
    ToolbarBreadcrumbs,
    ToolbarBreadcrumbsSeparator,
    ToolbarBreadcrumbsTextItem,
} from './ToolbarBreadcrumbs'

interface BreadcrumbsToolbarProps {
    listPage: {
        name: string
        route: string
        icon: IconName | ReactNode
    }
    currentPageName: string
    rhsAux?: ReactNode
    lhsAux?: ReactNode
}

export function BreadcrumbsToolbar(props: BreadcrumbsToolbarProps) {
    const { lhsAux, currentPageName, rhsAux, listPage } = props

    const { icon } = listPage

    return (
        <Toolbar>
            <Toolbar $grow>
                <ToolbarBreadcrumbs>
                    <div>
                        {typeof icon === 'string' ? (
                            <Icon name={icon as IconName} />
                        ) : (
                            icon
                        )}
                    </div>
                    <Link to={listPage.route}>
                        <ToolbarBreadcrumbsTextItem>
                            {listPage.name}
                        </ToolbarBreadcrumbsTextItem>
                    </Link>
                    <ToolbarBreadcrumbsSeparator />
                    <ToolbarBreadcrumbsTextItem $dimm>
                        {currentPageName}
                    </ToolbarBreadcrumbsTextItem>
                    {lhsAux}
                </ToolbarBreadcrumbs>
            </Toolbar>
            {rhsAux}
        </Toolbar>
    )
}
