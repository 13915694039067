import styled, { css } from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

type BadgeVariant =
    | 'default'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'urgent'

export const Badge = styled.div<{ $variant: BadgeVariant }>`
    border: 1px solid var(--badge-border-color);
    border-radius: 4px;
    padding: 2px 6px;
    font-size: ${themeVariables.typography.fontSizes.caption};
    font-weight: ${themeVariables.typography.fontWeight.bold};
    color: var(--badge-text-color);
    background-color: var(--badge-background-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    ${({ $variant }) => {
        switch ($variant) {
            case 'info':
                return css`
                    --badge-border-color: ${themeVariables.palettes.info300};
                    --badge-text-color: ${themeVariables.colors.brand};
                    --badge-background-color: ${themeVariables.palettes
                        .info100};
                `
            case 'positive':
                return css`
                    --badge-border-color: ${themeVariables.palettes.success300};
                    --badge-text-color: ${themeVariables.palettes.success600};
                    --badge-background-color: ${themeVariables.palettes
                        .success100};
                `

            case 'negative':
                return css`
                    --badge-border-color: ${themeVariables.palettes
                        .critical300};
                    --badge-text-color: ${themeVariables.palettes.critical600};
                    --badge-background-color: ${themeVariables.palettes
                        .critical100};
                `

            case 'warning':
                return css`
                    --badge-border-color: ${themeVariables.palettes.warning300};
                    --badge-text-color: ${themeVariables.palettes.warning600};
                    --badge-background-color: ${themeVariables.palettes
                        .warning100};
                `

            case 'urgent':
                return css`
                    --badge-border-color: ${themeVariables.palettes
                        .critical600};
                    --badge-text-color: ${themeVariables.colors
                        .backgroundSurface};
                    --badge-background-color: ${themeVariables.palettes
                        .critical600};
                `

            case 'default':
            default:
                return css`
                    --badge-border-color: ${themeVariables.colors.border};
                    --badge-text-color: ${themeVariables.palettes.neutral600};
                    --badge-background-color: ${themeVariables.colors
                        .backgroundContainer};
                `
        }
    }}
`
