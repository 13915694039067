import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface SkeletonParagraphProps {
    length?: number
    fontSize: number
    fullLine?: boolean
    lineLengthRandomness?: number
    className?: string
}

const SkeletonContainer = styled.div`
    width: 100%;
`
const SkeletonLine = styled.div<{ size: number }>`
    height: ${({ size }) => `${size}px`};
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: loading 1.5s infinite;
    }

    @keyframes loading {
        0% {
            left: -100%;
        }
        50% {
            left: 100%;
        }
        100% {
            left: 100%;
        }
    }
`

export const SkeletonParagraph = ({
    length = 100,
    fontSize,
    fullLine = false,
    lineLengthRandomness = 0,
    className,
}: SkeletonParagraphProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [averageCharPerLine, setAverageCharPerLine] = useState(80) // Default average character count per line

    useEffect(() => {
        if (ref.current) {
            const computedStyle = window.getComputedStyle(ref.current)
            const width = parseInt(computedStyle.width, 10)
            const characterWidth = fontSize * 0.6 // Estimate character width from font size
            setAverageCharPerLine(Math.floor(width / characterWidth))
        }
    }, [fontSize])

    if (fullLine) {
        return (
            <SkeletonContainer ref={ref} className={className}>
                <SkeletonLine size={fontSize} />
            </SkeletonContainer>
        )
    }
    const lineCount = Math.floor(length / averageCharPerLine)
    const partialLine = (length % averageCharPerLine) / averageCharPerLine

    return (
        <SkeletonContainer ref={ref} className={className}>
            {Array.from({ length: lineCount }, (_, index) => {
                const isFirstLine = index === 0
                const isShorter =
                    !isFirstLine && Math.random() < lineLengthRandomness / 100 // 25% chance to be shorter, except for the first line
                const widthPercentage = isShorter
                    ? Math.random() * (95 - 80) + 80
                    : 100 // Random width between 80% and 95% if shorter
                return (
                    <SkeletonLine
                        key={index}
                        size={fontSize}
                        style={{ width: `${widthPercentage}%` }}
                    />
                )
            })}
            {partialLine > 0 && (
                <SkeletonLine
                    key="partial"
                    size={fontSize}
                    style={{ width: `${partialLine * 100}%` }}
                />
            )}
        </SkeletonContainer>
    )
}
