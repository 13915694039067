import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

interface UseTransformedSearchParamOptions<T> {
    key: string
    transform(entries: string[]): T
}

export function useTransformedSearchParam<T>({
    key,
    transform,
}: UseTransformedSearchParamOptions<T>) {
    const [params] = useSearchParams()

    return useMemo(() => transform(params.getAll(key)), [transform, params])
}
