import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown, DropdownContent } from './Dropdown'
import { Button } from './Button'
import { Icon } from './Icon'
import { ModelQuestionsFilter } from '../utils/filters'
import { themeVariables } from '../themes/themeVariables'
import { Separator } from './Separator'
import { useDebounce } from '../utils/useDebounce'
import { Select } from './Form/Select/Select'

const StyledDropdownContent = styled(DropdownContent)`
    max-height: none;
    overflow: visible;
`

const FilterButtonContent = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`

const FilterButtonCount = styled.div`
    align-items: center;
    background: ${themeVariables.palettes.brand600};
    border-radius: 6px;
    color: ${themeVariables.palettes.neutral100};
    display: flex;
    font-size: 11px;
    height: 13px;
    justify-content: center;
    letter-spacing: -0.4px;
    line-height: normal;
    min-width: 13px;
`

const QuestionSearchInputWrap = styled.div`
    width: 100%;
    padding: 8px 12px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
`

const QustionSearchIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
`

const QuestionSearchInput = styled.input`
    border: none;
    background-color: transparent;
    outline: none;
    color: ${themeVariables.colors.primary};
    width: 100%;
    ::placeholder {
        color: ${themeVariables.colors.secondary};
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: ${themeVariables.colors.secondary};
    }
`

const SelectInputsContainer = styled.div`
    padding: 24px 20px 16px;
`

const ModelQuestionsFilterDropdownContent = ({
    dismiss,
    occurringCategories = [],
    occurringSubCategories = [],
}: {
    dismiss: () => void
    occurringCategories: { label: string; value: string }[]
    occurringSubCategories: { label: string; value: string }[]
}) => {
    const filter = ModelQuestionsFilter.useValue()
    const [categoryFilter, setCategoryFilter] = useState<string>(
        filter.category?.value[0] || ''
    )
    const [subCategoryFilter, setSubCategoryFilter] = useState<string>(
        filter.subCategory?.value[0] || ''
    )
    const [contentFilter, setContentFilter] = useState<string>(
        filter.content?.value[0] || ''
    )

    const setFilter = ModelQuestionsFilter.useSetFilterCallback()
    const setFilterDebounced = useDebounce(setFilter, 300)

    const prepareNewFilter = (
        filterKey: keyof ModelQuestionsFilter,
        value: string | undefined
    ): ModelQuestionsFilter => {
        const newFilter: ModelQuestionsFilter = {
            ...filter,
        }
        if (value) {
            newFilter[filterKey] = {
                operator: filterKey === 'content' ? 'contains' : 'is',
                value: [value],
            }
        } else {
            delete newFilter[filterKey]
        }
        return newFilter
    }

    return (
        <div>
            <QuestionSearchInputWrap>
                <QustionSearchIcon name="search" />
                <QuestionSearchInput
                    placeholder="Search questions"
                    value={contentFilter}
                    autoFocus
                    onChange={(e) => {
                        setFilterDebounced(
                            prepareNewFilter('content', e.target.value)
                        )
                        setContentFilter(e.target.value)
                    }}
                />
            </QuestionSearchInputWrap>
            <Separator />
            <SelectInputsContainer>
                <Select
                    label="Category"
                    onChange={(selection: string | undefined) => {
                        setCategoryFilter(selection || '')
                        const newFilter = prepareNewFilter(
                            'category',
                            selection
                        )
                        delete newFilter.subCategory
                        setFilter(newFilter)
                        setSubCategoryFilter('')
                        dismiss()
                    }}
                    options={occurringCategories}
                    value={categoryFilter}
                />
                <Select
                    label="Sub Category"
                    onChange={(selection: string | undefined) => {
                        setSubCategoryFilter(selection || '')
                        setFilter(prepareNewFilter('subCategory', selection))
                        dismiss()
                    }}
                    options={occurringSubCategories}
                    value={subCategoryFilter}
                    containerClassName="m-t-25"
                    disabled={!categoryFilter}
                />
            </SelectInputsContainer>
        </div>
    )
}

export const ModelQuestionsFilterDropdown = ({
    occurringCategories,
    occurringSubCategories,
}: {
    occurringCategories: { label: string; value: string }[]
    occurringSubCategories: { label: string; value: string }[]
}) => {
    const count = ModelQuestionsFilter.useCount()
    return (
        <Dropdown
            trigger={({ onClick }) => (
                <Button $variant="secondary" onClick={onClick}>
                    <FilterButtonContent>
                        <Icon name="switches" />
                        Display
                        {count > 0 && (
                            <FilterButtonCount>
                                <span>{count}</span>
                            </FilterButtonCount>
                        )}
                    </FilterButtonContent>
                </Button>
            )}
        >
            {(dismiss) => (
                <StyledDropdownContent $minWidth="320px" $maxWidth="320px">
                    <ModelQuestionsFilterDropdownContent
                        dismiss={dismiss}
                        occurringCategories={occurringCategories}
                        occurringSubCategories={occurringSubCategories}
                    />
                </StyledDropdownContent>
            )}
        </Dropdown>
    )
}
