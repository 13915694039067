import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from '../themes/themeVariables'
import { Icon, IconName, iconsList } from './Icon'

type OutcomeBadgeVariant =
    | 'default'
    | 'info'
    | 'positive'
    | 'negative'
    | 'warning'
    | 'urgent'

const AnswerBadgeRoot = styled.div<{
    $isButton: boolean
    $shadow: boolean
    $variant: OutcomeBadgeVariant
}>`
    align-items: center;
    appearance: none;
    background: var(--outcome-badge-background-color);
    box-shadow: 0 0 0 1px var(--outcome-badge-border-color);
    border: 0;
    border-radius: 4px;
    display: inline-flex;
    gap: 5px;
    height: 20px;
    padding: 2px 6px;
    color: var(--outcome-badge-text-color);
    font-size: ${themeVariables.typography.fontSizes.caption};

    ${({ $variant }) => {
        switch ($variant) {
            case 'info':
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .info300};
                    --outcome-badge-text-color: ${themeVariables.colors.brand};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .info100};
                `
            case 'positive':
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .success300};
                    --outcome-badge-text-color: ${themeVariables.palettes
                        .success600};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .success100};
                `

            case 'negative':
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .critical300};
                    --outcome-badge-text-color: ${themeVariables.palettes
                        .critical600};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .critical100};
                `

            case 'warning':
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .warning300};
                    --outcome-badge-text-color: ${themeVariables.palettes
                        .warning600};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .warning100};
                `

            case 'urgent':
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .critical600};
                    --outcome-badge-text-color: ${themeVariables.colors
                        .backgroundSurface};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .critical600};
                `

            case 'default':
            default:
                return css`
                    --outcome-badge-border-color: ${themeVariables.palettes
                        .neutral300};
                    --outcome-badge-text-color: ${themeVariables.palettes
                        .neutral600};
                    --outcome-badge-background-color: ${themeVariables.palettes
                        .neutral100};
                `
        }
    }}
    ${({ $shadow }) =>
        $shadow
            ? css`
                  box-shadow:
                      0px 1px 1px 0px #0000001f,
                      0px 0px 0px 1px #3c425729,
                      0px 2px 5px 0px #3c425714;
              `
            : ''}
    ${({ $isButton }) =>
        $isButton
            ? css`
                  outline: none;
                  cursor: pointer;

                  &:hover {
                      background: ${themeVariables.colors.backgroundContainer};
                  }

                  &:active {
                      box-shadow:
                          0px 2px 5px 0px #40445214,
                          0px 0px 0px 4px #0196ed5c,
                          0px 0px 0px 1px #40445229,
                          0px 1px 1px 0px #0000001f;
                  }
              `
            : ''}
`

export const AnswerBadgeDot = styled.div<{ $size?: number }>`
    border-radius: 50%;
    height: ${({ $size = 10 }) => $size}px;
    width: ${({ $size = 10 }) => $size}px;
`

const AnswerBadgeIcon = styled(Icon)<{ $size: number }>`
    height: ${({ $size }) => $size}px;
    width: ${({ $size }) => $size}px;
    svg {
        height: ${({ $size }) => $size}px;
        width: ${({ $size }) => $size}px;
    }
`

export const OutcomeBadge = ({
    label,
    icon,
    color,
    iconSize = 10,
    shadow = false,
    onClick,
    className,
    $variant = 'default',
}: {
    label: string
    icon?: string
    color: string
    iconSize?: number
    shadow?: boolean
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    className?: string
    $variant?: OutcomeBadgeVariant
}) => {
    return (
        <AnswerBadgeRoot
            as={onClick ? 'button' : 'div'}
            onClick={onClick}
            $isButton={!!onClick}
            $shadow={shadow}
            className={className}
            $variant={$variant}
        >
            {(!icon || !(icon in iconsList)) && (
                <AnswerBadgeDot
                    $size={iconSize}
                    style={{ backgroundColor: color }}
                />
            )}
            {!!icon && icon in iconsList && (
                <AnswerBadgeIcon
                    name={icon as IconName}
                    style={{ color }}
                    $size={iconSize}
                />
            )}
            {label}
        </AnswerBadgeRoot>
    )
}
