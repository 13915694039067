import React, {
    ReactNode,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react'
import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
import { useDiscardableEffect } from 'toasterhea'
import { RejectionReason } from '../../utils/exceptions'

// SINCE THIS WAS COPIED FROM STREAMR I MOCKED THE `TABLET` CONSTANT FOR NOW
const TABLET = '(min-width: 768px)'

export const MODAL_CONTAINER_ID = 'MODAL_CONTAINER'

const bringIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(1%) translateZ(0) scale(0.98);
    }
    to {
        opacity: 1;
        transform: translateZ(0) scale(1) translateY(0);
    }
`

const bringOut = keyframes`
    from {
        transform: translateZ(0) scale(1) translateY(0);
    }
    to {
        transform: translateY(1%) translateZ(0) scale(0.98);
    }
`

const fadeAway = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

const wiggle = keyframes`
    0% { transform: rotate(0deg); }
    80% { transform: rotate(0deg); }
    85% { transform: rotate(5deg); }
    95% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
`

const WIGGLE_TIME_MS = 500

const NoScrollStyles = createGlobalStyle`
    body {
        overflow: hidden !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }
`

const AnimatedWrap = styled.div<{ $dismissed?: boolean }>`
    animation: 300ms
        ${({ $dismissed = false }) => ($dismissed ? bringOut : bringIn)}
        ease-in-out 1;
    animation-fill-mode: both;
    backface-visibility: hidden;
`

const Root = styled.div<{ $dismissed?: boolean; $dark?: boolean }>`
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    left: 0;
    line-height: 24px;
    overflow: auto;
    position: fixed;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 1;

    ${({ $dismissed = false }) =>
        $dismissed &&
        css`
            animation: 300ms ${fadeAway} ease-in-out 1;
            animation-fill-mode: both;
        `}

    ${({ $dark = true }) =>
        $dark &&
        css`
            background-color: rgba(48, 49, 61, 0.3);
        `}
`

const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`

const OuterWrap = styled.div`
    align-items: center;
    color: #323232;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: relative;
`

const InnerWrap = styled.div`
    max-height: 100%;
    overflow: visible;
`

const Pad = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-top: 64px;
    padding-bottom: 64px;
`

const Interactive = styled.div`
    max-width: 90vw;
    min-width: 12rem;
    pointer-events: auto;
    width: max-content;
`

const Wigglable = styled.div<{ $wiggle?: boolean }>`
    background: #ffffff;
    border-radius: 8px;
    box-shadow:
        0px 8px 12px 0px #091e4226,
        0px 0px 1px 0px #091e424f;

    min-height: 160px;
    ${({ $wiggle = false }) =>
        $wiggle &&
        css`
            animation: ${WIGGLE_TIME_MS}ms ${wiggle} ease-in-out;
        `}
`

export const Footer = styled.div<{
    $borderless?: boolean
    $spacious?: boolean
    $autoHeight?: boolean
}>`
    align-items: center;
    display: flex;
    height: 80px;
    padding: 0 40px;
    width: 100%;

    ${({ $borderless = false }) =>
        !$borderless &&
        css`
            border-top: 1px solid #f3f3f3;
        `}

    ${({ $spacious = false }) =>
        $spacious &&
        css`
            @media ${TABLET} {
                height: 120px;
            }
        `}

    ${({ $autoHeight = false }) =>
        $autoHeight &&
        css`
            height: auto !important;
        `}
`

export interface BaseModalProps {
    children?: ReactNode | ((close: (reason?: unknown) => void) => ReactNode)
    darkBackdrop?: boolean
    onBeforeAbort?: (reason?: unknown) => boolean | null | void
    onReject?: (reason?: unknown) => void
    onResolve?: (...args: unknown[]) => unknown
}

export const BaseModal = ({
    children,
    darkBackdrop,
    onBeforeAbort,
    onReject,
    onResolve: _,
    ...props
}: BaseModalProps) => {
    const [dismissed, dismiss] = useReducer(() => true, false)
    const [wiggleHash, setWiggleHash] = useState<number>(0)

    const rootRef = useRef<HTMLDivElement>(null)

    useDiscardableEffect((discard) => {
        const { current: root } = rootRef

        root?.addEventListener('animationend', ({ animationName }) => {
            if (animationName === fadeAway.getName()) {
                discard()
            }
        })

        dismiss()
    })

    function wiggle() {
        setWiggleHash(Math.round(Math.random() * 1000))
        setTimeout(() => {
            setWiggleHash(0)
        }, WIGGLE_TIME_MS)
    }

    function close(reason?: unknown) {
        const beforeAbort = onBeforeAbort?.(reason)

        if (beforeAbort === false) {
            wiggle()
        }

        if (beforeAbort === null || beforeAbort === false) {
            return
        }

        onReject?.(reason)
    }

    const closeRef = useRef(close)

    closeRef.current = close

    useEffect(() => {
        function onKeyDown({ key }: KeyboardEvent) {
            if (key === 'Escape') {
                closeRef.current(RejectionReason.EscapeKey)
            }
        }

        window.addEventListener('keydown', onKeyDown)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [])

    return (
        <Root
            ref={rootRef}
            $dismissed={dismissed}
            $dark={darkBackdrop}
            {...props}
        >
            <NoScrollStyles />
            <Backdrop onMouseDown={() => wiggle()} />
            <OuterWrap>
                <InnerWrap>
                    <Pad>
                        <Interactive>
                            <AnimatedWrap $dismissed={dismissed}>
                                <Wigglable
                                    $wiggle={wiggleHash > 0}
                                    data-wiggle-hash={wiggleHash} // this is needed to trigger the css animation again
                                >
                                    {typeof children === 'function'
                                        ? children(close)
                                        : children}
                                </Wigglable>
                            </AnimatedWrap>
                        </Interactive>
                    </Pad>
                </InnerWrap>
            </OuterWrap>
        </Root>
    )
}
