import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Anchor } from './Anchor'

const TooltipDisplayRoot = styled.div`
    left: 0;
    max-width: 240px;
    pointer-events: none;
    position: absolute;
    top: 0;
    padding: 4px;
    z-index: 5;
`

const TooltipDisplayInner = styled.div`
    background: ${themeVariables.palettes.neutral900};
    border-radius: 4px;
    color: ${themeVariables.palettes.neutral100};
    padding: 2px 6px;
    word-break: break-word;
`

interface TooltipDisplayProps {
    x: number
    y: number
    children?: ReactNode
}

function TooltipDisplay(props: TooltipDisplayProps) {
    const { x, y, children } = props

    if (!children) {
        return null
    }

    return (
        <TooltipDisplayRoot
            style={{
                transform: `
                    translate(${x}px, ${y}px)
                    translate(-50%, -100%)
                    translateX(max(0px, calc(50% - ${x}px)))
                    translateY(max(0px, calc(100% - ${y}px)))
                    translateX(min(0px, calc(${document.documentElement.clientWidth}px - ${x}px - 50%)))
                `,
            }}
        >
            <TooltipDisplayInner>
                {typeof children === 'string' ? (
                    <small>{children}</small>
                ) : (
                    children
                )}
            </TooltipDisplayInner>
        </TooltipDisplayRoot>
    )
}

function topCenterTransform(rect: DOMRect | undefined): [number, number] {
    return rect
        ? [
              (rect.x + rect.width / 2 + window.scrollX) | 0,
              (rect.y + window.scrollY) | 0,
          ]
        : [0, 0]
}

interface TooltipProps {
    anchorDisplay?: 'inline' | 'inline-block'
    children?: ReactNode
    content?: ReactNode
}

export function Tooltip(props: TooltipProps) {
    const { anchorDisplay, children, content } = props

    const [visible, setVisible] = useState(false)

    return (
        <Anchor
            onMouseEnter={() => {
                setVisible(true)
            }}
            onMouseLeave={() => {
                setVisible(false)
            }}
            translate={topCenterTransform}
            display={anchorDisplay}
            componentProps={{ children: visible ? content : undefined }}
            component={TooltipDisplay}
        >
            {children}
        </Anchor>
    )
}
