import React from 'react'
import styled from 'styled-components'
import { CURRENT_THEME } from 'utils/currentTheme'
import defaultLogo from 'themes/default/logo.svg'
import adbLogo from 'themes/adb/logo.svg'

let logoFile: typeof import('*.svg')
switch (CURRENT_THEME) {
    case 'adb':
        logoFile = adbLogo
        break
    case 'default':
    default:
        logoFile = defaultLogo
        break
}

const LogoImage = styled.img<{ $size: 'full' | 'small' }>`
    width: ${({ $size }) =>
        $size === 'full'
            ? 'var(--ai-logo-full-size-width)'
            : 'var(--ai-logo-small-size-width)'};
    height: ${({ $size }) =>
        $size === 'full'
            ? 'var(--ai-logo-full-size-height)'
            : 'var(--ai-logo-small-size-height)'};
`

export const Logo = ({
    size = 'full',
    className,
}: {
    size?: 'full' | 'small'
    className?: string
}) => (
    <LogoImage
        src={logoFile as unknown as string}
        alt="logo"
        $size={size}
        className={className}
    />
)
