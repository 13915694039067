import { createGlobalStyle } from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

const spacings = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95, 100,
]

export const GlobalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    ${spacings.map(
        (spacing) => `
        .p-${spacing} {
            padding: ${spacing}px;
        }
        .p-l-${spacing} {
            padding-left: ${spacing}px;
        }
        .p-r-${spacing} {
            padding-right: ${spacing}px;
        }
        .p-t-${spacing} {
            padding-top: ${spacing}px;
        }
        .p-b-${spacing} {
            padding-bottom: ${spacing}px;
        }
        .m-${spacing} {
            margin: ${spacing}px;
        }
        .m-l-${spacing} {
            margin-left: ${spacing}px;
        }
        .m-r-${spacing} {
            margin-right: ${spacing}px;
        }
        .m-t-${spacing} {
            margin-top: ${spacing}px;
        }
        .m-b-${spacing} {
            margin-bottom: ${spacing}px;
        }

        .g-${spacing} {
            gap: ${spacing}px;
        }
    `
    )}

    .m-r-auto {
        margin-right: auto;
    }

    .m-l-auto {
        margin-left: auto;
    }

    .d-flex {
        display: flex;
    }

    .flex-column {
        flex-direction: column;
    }

    .flex-row {
        flex-direction: row;
    }

    .flex-1 {
        flex: 1;
    }

    .align-items-center {
        align-items: center;
    }

    .justify-content-center {
        justify-content: center;
    }

    .justify-space-between {
        justify-content: space-between;
    }

    .w-100 {
        width: 100%;
    }

    .main-container {
        max-width: 584px;        
    }

    .primary {
        color: ${themeVariables.colors.primary}
    }

    .secondary {
        color: ${themeVariables.colors.secondary}
    }

    .no-shadow {
        box-shadow: none;
    }

    .no-wrap {
        white-space: nowrap;
    }

`
