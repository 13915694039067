import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

export const BaseButton = styled.button<{ disabled?: boolean }>`
    background: transparent;
    border: none;
    padding: 4px 8px;
    margin: 0;
    appearance: none;
    border-radius: 4px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:active {
        box-shadow:
            0px 2px 5px 0px #40445214,
            0px 0px 0px 4px #0196ed5c,
            0px 0px 0px 1px #40445229,
            0px 1px 1px 0px #0000001f;
    }
    &:hover,
    &:active {
        background-color: ${themeVariables.colors.backgroundContainer};
    }
`
