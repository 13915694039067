import { AssessmentInnerLayout } from 'components/AssessmentPageLayout'
import { CircularProgressIndicator } from 'components/CircularProgressIndicator'
import { Table, TableRoot } from 'components/Table'
import { ToolbarButton } from 'components/Toolbar'
import { AssessmentNavToolbar } from 'components/Toolbar/AssessmentNavToolbar'
import { useCreateReportModal } from 'components/modals/CreateReportModal'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import cx from 'classnames'
import { useRequiredParam } from 'utils/misc'
import { useAssessmentQuery, useReportsQuery } from 'utils/queries'
import { getProgressColor } from 'utils/reports'
import { route } from 'utils/routes'

const Root = styled.div`
    padding-bottom: 96px;

    ${TableRoot} {
        border-top: 0;
    }
`

export function AssessmentShowReportsPage() {
    const assessmentId = useRequiredParam('id')

    const { data: assessment = null } = useAssessmentQuery({ assessmentId })

    const navigate = useNavigate()

    const reportsQuery = useReportsQuery({
        assessmentId,
    })

    const reports = reportsQuery.data || []

    const createReport = useCreateReportModal()

    return (
        <AssessmentInnerLayout
            assessmentId={assessmentId}
            toolbars={
                <AssessmentNavToolbar
                    assessmentId={assessmentId}
                    rhsAux={
                        <ToolbarButton
                            onClick={() => {
                                createReport(assessmentId)
                            }}
                            prepend={
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5 1.5V8.5M1.5 5H8.5"
                                        stroke="#30313D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                        >
                            Create report
                        </ToolbarButton>
                    }
                    answersCount={assessment?.answers.length}
                    reportsCount={reports.length}
                />
            }
        >
            <Root>
                <Table
                    columns={[
                        {
                            key: 'name',
                            title: 'Name',
                            render: (report) => report.name,
                            width: 'minmax(min-content, 40%)',
                        },
                        {
                            title: 'Template',
                            key: 'template',
                            render: (report) => report.reportTemplate.name,
                        },
                        {
                            title: 'Last update',
                            key: 'updatedAt',
                            render: (report) => (
                                <DateRenderer value={report.updatedAt} />
                            ),
                        },
                        {
                            title: 'Progress',
                            key: 'progress',
                            render: (report) => (
                                <div
                                    className={cx(
                                        'd-flex',
                                        'g-10',
                                        'align-items-center'
                                    )}
                                >
                                    <CircularProgressIndicator
                                        progress={report.progress / 100}
                                        color={getProgressColor(report.status)}
                                    />
                                    {report.progress}%
                                </div>
                            ),
                        },
                    ]}
                    data={reports}
                    onRowClick={(report) => {
                        navigate(route('report', report.id))
                    }}
                    stickyHeader
                />
            </Root>
        </AssessmentInnerLayout>
    )
}
