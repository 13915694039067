import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import cx from 'classnames'
import { Answer, AnswerWithRelations, DataRoomDocument } from 'silta-ai-backend'
import { toaster } from 'toasterhea'
import { Reference } from 'utils/references'
import { downloadDocument } from 'utils/download'

import { FullPageLayout } from '../components/FullPageLayout'
import { route } from '../utils/routes'
import { AnswerContent } from '../components/AnswerContent'
import { Sidebar, SidebarSection } from '../components/Sidebar'
import { OutcomeBadge } from '../components/OutcomeBadge'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Categories, Category } from '../components/Categories'
import {
    useExtractedReferences,
    useRunningAnswerPollEffect,
} from '../utils/answers'
import { Accordion } from '../components/Accordion'
import { themeVariables } from '../themes/themeVariables'
import { TextLink } from '../components/TextLink'
import { Icon } from '../components/Icon'
import { ReviewStatusDropdown } from '../components/dropdowns/ReviewStatusDropdown'
import { useAnswerQuery, useAssessmentQuery } from '../utils/queries'
import { useAssessmentOutcomes } from '../utils/assessments'
import { OutcomeDropdown } from '../components/dropdowns/OutcomeDropdown'
import { Tooltip } from '../components/Tooltip'
import { useDeleteAnswer, useRunAnswer } from '../utils/mutations'
import { ConfirmationModal } from '../components/modals/ConfirmationModal'
import { Layer } from '../utils/layers'
import { FileTypeIcon } from '../components/FileTypeIcon'
import { SkeletonParagraph } from '../components/SkeletonParagraph'
import { useEditQuestionModal } from '../components/modals/EditQuestionModal'
import { Button } from '../components/Button'
import { RunningLabel } from '../components/RunningLabel'
import { Separator } from '../components/Separator'

const MAX_CONTENT_LENGTH = 1000

const InnerContent = styled.div`
    max-width: 800px;
    padding: 0 12px;
    margin: 60px auto 20px;
`

const DocumentTile = styled.div`
    background-color: ${themeVariables.colors.backgroundContainer};
    border-radius: 4px;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
`

const DocumentLink = styled(TextLink)`
    font-weight: ${themeVariables.typography.fontWeight.normal};
    display: inline-flex;
    align-items: center;
    gap: 8px;
`

const StyledLinkIcon = styled(Icon)`
    display: inline;
    transform: translateX(-4px);
`

const ReferenceBadge = styled.div`
    color: ${themeVariables.colors.secondary};
    padding: 0 4px;
    border: 1px solid ${themeVariables.palettes.neutral400};
    border-radius: 2px;
    white-space: nowrap;
    font-size: ${themeVariables.typography.fontSizes.caption};
    line-height: ${themeVariables.typography.lineHeight.caption};
`

const OutcomeCriteriaHeading = styled.p`
    font-size: ${themeVariables.typography.fontSizes.large};
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
`

const OutcomesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    border-radius: 8px;
    margin-top: 16px;
`

const QuestionOutcomeGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`
const QuestionOutcome = styled.div`
    display: contents;
    & > div {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid ${themeVariables.colors.border};
    }
    &:last-child > div {
        border-bottom: none;
    }
`

const QuestionOutcomeDotContainer = styled.div`
    padding-right: 40px !important;
`

const QuestionOutcomeDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
`

export function AnswerShowPage() {
    const { answerId, assessmentId } = useParams<{
        answerId: string
        assessmentId: string
    }>()

    const navigate = useNavigate()

    const { data: assessment = null, isFetching: isAssessmentFetching } =
        useAssessmentQuery({ assessmentId })
    const { data: answer = null, isFetching: isAnswerFetching } =
        useAnswerQuery({ answerId })

    const outcomes = useAssessmentOutcomes(assessment)

    useRunningAnswerPollEffect(answer || null)

    const runAnswer = useRunAnswer()

    const openQuestionEditModal = useEditQuestionModal(answer || ({} as Answer))

    const deleteAnswer = useDeleteAnswer()
    const confirmModal = toaster(ConfirmationModal, Layer.Modal)
    const triggerDeleteAnswer = useCallback(async () => {
        if (!answer) {
            return
        }
        try {
            const confirmed = await confirmModal.pop({
                title: 'Delete Question?',
                content: `Are you sure you want to delete question “${answer.question}”?`,
                confirmButtonText: 'Delete Question',
                cancelButtonText: 'Cancel',
            })
            if (confirmed) {
                deleteAnswer.mutate(answer, {
                    onSuccess: () => {
                        navigate(
                            route('assessmentAnswers', answer.assessmentId)
                        )
                    },
                })
            }
        } catch (e) {
            // do nothing
        }
    }, [deleteAnswer, answerId])

    const questionNumber = useMemo<string>(() => {
        if (!assessment) {
            return ''
        }
        return String(
            assessment.answers.findIndex((a) => a.id === answerId) + 1
        )
    }, [assessment, answerId])

    const category1 = useMemo(() => answer?.sourceQuestion?.category1, [answer])
    const category2 = useMemo(() => answer?.sourceQuestion?.category2, [answer])

    const { projectDataRoomMatches, precedentsDataRoomMatches, webMatches } =
        useMemo(() => {
            if (!answer || !assessment) {
                return {
                    projectDataRoomMatches: [],
                    precedentsDataRoomMatches: [],
                }
            }
            const projectDataRoomMatches: AnswerWithRelations['sourceMaterials'] =
                []
            const precedentsDataRoomMatches: AnswerWithRelations['sourceMaterials'] =
                []
            const webMatches: AnswerWithRelations['sourceMaterials'] = []

            answer.sourceMaterials.forEach((m) => {
                if (m.dataRoomId === assessment.project.dataRoomId) {
                    projectDataRoomMatches.push(m)
                } else if (m.dataRoomId) {
                    precedentsDataRoomMatches.push(m)
                } else if (m.url) {
                    webMatches.push(m)
                } else {
                    throw new Error(
                        `Whoa! Don't know how to categorize match: ${JSON.stringify(m)}`
                    )
                }
            })
            return {
                projectDataRoomMatches,
                precedentsDataRoomMatches,
                webMatches,
            }
        }, [answer, assessment])

    const { references, matches } = useExtractedReferences(answer)

    const getReferenceTitleWithSections = (reference: Reference) => {
        const sectionDescriptions = matches
            .filter((m) => m.reference.index === reference.index)
            .map((m) => m.sectionDescription)
            .filter((desc) => !!desc)

        return `${sectionDescriptions.join(', ')}${
            sectionDescriptions.length > 0 ? ' - ' : ''
        }${reference.title}`
    }

    return (
        <FullPageLayout
            breadcrumbs={
                assessment
                    ? [
                          {
                              label: assessment.name,
                              link: route('assessmentAnswers', assessmentId!),
                          },
                          {
                              label: (
                                  <div
                                      className={cx(
                                          'd-flex',
                                          'align-items-center',
                                          'g-5'
                                      )}
                                  >
                                      {questionNumber
                                          ? `Q${questionNumber}`
                                          : ''}
                                      {answer?.status === 'running' && (
                                          <RunningLabel />
                                      )}
                                  </div>
                              ),
                              secondary: true,
                          },
                      ]
                    : []
            }
            closeButtonLink={route('assessmentAnswers', assessmentId!)}
            sidebarContent={
                <>
                    {answer && (
                        <Sidebar>
                            <SidebarSection title="Properties">
                                <p className={cx('secondary', 'm-t-30')}>
                                    Outcome
                                </p>
                                {answer.status === 'error' && (
                                    <Tooltip
                                        content={
                                            answer.error ||
                                            'No error description.'
                                        }
                                    >
                                        <OutcomeBadge
                                            label="Error"
                                            color={
                                                themeVariables.colors.primary
                                            }
                                        />
                                    </Tooltip>
                                )}
                                {answer.status === 'new' && (
                                    <OutcomeBadge
                                        label="New"
                                        color={themeVariables.colors.info}
                                    />
                                )}
                                {answer.status === 'ready' && (
                                    <OutcomeDropdown
                                        answer={answer}
                                        outcomes={outcomes}
                                        triggerClassName="regular"
                                    />
                                )}
                                {answer.status === 'running' && (
                                    <div style={{ width: '115px' }}>
                                        <SkeletonParagraph
                                            fontSize={20}
                                            fullLine
                                        />
                                    </div>
                                )}

                                <p className={cx('secondary', 'm-t-30')}>
                                    Status
                                </p>
                                {answer.status === 'ready' && (
                                    <ReviewStatusDropdown
                                        answer={answer}
                                        triggerClassName={cx(
                                            'regular',
                                            'no-shadow'
                                        )}
                                    />
                                )}
                                {answer.status !== 'ready' && (
                                    <div style={{ width: '115px' }}>
                                        <SkeletonParagraph
                                            fontSize={20}
                                            fullLine
                                        />
                                    </div>
                                )}

                                {(category1 || category2) && (
                                    <>
                                        <p
                                            className={cx(
                                                'secondary',
                                                'm-t-30'
                                            )}
                                        >
                                            Categories
                                        </p>
                                        <Categories>
                                            {category1 && (
                                                <Category>{category1}</Category>
                                            )}
                                            {category2 && (
                                                <Category>{category2}</Category>
                                            )}
                                        </Categories>
                                    </>
                                )}
                            </SidebarSection>
                        </Sidebar>
                    )}
                </>
            }
            headerCTAContent={
                answer && (
                    <div className={cx('d-flex', 'align-items-center', 'g-10')}>
                        <Button
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'g-5'
                            )}
                            $variant="secondary"
                            onClick={() => runAnswer.mutate(answer)}
                            disabled={answer.status === 'running'}
                        >
                            <Icon name="repeat" /> Re-run question
                        </Button>

                        <Button
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'g-5'
                            )}
                            $variant="secondary"
                            onClick={openQuestionEditModal}
                            disabled={answer.status === 'running'}
                        >
                            <Icon name="edit" /> Edit question
                        </Button>

                        <Button
                            className={cx(
                                'd-flex',
                                'align-items-center',
                                'g-5'
                            )}
                            $variant="secondary"
                            onClick={triggerDeleteAnswer}
                            disabled={answer.status === 'running'}
                        >
                            <Icon name="trash" /> Delete question
                        </Button>
                    </div>
                )
            }
        >
            <>
                <HorizontalLoadingIndicator
                    loading={isAssessmentFetching || isAnswerFetching}
                />
                <InnerContent>
                    <h3 className="m-b-35">{answer?.question}</h3>
                    {answer && answer.status !== 'running' && (
                        <>
                            <AnswerContent answer={answer} />

                            {answer.sourceQuestion?.criteria && (
                                <>
                                    <OutcomeCriteriaHeading className="m-t-50">
                                        Question outcome criteria
                                    </OutcomeCriteriaHeading>
                                    <OutcomesContainer>
                                        <QuestionOutcomeGrid>
                                            {answer.sourceQuestion?.criteria.map(
                                                (c) => (
                                                    <QuestionOutcome key={c.id}>
                                                        <QuestionOutcomeDotContainer>
                                                            <QuestionOutcomeDot
                                                                style={{
                                                                    backgroundColor:
                                                                        c
                                                                            .outcome
                                                                            .color,
                                                                }}
                                                            />
                                                            <span>
                                                                {
                                                                    c.outcome
                                                                        .label
                                                                }
                                                            </span>
                                                        </QuestionOutcomeDotContainer>
                                                        <div>{c.criterion}</div>
                                                    </QuestionOutcome>
                                                )
                                            )}
                                        </QuestionOutcomeGrid>
                                    </OutcomesContainer>
                                </>
                            )}

                            <Separator className="m-t-40" />

                            <Accordion
                                title={`${references.length} References`}
                                className={cx('m-t-40', 'm-b-15')}
                            >
                                {references.map((r, index) => (
                                    <DocumentTile
                                        key={index}
                                        className="m-b-10"
                                    >
                                        <ReferenceBadge>
                                            Ref. {r.index}
                                        </ReferenceBadge>
                                        {!r.document && !r.url && (
                                            <span>[Invalid reference]</span>
                                        )}
                                        {r.document && (
                                            <DocumentLink
                                                to="#"
                                                onClick={() => {
                                                    downloadDocument(
                                                        r.document as DataRoomDocument
                                                    )
                                                }}
                                            >
                                                <FileTypeIcon
                                                    fileName={
                                                        r.document
                                                            .originalFileName
                                                    }
                                                />
                                                {getReferenceTitleWithSections(
                                                    r
                                                )}
                                                <StyledLinkIcon name="exportArrow" />
                                            </DocumentLink>
                                        )}
                                        {r.url && (
                                            <DocumentLink
                                                to={r.url}
                                                target="_blank"
                                            >
                                                <FileTypeIcon
                                                    fileName={r.url}
                                                />
                                                {getReferenceTitleWithSections(
                                                    r
                                                )}
                                                <StyledLinkIcon name="exportArrow" />
                                            </DocumentLink>
                                        )}
                                    </DocumentTile>
                                ))}
                            </Accordion>
                            <Accordion
                                title={`${projectDataRoomMatches.length} Data room matches`}
                                className="m-b-15"
                            >
                                {projectDataRoomMatches?.map((m, index) => (
                                    <div key={index}>
                                        <DocumentTile
                                            key={index}
                                            className="m-b-10"
                                        >
                                            {!m.dataRoomDocument && (
                                                <span>[Deleted document]</span>
                                            )}
                                            {m.dataRoomDocument && (
                                                <DocumentLink
                                                    to="#"
                                                    onClick={() => {
                                                        downloadDocument(
                                                            m.dataRoomDocument as DataRoomDocument
                                                        )
                                                    }}
                                                >
                                                    <FileTypeIcon
                                                        fileName={
                                                            m.dataRoomDocument
                                                                .originalFileName
                                                        }
                                                    />
                                                    {m.title ||
                                                        m.dataRoomDocument
                                                            .originalFileName}
                                                    <StyledLinkIcon name="exportArrow" />
                                                </DocumentLink>
                                            )}
                                        </DocumentTile>
                                        <p className={cx('m-t-15', 'm-b-30')}>
                                            {m.content.length >
                                            MAX_CONTENT_LENGTH
                                                ? `${m.content.substring(0, MAX_CONTENT_LENGTH)}...`
                                                : m.content}
                                        </p>
                                    </div>
                                ))}
                            </Accordion>
                            <Accordion
                                title={`${precedentsDataRoomMatches.length} Precedent matches`}
                                className="m-b-15"
                            >
                                {precedentsDataRoomMatches?.map((m, index) => (
                                    <div key={index}>
                                        <DocumentTile
                                            key={index}
                                            className="m-b-10"
                                        >
                                            {!m.dataRoomDocument && (
                                                <span>[Deleted document]</span>
                                            )}
                                            {m.dataRoomDocument && (
                                                <DocumentLink
                                                    to="#"
                                                    onClick={() => {
                                                        downloadDocument(
                                                            m.dataRoomDocument as DataRoomDocument
                                                        )
                                                    }}
                                                >
                                                    <FileTypeIcon
                                                        fileName={
                                                            m.dataRoomDocument
                                                                .originalFileName
                                                        }
                                                    />
                                                    {m.title ||
                                                        m.dataRoomDocument
                                                            .originalFileName}
                                                    <StyledLinkIcon name="exportArrow" />
                                                </DocumentLink>
                                            )}
                                        </DocumentTile>
                                        <p className={cx('m-t-15', 'm-b-30')}>
                                            {m.content.length >
                                            MAX_CONTENT_LENGTH
                                                ? `${m.content.substring(0, MAX_CONTENT_LENGTH)}...`
                                                : m.content}
                                        </p>
                                    </div>
                                ))}
                            </Accordion>
                            <Accordion
                                title={`${webMatches?.length ?? 0} Web matches`}
                            >
                                {webMatches?.map((m, index) => (
                                    <div key={index}>
                                        <DocumentTile
                                            key={index}
                                            className="m-b-10"
                                        >
                                            <DocumentLink
                                                to={m.url!}
                                                target="_blank"
                                            >
                                                <FileTypeIcon
                                                    fileName={m.url!}
                                                />
                                                {m.title}
                                                <StyledLinkIcon name="exportArrow" />
                                            </DocumentLink>
                                        </DocumentTile>
                                        <p className={cx('m-t-15', 'm-b-30')}>
                                            {m.content.length >
                                            MAX_CONTENT_LENGTH
                                                ? `${m.content.substring(0, MAX_CONTENT_LENGTH)}...`
                                                : m.content}
                                        </p>
                                    </div>
                                ))}
                            </Accordion>
                        </>
                    )}
                    {answer && answer.status === 'running' && (
                        <>
                            <SkeletonParagraph
                                fontSize={14}
                                length={1100}
                                lineLengthRandomness={40}
                            />
                            <SkeletonParagraph
                                fontSize={60}
                                className="m-t-50"
                                fullLine
                            />
                            <SkeletonParagraph
                                fontSize={60}
                                className="m-t-15"
                                fullLine
                            />
                            <SkeletonParagraph
                                fontSize={60}
                                className="m-t-15"
                                fullLine
                            />
                        </>
                    )}
                </InnerContent>
            </>
        </FullPageLayout>
    )
}
