import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Icon } from './Icon'

const SidebarToggleRoot = styled.button`
    appearance: none;
    background: none;
    border: 0;
    color: ${themeVariables.palettes.neutral700};
    outline: 0 !important;
    padding: 0;

    svg {
        display: block;
    }
`

interface SidebarToggleProps
    extends Omit<HTMLAttributes<HTMLButtonElement>, 'children' | 'type'> {
    value?: boolean
}

export function SidebarToggle({ value = false, ...props }: SidebarToggleProps) {
    return (
        <SidebarToggleRoot {...props} type="button">
            {value ? <Icon name="sidebarOn" /> : <Icon name="sidebarOff" />}
        </SidebarToggleRoot>
    )
}
