import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { AssessmentDraft } from '../types/assessments'

export const emptyAssessmentDraft: AssessmentDraft = {
    id: undefined,
    name: undefined,
    projectId: undefined,
    modelId: undefined,
    questions: [],
    filteredQuestions: [],
    precedentDataRoomIds: [],
    selectedCategories1: [],
    selectedCategories2: [],
    assignedToId: undefined,
}

const assessmentDraft = atom<AssessmentDraft>({
    key: 'assessmentDraft',
    default: emptyAssessmentDraft,
})

export const useAssessmentDraft = (): AssessmentDraft =>
    useRecoilValue(assessmentDraft)

export const useUpdateAssessmentDraft = (): ((
    partialState: Partial<AssessmentDraft>
) => void) => {
    const setDraft = useSetRecoilState(assessmentDraft)

    return (partialState: Partial<AssessmentDraft>) =>
        setDraft((state) => ({ ...state, ...partialState }))
}
