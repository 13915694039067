import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { CgClose } from 'react-icons/cg'

export const TermsAndConditionsModal = (props: {
    onHide: () => void
    show: boolean
}) => {
    const { onHide } = props

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="conditions-modal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Consent for dApp Registration
                </Modal.Title>
                <CgClose className="conditions-modal__close" onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <p>
                    You are entering the preliminary registration of the Silta
                    dApp which is the first step in the Silta scoring process.
                    Registration is intended only for bonafide impact investment
                    projects. By accepting these terms and conditions you hereby
                    agree to this site’s (i){' '}
                    <a
                        href="https://silta.finance/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms
                    </a>
                    , (ii){' '}
                    <a
                        href="https://silta.finance/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    , (iii){' '}
                    <a
                        href="https://silta.finance/privacy-policy/#cookies"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Cookie Policy
                    </a>{' '}
                    and (iv){' '}
                    <a
                        href="https://silta.finance/terms-of-use/#disclaimers"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Legal Disclaimers
                    </a>
                    . In addition you agree that if you are prequalified and are
                    invited by Silta to participate in our Silta scoring, you
                    will be required to countersign our Due Diligence Consent
                    Letter. Silta retains the right to disapprove any
                    application in its sole discretion.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onHide}
                    className="w-100 conditions-modal__btn"
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
