import { useMemo } from 'react'
import { Outcome, ModelWithRelations } from 'silta-ai-backend'
import { ModelQuestionsFilter, expandFilter } from './filters'
import { FilterKeys } from '../types/filters'

type QuestionFromModel = ModelWithRelations['questions'][number]

interface UseFilteredModelQuestionsParams {
    questions: QuestionFromModel[]
    filter: ModelQuestionsFilter
    outcomesById: Partial<Record<string, Outcome>>
}

export function useFilteredModelQuestions(
    params: UseFilteredModelQuestionsParams
): QuestionFromModel[] {
    const { questions, filter, outcomesById } = params

    return useMemo(() => {
        if (Object.keys(filter).length === 0) {
            return questions
        }

        const result: QuestionFromModel[] = []

        const expandedFilter = expandFilter(
            filter,
            ModelQuestionsFilter.items.map(({ key }) => key)
        )

        function eligible(
            key: FilterKeys<ModelQuestionsFilter>,
            value: string | undefined
        ): boolean {
            if (!Object.keys(expandedFilter[key].value).length) {
                return true
            }

            const field = expandedFilter[key]

            if (!value) {
                return field.operator !== 'is'
            }

            switch (field.operator) {
                case 'is':
                    return field.value[value] === true
                case 'contains':
                    return Object.keys(field.value).some((v) =>
                        value.includes(v)
                    )
                case 'is not':
                default:
                    return !field.value[value]
            }
        }

        for (const question of questions) {
            let include = true

            include =
                include && eligible('category', question.category1 || undefined)

            include =
                include &&
                eligible('subCategory', question.category2 || undefined)

            include = include && eligible('content', question.content)

            if (include) {
                result.push(question)
            }
        }

        return result
    }, [questions, filter, outcomesById])
}

export const filterQuestionsByCategory = (
    questionsList: ModelWithRelations['questions'],
    selectedCategories: string[],
    field: 'category1' | 'category2'
) => {
    if (selectedCategories.length === 0) return questionsList

    return questionsList.filter((question) =>
        question[field]
            ? selectedCategories.includes(question[field] as string)
            : false
    )
}
