import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useDataRoomsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { route } from '../utils/routes'
import { useCreateDataRoomModal } from '../components/modals/CreateDataRoomModal'

export default function DataRoomListPage() {
    const [search, setSearch] = useState<string>('')
    const dataRoomsQuery = useDataRoomsQuery({
        search,
        hasNoProject: true,
    })
    const dataRooms = dataRoomsQuery.data || []

    const navigate = useNavigate()

    const createDataRoomModal = useCreateDataRoomModal()

    return (
        <Layout>
            <ListTopBar onSearch={(query) => setSearch(query)} />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Precedent Databases</h1>
                <Button $size="medium" onClick={() => createDataRoomModal()}>
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">Create Precedent</span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        render: (dataRoom) => (
                            <span className="emphasized">{dataRoom.name}</span>
                        ),
                        width: 'minmax(min-content, 90%)',
                    },
                    {
                        title: 'Documents',
                        key: 'documents',
                        render: (dataRoom) => (
                            <span>{dataRoom._count.documents}</span>
                        ),
                    },
                    {
                        title: 'Last update',
                        key: 'updatedAt',
                        render: (dataRoom) => (
                            <DateRenderer value={dataRoom.updatedAt} />
                        ),
                    },
                ]}
                data={dataRooms}
                onRowClick={(dataRoom) => {
                    navigate(route('dataRoom', dataRoom.id))
                }}
                stickyHeader
            />
        </Layout>
    )
}
