import React, { useCallback } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { toaster } from 'toasterhea'
import { DataRoomDocument } from 'silta-ai-backend'
import { downloadDocument } from 'utils/download'
import { useDataRoomDocumentsQuery, useProjectQuery } from '../utils/queries'
import { Button } from './Button'
import { route } from '../utils/routes'
import { Icon } from './Icon'
import {
    Table,
    TableNoContentContainer,
    TableNoContentIcon,
    TableNoContentIconContainer,
} from './Table'
import { FileTypeIcon } from './FileTypeIcon'
import { DateRenderer } from './renderers/DateRenderer'
import { Dropdown, DropdownContent } from './Dropdown'
import { DotdotdotButton } from './DotdotdotButton'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { ConfirmationModal } from './modals/ConfirmationModal'
import { Layer } from '../utils/layers'
import { useDeleteDocument } from '../utils/mutations'
import { Separator } from './Separator'

const DropdownIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`

export const ProjectDataRoomDocuments = ({
    projectId,
    tableTitle,
    className,
    limit,
    showUploadButton = false,
}: {
    projectId: string
    tableTitle: string
    className?: string
    limit?: number
    showUploadButton?: boolean
}) => {
    const { data: project, isFetching } = useProjectQuery({ projectId })
    const { data: allDocuments } = useDataRoomDocumentsQuery(
        project?.dataRoomId
    )

    const documents = limit ? allDocuments?.slice(0, limit) : allDocuments

    const navigate = useNavigate()

    const deleteDocument = useDeleteDocument()

    const confirmModal = toaster(ConfirmationModal, Layer.Modal)

    const triggerDeleteDocument = useCallback(async (doc: DataRoomDocument) => {
        try {
            const confirmed = await confirmModal.pop({
                title: 'Delete Document?',
                content: `Are you sure you want to delete document “${doc.originalFileName}”?`,
                confirmButtonText: 'Delete Document',
                cancelButtonText: 'Cancel',
            })
            if (confirmed) {
                deleteDocument.mutate({ documentId: doc.id, projectId })
            }
        } catch (e) {
            // do nothing
        }
    }, [])
    return (
        <div className={className}>
            <div
                className={cx(
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    'm-b-30'
                )}
            >
                <h3>{tableTitle}</h3>
                {allDocuments &&
                    allDocuments.length > 0 &&
                    showUploadButton && (
                        <div className={cx('d-flex', 'g-5')}>
                            <Button
                                $variant="secondary"
                                $size="small"
                                onClick={() =>
                                    navigate(
                                        route('projectDataRoom', projectId)
                                    )
                                }
                                className={cx('d-flex', 'g-5')}
                            >
                                <Icon name="upload" />
                                Upload Documents
                            </Button>
                            <Button
                                $variant="secondary"
                                $size="small"
                                onClick={() =>
                                    navigate(
                                        route('projectDataRoom', projectId)
                                    )
                                }
                            >
                                View all {allDocuments.length} documents
                            </Button>
                        </div>
                    )}
            </div>
            {documents && documents.length > 0 && (
                <Table
                    columns={[
                        {
                            key: 'originalFileName',
                            title: 'Name',
                            width: 'minmax(min-content, 90%)',
                            render: ({ originalFileName }) => (
                                <div className={cx('d-flex', 'g-10')}>
                                    <FileTypeIcon fileName={originalFileName} />
                                    {originalFileName}
                                </div>
                            ),
                        },
                        { key: 'wordCount', title: 'Words' },
                        {
                            key: 'createdAt',
                            title: 'Added on',
                            render: ({ createdAt }) => (
                                <DateRenderer value={createdAt} />
                            ),
                        },
                        {
                            key: 'meatballMenu',
                            title: '',
                            render: (doc) => (
                                <>
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                                flip
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        dismiss()
                                                        downloadDocument(doc)
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <DropdownIcon name="download" />
                                                    </SelectBasicItemIconWrap>
                                                    Download
                                                </SelectBasicItem>
                                                <SelectBasicItem
                                                    type="button"
                                                    onClick={() => {
                                                        dismiss()
                                                        triggerDeleteDocument(
                                                            doc
                                                        )
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <DropdownIcon name="trash" />
                                                    </SelectBasicItemIconWrap>
                                                    Delete
                                                </SelectBasicItem>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </>
                            ),
                        },
                    ]}
                    data={documents}
                    noHorizontalPadding
                />
            )}
            {documents && documents.length === 0 && !isFetching && (
                <>
                    <TableNoContentContainer>
                        <TableNoContentIconContainer>
                            <TableNoContentIcon name="upload" />
                        </TableNoContentIconContainer>
                        <div className={cx('large', 'emphasized')}>
                            Upload first Documents to Project Data Room
                        </div>
                        <div className="secondary">
                            Project Data Rooms will be great assets for
                            Evaluation Criteria{' '}
                        </div>
                        {showUploadButton && (
                            <Button
                                onClick={() =>
                                    navigate(
                                        route('projectDataRoom', projectId)
                                    )
                                }
                                className={cx('d-flex', 'g-5', 'm-t-25')}
                            >
                                <Icon name="upload" />
                                Upload Documents
                            </Button>
                        )}
                    </TableNoContentContainer>
                    <Separator className="m-t-90" />
                </>
            )}
        </div>
    )
}
