import { Dropdown, DropdownContent } from 'components/Dropdown'
import { Checkbox } from 'components/Form/Checkbox'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
    SelectCheckboxItem,
} from 'components/Form/Select/SelectItemRenderer'
import { Icon } from 'components/Icon'
import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { Filter, FilterOperator, FilterValue } from 'types/filters'
import { useSetFilterCallback } from 'utils/filters'

function getFilterOperatorLabel(operator: FilterOperator, count: number) {
    return count === 1
        ? operator
        : operator === 'is'
          ? 'is any of'
          : 'is none of'
}

const FilterItemRoot = styled.div<{ $active?: boolean }>`
    border: 1px dashed ${themeVariables.palettes.neutral500};
    border-radius: 12px;
    height: 24px;
    color: ${themeVariables.palettes.neutral700};
    display: flex;
    align-items: center;
    padding-right: 8px;

    svg {
        display: block;
    }

    button {
        appearance: none;
        align-items: center;
        color: inherit;
        border: 0;
        background: none;
        height: 100%;
        display: flex;
        gap: 4px;
        outline: 0 !important;
    }

    ${({ $active = false }) =>
        $active &&
        css`
            svg {
                transform: rotate(45deg);
            }
        `}
`

const Separator = styled.div`
    height: 8px;
    width: 1px;
    background: ${themeVariables.palettes.neutral400};
`

const FilterItemValue = styled.small`
    color: ${themeVariables.palettes.neutral900};
    font-weight: 500;
`

const FilterItemInner = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
`

const FilterItemIconButton = styled.button`
    padding: 0 5px;
`

const FilterItemTitle = styled.div`
    color: ${themeVariables.colors.primary};
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
    padding: 0 12px;
    margin-bottom: 4px;

    small {
        font-weight: inherit;
    }
`

type FilterItemOption<O> = O extends (infer R)[] ? R : never

interface FilterItemProps<O> {
    fieldKey: string
    label: string
    labelPlural?: string
    optionLabelRenderer?(option: FilterItemOption<O>): ReactNode
    options: O
    value: FilterValue | undefined
}

export function FilterItem<
    O extends (
        | string
        | {
              id: string
              label?: string
          }
    )[],
>({
    fieldKey,
    optionLabelRenderer,
    label,
    labelPlural,
    options,
    value,
}: FilterItemProps<O>) {
    const setFilter = useSetFilterCallback()

    if (!options.length) {
        return null
    }

    const labelsById = useMemo(() => {
        const mapping: Partial<Record<string, string>> = {}

        for (const option of options) {
            const [id, label] =
                typeof option === 'string'
                    ? [option, option]
                    : [option.id, option.label || option.id]

            mapping[id] = label
        }

        return mapping
    }, [options])

    return (
        <Dropdown
            trigger={({ onClick }) => (
                <FilterItemRoot $active={!!value}>
                    <FilterItemIconButton
                        type="button"
                        onClick={() => {
                            if (!value) {
                                onClick()

                                return
                            }

                            setFilter((prev) => ({
                                ...prev,
                                [fieldKey]: [],
                            }))
                        }}
                    >
                        <div>
                            <Icon name="circlePlus" />
                        </div>
                    </FilterItemIconButton>
                    <FilterItemInner>
                        <button type="button" onClick={onClick}>
                            <small>{label}</small>
                        </button>
                        {value && value.value.length > 0 && (
                            <>
                                <Separator />
                                <Dropdown
                                    trigger={({ onClick }) => (
                                        <button type="button" onClick={onClick}>
                                            <small>
                                                {getFilterOperatorLabel(
                                                    value.operator,
                                                    value.value.length
                                                )}
                                            </small>
                                        </button>
                                    )}
                                >
                                    {(close) => (
                                        <DropdownContent $minWidth="100px">
                                            <SelectBasicItem
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        value.operator !== 'is'
                                                    ) {
                                                        setFilter((prev) => {
                                                            const prevField =
                                                                prev[fieldKey]

                                                            return !prevField
                                                                ? prev
                                                                : {
                                                                      ...prev,
                                                                      [fieldKey]:
                                                                          {
                                                                              ...prevField,
                                                                              operator:
                                                                                  'is',
                                                                          },
                                                                  }
                                                        })
                                                    }

                                                    close()
                                                }}
                                            >
                                                <SelectBasicItemIconWrap>
                                                    {value.operator ===
                                                        'is' && (
                                                        <Icon name="check" />
                                                    )}
                                                </SelectBasicItemIconWrap>
                                                {getFilterOperatorLabel(
                                                    'is',
                                                    value.value.length
                                                )}
                                            </SelectBasicItem>
                                            <SelectBasicItem
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        value.operator !==
                                                        'is not'
                                                    ) {
                                                        setFilter((prev) => {
                                                            const prevField =
                                                                prev[fieldKey]

                                                            return !prevField
                                                                ? prev
                                                                : {
                                                                      ...prev,
                                                                      [fieldKey]:
                                                                          {
                                                                              ...prevField,
                                                                              operator:
                                                                                  'is not',
                                                                          },
                                                                  }
                                                        })
                                                    }

                                                    close()
                                                }}
                                            >
                                                <SelectBasicItemIconWrap>
                                                    {value.operator ===
                                                        'is not' && (
                                                        <Icon name="check" />
                                                    )}
                                                </SelectBasicItemIconWrap>
                                                {getFilterOperatorLabel(
                                                    'is not',
                                                    value.value.length
                                                )}
                                            </SelectBasicItem>
                                        </DropdownContent>
                                    )}
                                </Dropdown>
                                <Separator />
                                <button type="button" onClick={onClick}>
                                    <FilterItemValue>
                                        {value.value.length !== 1
                                            ? `${value.value.length} ${labelPlural?.toLowerCase() || 'values'}`
                                            : value.value
                                                  .map(
                                                      (id) =>
                                                          labelsById[id] || id
                                                  )
                                                  .join(', ')}
                                    </FilterItemValue>
                                </button>
                            </>
                        )}
                    </FilterItemInner>
                </FilterItemRoot>
            )}
        >
            <DropdownContent $maxWidth="none">
                <FilterItemTitle>
                    <small>Filter by {label}</small>
                </FilterItemTitle>
                {options.map((item, index) => {
                    const [id, label] =
                        typeof item === 'string'
                            ? [item, item]
                            : [item.id, item.label || item.id]

                    const checked = value?.value.includes(id) === true

                    return (
                        <SelectCheckboxItem
                            $selected={checked}
                            key={`${id}-${index}`}
                            onClick={() => {
                                setFilter((prev) => {
                                    const current = prev[fieldKey] || {
                                        operator: 'is',
                                        value: [],
                                    }

                                    return {
                                        ...prev,
                                        [fieldKey]: {
                                            operator: current.operator,
                                            value: current.value.includes(id)
                                                ? current.value.filter(
                                                      (item) => item !== id
                                                  )
                                                : [...current.value, id],
                                        },
                                    }
                                })
                            }}
                        >
                            <Checkbox checked={checked} />
                            {optionLabelRenderer
                                ? optionLabelRenderer(
                                      item as FilterItemOption<O>
                                  )
                                : label}
                        </SelectCheckboxItem>
                    )
                })}
            </DropdownContent>
        </Dropdown>
    )
}

const ClearFilterButtonRoot = styled.button`
    appearance: none;
    background: none;
    border: 0;
    color: #007db7 !important;
    font-weight: 500;
    outline: 0 !important;
`

interface ClearFilterButtonProps<F extends Filter> {
    filter: F
}

export function ClearFilterButton<F extends Filter>(
    props: ClearFilterButtonProps<F>
) {
    const setFilter = useSetFilterCallback()

    const { filter } = props

    if (!Object.keys(filter).length) {
        return null
    }

    return (
        <ClearFilterButtonRoot
            onClick={() => {
                setFilter({})
            }}
        >
            <small>Clear Filter</small>
        </ClearFilterButtonRoot>
    )
}
