import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

/**
 * Extracts arbitrary information from the given resource and memoize it.
 */
export function useExtract<A, R>(
    arg: A,
    fn: (arg: A) => R,
    deps: unknown[] = []
) {
    const fnRef = useRef(fn)

    if (fnRef.current !== fn) {
        fnRef.current = fn
    }

    return useMemo(() => fnRef.current(arg), [arg, ...deps])
}

/**
 * Returns a route param value at a given key. It throws if the value is empty.
 */
export function useRequiredParam(key: string) {
    const value = useParams()[key]

    if (!value) {
        throw new Error(`Invalid context (missing "${key}" param`)
    }

    return value
}

interface UseOnOutsideClickEffectParams {
    containerRef: RefObject<HTMLElement>
    callback(): void
}

/**
 * Calls `params.callback` if a `mousedown` event occured outside of a given container.
 */
export function useOnOutsideClickEffect(params: UseOnOutsideClickEffectParams) {
    const { containerRef, callback } = params

    const callbackRef = useRef(callback)

    if (callbackRef.current !== callback) {
        callbackRef.current = callback
    }

    useEffect(
        function setUpGlobalMouseDownEvents() {
            function onMouseDown(e: MouseEvent) {
                const { target } = e

                if (!(target instanceof Element)) {
                    return
                }

                const { current: container } = containerRef

                if (!container) {
                    return
                }

                if (!container.contains(target)) {
                    callbackRef.current()
                }
            }

            window.addEventListener('mousedown', onMouseDown)

            return () => {
                window.removeEventListener('mousedown', onMouseDown)
            }
        },
        [containerRef]
    )
}

/**
 * Calls `fn` for each specific keyboard key hit.
 */
export function useGlobalKeyDownEffect(
    key: string | RegExp,
    fn: () => void,
    { preventDefault = false } = {}
) {
    const fnRef = useRef(fn)

    if (fnRef.current !== fn) {
        fnRef.current = fn
    }

    const keyRef = useRef(key)

    if (keyRef.current !== key) {
        keyRef.current = key
    }

    useEffect(
        function handleGlobalKeyDown() {
            function onKeyDown(e: KeyboardEvent) {
                if (
                    /select|input|textarea/i.test(
                        (e.target as HTMLElement).tagName
                    )
                ) {
                    return
                }

                const { current: k } = keyRef

                const match =
                    typeof k === 'string' ? e.key === k : k.test(e.key)

                if (match) {
                    fnRef.current()
                }

                if (match && preventDefault) {
                    e.preventDefault()
                }
            }

            window.addEventListener('keydown', onKeyDown)

            return () => {
                window.removeEventListener('keydown', onKeyDown)
            }
        },
        [key, preventDefault]
    )
}

export function useIsShiftPressed() {
    const [isShiftPressed, setIsShiftPressed] = useState(false)

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === 'Shift') {
                setIsShiftPressed(true)
            }
        }

        function handleKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Shift') {
                setIsShiftPressed(false)
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        window.addEventListener('keyup', handleKeyUp)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)

            window.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    return isShiftPressed
}
