import { Icon } from 'components/Icon'
import { ToolbarButton, ToolbarButtonProps } from 'components/Toolbar'
import React from 'react'
import { styled } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'

interface FilterButtonProps extends ToolbarButtonProps {
    count?: number
}

const FilterButtonCount = styled.div`
    align-items: center;
    background: ${themeVariables.palettes.brand600};
    border-radius: 6px;
    color: ${themeVariables.palettes.neutral100};
    display: flex;
    font-size: 11px;
    height: 13px;
    justify-content: center;
    letter-spacing: -0.4px;
    line-height: normal;
    min-width: 13px;
`

export function FilterButton({ count, ...props }: FilterButtonProps) {
    return (
        <ToolbarButton
            {...props}
            prepend={
                <div>
                    <Icon name="filter" />
                </div>
            }
            append={
                count != null && (
                    <FilterButtonCount>
                        <div>{count}</div>
                    </FilterButtonCount>
                )
            }
        />
    )
}
