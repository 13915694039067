import cx from 'classnames'
import { Table } from 'components/Table'
import { DateRenderer } from 'components/renderers/DateRenderer'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useAssessmentsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { route } from '../utils/routes'
import { AssessmentProgress } from '../components/AssessmentProgress'

export default function AssessmentListPage() {
    const navigate = useNavigate()

    const [search, setSearch] = useState<string>('')
    const assessmentsQuery = useAssessmentsQuery({
        search,
    })
    const assessments = assessmentsQuery.data || []

    return (
        <Layout>
            <ListTopBar onSearch={(query) => setSearch(query)} />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Assessments</h1>
                <Button
                    $size="medium"
                    onClick={() => navigate(route('assessmentCreate'))}
                >
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">Create assessment</span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        render: (assessment) => (
                            <span className="emphasized">
                                {assessment.name}
                            </span>
                        ),
                        width: 'minmax(min-content, 20%)',
                    },
                    {
                        key: 'projectId',
                        title: 'Project ID',
                        render: (assessment) => assessment.project.customId,
                    },
                    {
                        key: 'projectName',
                        title: 'Project name',
                        render: (assessment) => assessment.project.name,
                    },
                    {
                        key: 'evaluationCriteria',
                        title: 'Evaluation Criteria',
                        render: (assessment) => assessment.model?.name,
                    },
                    {
                        key: 'createdAt',
                        title: 'Created',
                        render: (assessment) => (
                            <DateRenderer value={assessment.createdAt} />
                        ),
                        width: 'minmax(min-content, 10%)',
                    },
                    {
                        key: 'progress',
                        title: 'Progress',
                        render: (assessment) => (
                            <AssessmentProgress
                                assessment={assessment}
                                showPercentage
                            />
                        ),
                    },
                ]}
                data={assessments}
                onRowClick={(assessment) => {
                    navigate(route('assessment', assessment.id))
                }}
                stickyHeader
            />
        </Layout>
    )
}
