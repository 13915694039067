import React from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'
import { Categories, Category } from './Categories'

const QuestionContainer = styled.div`
    display: flex;
    padding: 24px 16px;
    background-color: ${themeVariables.colors.backgroundContainer};
    border-radius: 8px;
    gap: 16px;
`

const QuestionNumber = styled.span`
    color: ${themeVariables.colors.disabled};
    font-size: ${themeVariables.typography.fontSizes.large};
    line-height: ${themeVariables.typography.lineHeight.large};
`

const QuestionContent = styled.p`
    font-size: ${themeVariables.typography.fontSizes.large};
    line-height: ${themeVariables.typography.lineHeight.large};
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
`

const StyledCategory = styled(Category)`
    background-color: ${themeVariables.colors.backgroundContainer};
`

export const QuestionElement = ({
    number,
    content,
    category1,
    category2,
    className,
}: {
    number: number
    content: string
    category1: string
    category2: string
    className?: string
}) => (
    <QuestionContainer className={className}>
        <QuestionNumber>Q{number}</QuestionNumber>
        <div>
            <QuestionContent>{content}</QuestionContent>
            {(category1 || category2) && (
                <Categories>
                    {category1 && <StyledCategory>{category1}</StyledCategory>}
                    {category2 && <StyledCategory>{category2}</StyledCategory>}
                </Categories>
            )}
        </div>
    </QuestionContainer>
)
