import { useEffect } from 'react'
import { ReportWithRelations } from 'silta-ai-backend'
import { apiClient } from './clients'
import { invalidateReportQuery } from './queries'
import { themeVariables } from '../themes/themeVariables'

export const getProgressColor = (status: string) => {
    switch (status) {
        case 'running':
            return themeVariables.colors.attention
        case 'ready':
            return themeVariables.colors.success
        default:
            return themeVariables.colors.primary
    }
}

export function useRunningReportPollEffect(report: ReportWithRelations | null) {
    const { id: reportId = undefined } = report || {}

    const isRunning =
        report?.status === 'running' ||
        report?.summaryStatus === 'running' ||
        report?.summaryStatus === 'new'

    useEffect(
        function pollReport() {
            let mounted = true

            if (reportId && isRunning) {
                ;(async () => {
                    for (;;) {
                        if (!mounted) {
                            return
                        }
                        try {
                            const innerReport =
                                await apiClient.getReport(reportId)

                            if (
                                !!innerReport.blobId ||
                                !!innerReport.summary ||
                                report.progress !== innerReport.progress
                            ) {
                                await invalidateReportQuery(reportId)
                            }
                        } catch (e) {
                            console.warn('Getting report failed', e)
                        }

                        await new Promise<void>((resolve) => {
                            setTimeout(resolve, 5000)
                        })
                    }
                })()
            }

            return () => {
                mounted = false
            }
        },
        [reportId, isRunning]
    )
}
