import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { QuestionForm } from './Form/QuestionForm'
import { themeVariables } from '../themes/themeVariables'
import { Button } from './Button'
import { useCreateAnswer, useRunAnswer } from '../utils/mutations'
import { Icon } from './Icon'

const AnswerContainer = styled.div`
    padding: 24px;
    background-color: ${themeVariables.colors.backgroundContainer};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const FormContainer = styled.div`
    background-color: ${themeVariables.colors.backgroundSurface};
    border-radius: 4px;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const Spinner = styled(Icon)`
    color: ${themeVariables.colors.backgroundSurface};
    width: 14px;
    height: 14px;
    svg {
        width: 14px;
        height: 14px;
    }
`

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const NewQuestion = ({ assessmentId }: { assessmentId: string }) => {
    const [question, setQuestion] = useState('')

    const createAnswer = useCreateAnswer(assessmentId)
    const runAnswer = useRunAnswer()

    const handleCreateAnswer = useCallback(async () => {
        const answer = await createAnswer.mutateAsync({ question })
        await runAnswer.mutateAsync(answer)
        setQuestion('')
    }, [question, createAnswer, runAnswer])

    return (
        <AnswerContainer>
            <p className="m-b-0 large emphasized">Add a new question</p>
            <FormContainer>
                <QuestionForm
                    value={{ question }}
                    onChange={({ question }) => setQuestion(question)}
                    displayCategorySelection={false}
                />
            </FormContainer>
            <ButtonContainer>
                <Button
                    disabled={
                        !question ||
                        createAnswer.isPending ||
                        runAnswer.isPending
                    }
                    onClick={handleCreateAnswer}
                >
                    {createAnswer.isPending || runAnswer.isPending ? (
                        <SpinnerContainer>
                            Saving
                            <Spinner name="spinner" />
                        </SpinnerContainer>
                    ) : (
                        'Save and run question'
                    )}
                </Button>
            </ButtonContainer>
        </AnswerContainer>
    )
}
