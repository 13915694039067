import React, { MouseEvent, ReactNode } from 'react'
import { AnswerWithRelations } from 'silta-ai-backend'
import styled, { css } from 'styled-components'
import { themeVariables } from 'themes/themeVariables'
import { useDeleteAnswer, useRunAnswer } from 'utils/mutations'
import { AnswerContent } from './AnswerContent'
import { DotdotdotButton } from './DotdotdotButton'
import { Dropdown, DropdownContent } from './Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { Icon } from './Icon'
import { CustomButton } from './Toolbar'
import { Tooltip } from './Tooltip'
import { Categories, Category } from './Categories'
import { OutcomeBadge } from './OutcomeBadge'
import { SkeletonParagraph } from './SkeletonParagraph'
import { useEditQuestionModal } from './modals/EditQuestionModal'

const AnswerDisplayHeader = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
`

const AnswerDisplayRoot = styled.div<{ $interactive?: boolean }>`
    background: ${themeVariables.palettes.neutral200};
    border-radius: 8px;
    padding: 24px;
    display: grid;
    gap: 24px 2px;
    grid-template-columns: 40px 1fr;
    align-items: center;

    ${CustomButton} {
        height: 20px;
    }

    ${({ $interactive = false }) =>
        $interactive &&
        css`
            cursor: pointer;
        `}
`

const QuestionNo = styled.small`
    color: ${themeVariables.palettes.neutral700};
`

const AnswerDisplayHeaderInner = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 20px;
    gap: 6px;
`

const Q = styled.div`
    font-weight: 500;
`

const A = styled.div`
    * + & {
        margin-top: 16px;
    }
`

interface AnswerDisplayProps {
    actionDropdown: ReactNode
    outcomeDropdown: ReactNode
    answer: AnswerWithRelations
    no: number
    onClick?(
        event: MouseEvent<HTMLDivElement>,
        answer: AnswerWithRelations
    ): void
    onDoubleClick?(
        event: MouseEvent<HTMLDivElement>,
        answer: AnswerWithRelations
    ): void
}

export function AnswerDisplay({
    actionDropdown,
    outcomeDropdown,
    answer,
    no,
    onClick,
    onDoubleClick,
}: AnswerDisplayProps) {
    const { question, status } = answer

    const category1 = answer.sourceQuestion?.category1

    const category2 = answer.sourceQuestion?.category2

    const deleteAnswer = useDeleteAnswer()

    const runAnswer = useRunAnswer()

    const openQuestionEditModal = useEditQuestionModal(answer)

    function clickHandler(
        callback?: (
            event: MouseEvent<HTMLDivElement>,
            answer: AnswerWithRelations
        ) => void
    ) {
        return (e: MouseEvent<HTMLDivElement>) => {
            if (!callback) {
                return
            }

            const { target } = e

            /**
             * Since the entire `AnswerDisplay` can be clickable let's proactively
             * filter out some click events. The rule is simple: ignore clicks from
             * within links, buttons, and input elements.
             *
             * Clicks in other tags and custom components can be cut short with
             * propagation prevention (i.e. `event.stopPropagation()`).
             */
            if (target instanceof Element) {
                let targetElement: Element | null = target

                while (targetElement) {
                    if (targetElement === e.currentTarget) {
                        break
                    }

                    if (/^a|button|input$/i.test(targetElement.nodeName)) {
                        return
                    }

                    targetElement = targetElement.parentElement
                }
            }

            callback?.(e, answer)
        }
    }

    return (
        <AnswerDisplayRoot
            onClick={clickHandler(onClick)}
            onDoubleClick={clickHandler(onDoubleClick)}
            $interactive={!!onClick}
        >
            <QuestionNo>Q{no}</QuestionNo>
            <div>
                <AnswerDisplayHeader>
                    <AnswerDisplayHeaderInner>
                        {status === 'error' && (
                            <Tooltip
                                content={
                                    answer.error || 'No error description.'
                                }
                            >
                                <OutcomeBadge
                                    label="Error"
                                    icon="warningTriangle"
                                    $variant="negative"
                                    className="error"
                                    color={themeVariables.colors.critical}
                                />
                            </Tooltip>
                        )}
                        {status === 'new' && (
                            <OutcomeBadge
                                label="New"
                                color={themeVariables.colors.info}
                            />
                        )}
                        {status === 'ready' && outcomeDropdown}
                        {status === 'running' && (
                            <OutcomeBadge
                                label="Running..."
                                icon="spinnerBlue"
                                color={themeVariables.colors.border}
                            />
                        )}
                        {actionDropdown}
                    </AnswerDisplayHeaderInner>
                    <Dropdown
                        trigger={({ onClick }) => (
                            <DotdotdotButton onClick={onClick} />
                        )}
                    >
                        {(dismiss) => (
                            <DropdownContent>
                                <SelectBasicItem
                                    disabled={status === 'running'}
                                    type="button"
                                    onClick={() => {
                                        dismiss()

                                        runAnswer.mutate(answer)
                                    }}
                                >
                                    <SelectBasicItemIconWrap>
                                        <Icon name="repeat" />
                                    </SelectBasicItemIconWrap>
                                    Re-run
                                </SelectBasicItem>
                                <SelectBasicItem
                                    type="button"
                                    onClick={() => {
                                        dismiss()
                                        openQuestionEditModal()
                                    }}
                                >
                                    <SelectBasicItemIconWrap>
                                        <Icon name="edit" />
                                    </SelectBasicItemIconWrap>
                                    Edit
                                </SelectBasicItem>
                                <SelectBasicItem
                                    type="button"
                                    onClick={() => {
                                        dismiss()

                                        // eslint-disable-next-line no-restricted-globals, no-alert
                                        if (confirm('Are you sure?')) {
                                            deleteAnswer.mutate(answer)
                                        }
                                    }}
                                >
                                    <SelectBasicItemIconWrap>
                                        <Icon name="trash" />
                                    </SelectBasicItemIconWrap>
                                    Delete
                                </SelectBasicItem>
                            </DropdownContent>
                        )}
                    </Dropdown>
                </AnswerDisplayHeader>
            </div>
            <div />
            <div>
                <Q>{question}</Q>
                <A>
                    {answer.status === 'ready' && (
                        <AnswerContent answer={answer} />
                    )}
                    {answer.status === 'running' && (
                        <SkeletonParagraph
                            fontSize={14}
                            length={440}
                            lineLengthRandomness={35}
                        />
                    )}
                </A>
                <Categories>
                    {category1 && <Category>{category1}</Category>}
                    {category2 && <Category>{category2}</Category>}
                </Categories>
            </div>
        </AnswerDisplayRoot>
    )
}
