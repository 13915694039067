import { Field, Formik, Form as FormikForm } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { object, string } from 'yup'
import countryList from 'country-list'
import { useNavigate } from 'react-router'
import { FullPageLayout } from '../components/FullPageLayout'
import { route } from '../utils/routes'
import { TextField } from '../components/Form/TextField'
import { TextAreaField } from '../components/Form/TextAreaField'
import { themeVariables } from '../themes/themeVariables'
import { Select } from '../components/Form/Select/Select'
import { sectors } from '../utils/sectors'
import {
    emptyProjectDraftDraft,
    useProjectDraft,
    useUpdateProjectDraft,
} from '../state/ProjectDraft.state'
import { useCreateProject } from '../utils/mutations'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { useUsersQuery } from '../utils/queries'
import { Button } from '../components/Button'

const countries = countryList.getNames()
const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
}))

const ProjectCreateValidationSchema = object().shape({
    customId: string().required('Field is required'),
    name: string().required('Field is required'),
    description: string().required('Field is required'),
    country: string(),
    sector: string(),
    assignedToId: string().required('Field is required'),
})

const PageContainer = styled.div`
    max-width: 666px;
    margin: 60px auto 0;
    display: flex;
`

const ProjectIdContainer = styled.div`
    max-width: 128px;
`

const ProjectNameContainer = styled.div`
    flex: 1;
`

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${themeVariables.colors.border};
    margin: 48px 0;
`

const SelectFieldsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px 25px;
    > * {
        flex: 1;
        min-width: calc(50% - 12.5px);
        max-width: calc(50% - 12.5px);
    }
`

const PrefixIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    margin-right: 8px;
`

export default function ProjectCreatePage() {
    const navigate = useNavigate()

    const projectDraft = useProjectDraft()

    const updateProjectDraft = useUpdateProjectDraft()

    const createProject = useCreateProject()

    const { data: users, isFetching: isUsersLoading } = useUsersQuery({})

    const [countrySearchTerm, setCountrySearchTerm] = useState('')

    const countryValueLabels = useMemo<Record<string, string>>(
        () =>
            Object.fromEntries(countries.map((country) => [country, country])),
        []
    )

    const filteredCountryOptions = useMemo(
        () =>
            countryOptions.filter((country) =>
                country.label
                    .toLowerCase()
                    .includes(countrySearchTerm.toLowerCase())
            ),
        [countrySearchTerm]
    )

    // Cleanup
    useEffect(
        () => () => {
            updateProjectDraft(emptyProjectDraftDraft)
        },
        []
    )

    return (
        <FullPageLayout
            closeButtonLink={route('projects')}
            breadcrumbs={[{ label: 'Create a new project' }]}
            headerCTAContent={
                <div className={cx('d-flex', 'g-10')}>
                    <Button
                        onClick={() => {
                            navigate(route('projects'))
                        }}
                        $variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            createProject.mutate(projectDraft)
                        }}
                        $variant="primary"
                        disabled={
                            !projectDraft.customId ||
                            !projectDraft.name ||
                            !projectDraft.description ||
                            !projectDraft.assignedToId ||
                            createProject.isPending
                        }
                    >
                        <div className={cx('d-flex', 'align-items-center')}>
                            <Icon name="plus" />
                            <span className="m-l-5">Create project</span>
                        </div>
                    </Button>
                </div>
            }
        >
            <HorizontalLoadingIndicator
                loading={createProject.isPending || isUsersLoading}
            />
            <PageContainer>
                <Formik
                    onSubmit={() => createProject.mutate(projectDraft)}
                    initialValues={{}}
                    validationSchema={ProjectCreateValidationSchema}
                >
                    <FormikForm className="w-100">
                        <h3 className="m-b-25">Project info</h3>

                        <div className={cx('d-flex', 'g-25', 'm-b-30')}>
                            <ProjectIdContainer>
                                <Field name="customId">
                                    {({
                                        field,
                                        form,
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }: any) => (
                                        <TextField
                                            {...field}
                                            value={projectDraft.customId || ''}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            label="Project ID"
                                            placeholder="e.g. 51234-012"
                                            onChange={(e) => {
                                                field.onChange(e)
                                                updateProjectDraft({
                                                    customId: e.target.value,
                                                })
                                            }}
                                        />
                                    )}
                                </Field>
                            </ProjectIdContainer>

                            <ProjectNameContainer>
                                <Field name="name">
                                    {({
                                        field,
                                        form,
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }: any) => (
                                        <TextField
                                            {...field}
                                            value={projectDraft.name || ''}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            label="Project name"
                                            placeholder="Please specify the project name"
                                            onChange={(e) => {
                                                field.onChange(e)
                                                updateProjectDraft({
                                                    name: e.target.value,
                                                })
                                            }}
                                        />
                                    )}
                                </Field>
                            </ProjectNameContainer>
                        </div>

                        <Field name="description">
                            {({
                                field,
                                form,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            }: any) => (
                                <TextAreaField
                                    {...field}
                                    value={projectDraft.description || ''}
                                    label="Project description"
                                    placeholder="Please provide a summary description of the project"
                                    errorMessage={
                                        form.touched[field.name] &&
                                        form.errors[field.name]
                                    }
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        field.onChange(event)
                                        updateProjectDraft({
                                            description: event.target.value,
                                        })
                                    }}
                                />
                            )}
                        </Field>

                        <Separator />

                        <h3 className="m-b-25">Additional information</h3>

                        <SelectFieldsContainer>
                            <Field name="country">
                                {({
                                    field,
                                    form,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                }: any) => (
                                    <Select
                                        {...field}
                                        value={projectDraft.country}
                                        valueLabels={countryValueLabels}
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        additionalLabel="Optional"
                                        label="Country"
                                        options={filteredCountryOptions}
                                        onSearchChange={setCountrySearchTerm}
                                        placeholder="Select a Country"
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            field.onChange(selection || '')
                                            updateProjectDraft({
                                                country: selection,
                                            })
                                        }}
                                        prefixContent={
                                            <PrefixIcon name="globe" />
                                        }
                                    />
                                )}
                            </Field>

                            <Field name="assignedToId">
                                {({
                                    field,
                                    form,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                }: any) => (
                                    <Select
                                        {...field}
                                        value={projectDraft.assignedToId}
                                        // containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label="Project Officer"
                                        options={(users || []).map((user) => ({
                                            value: user.id,
                                            label: user.name,
                                        }))}
                                        placeholder="Select a Project Officer"
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            field.onChange(selection || '')
                                            updateProjectDraft({
                                                assignedToId: selection,
                                            })
                                        }}
                                        prefixContent={
                                            <PrefixIcon name="userCircle" />
                                        }
                                    />
                                )}
                            </Field>

                            <Field name="sector">
                                {({
                                    field,
                                    form,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                }: any) => (
                                    <Select
                                        {...field}
                                        value={projectDraft.sector}
                                        // containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label="Sector"
                                        additionalLabel="Optional"
                                        options={sectors.map((sector) => ({
                                            value: sector,
                                            label: sector,
                                        }))}
                                        placeholder="Select a Sector"
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            field.onChange(selection || '')
                                            updateProjectDraft({
                                                sector: selection,
                                            })
                                        }}
                                        prefixContent={
                                            <PrefixIcon name="parthenon" />
                                        }
                                    />
                                )}
                            </Field>
                        </SelectFieldsContainer>
                    </FormikForm>
                </Formik>
            </PageContainer>
        </FullPageLayout>
    )
}
