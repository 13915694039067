import React, {
    ComponentType,
    FocusEventHandler,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react'
import styled from 'styled-components'
import { difference } from 'lodash-es'
import cx from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import { themeVariables } from '../../../themes/themeVariables'
import { Icon } from '../../Icon'
import {
    FormFieldSize,
    SelectableOption,
    SelectableOptionGroup,
    SelectItemRendererProps,
    SelectValueRendererProps,
    SelectValueType,
} from '../../../types/form'
import { SelectValueRenderer } from './SelectValueRenderer'
import { SelectItemRenderer } from './SelectItemRenderer'

const Dropdown = styled.div`
    width: 100%;
`

const DropdownButton = styled.div<{
    $isOpen: boolean
    $disabled?: boolean
    $invalid?: boolean
    $size: FormFieldSize
}>`
    position: relative;
    background-color: ${themeVariables.colors.backgroundSurface};
    border: 1px solid ${themeVariables.colors.border};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow:
        0px 1px 1px 0px #0000001f,
        0px 2px 5px 0px #30313d14;

    ${({ $isOpen }) =>
        $isOpen &&
        `
        border-color: ${themeVariables.palettes.brand300};
        outline: 1px solid ${themeVariables.palettes.brand300};
    `}

    ${({ $disabled }) =>
        $disabled &&
        `
        background-color: ${themeVariables.palettes.neutral200};
        cursor: not-allowed;
        color: ${themeVariables.colors.secondary};
    `}

    ${({ $invalid }) =>
        $invalid &&
        `
        border-color: ${themeVariables.colors.critical};
        outline: 1px solid ${themeVariables.colors.critical};
    `}

    ${({ $size }) => {
        switch ($size) {
            case 'small':
                return `
                padding: 2px 8px;
                font-size: ${themeVariables.typography.fontSizes.caption};
                line-height: ${themeVariables.typography.lineHeight.caption};
                `
            case 'medium':
                return `padding: 6px 8px;`
            case 'extraLarge':
                return `padding: 18px 12px;`
            case 'large':
            default:
                return `padding: 10px; 8px`
        }
    }}
`

const DropdownContent = styled.div`
    position: absolute;
    left: 0;
    bottom: -8px;
    transform: translateY(100%);
    width: 100%;
    border-radius: 4px;
    background-color: ${themeVariables.colors.backgroundSurface};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px 0;
    box-shadow:
        0px 8px 12px 0px #091e4226,
        0px 0px 1px 0px #091e424f;
`

const DropdownOptionsLoading = styled.div`
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeVariables.colors.disabled};
`

const DropdownLabelContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 3px;
    justify-content: space-between;
`

const DropdownStyledLabel = styled.div`
    font-weight: ${themeVariables.typography.fontWeight.bold};
`

const DropdownAdditionalLabelContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${themeVariables.colors.secondary};
`

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${themeVariables.colors.secondary};
`

const Spinner = styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 16px;
        height: 16px;
    }
`

const ClearButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ErrorMessage = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 3px;
    color: ${themeVariables.colors.critical};
    font-size: ${themeVariables.typography.fontSizes.caption};
`

export const Select = <Multiple extends boolean = false>({
    options,
    value,
    valueLabels,
    placeholder,
    label,
    additionalLabel,
    onChange,
    disabled = false,
    clearable = true,
    errorMessage,
    loadingOptions = false,
    multiple = false as Multiple,
    onSearchChange,
    searchDebounceTime,
    containerClassName,
    itemRenderer: ItemRenderer = SelectItemRenderer,
    itemLabelRenderer,
    valueRenderer: ValueRenderer = SelectValueRenderer,
    size = 'large',
    prefixContent,
    onBlur,
    id,
    name,
}: {
    options: (SelectableOption | SelectableOptionGroup)[]
    value: SelectValueType<Multiple>
    valueLabels?: Record<string, string>
    placeholder?: string
    label?: ReactNode
    additionalLabel?: ReactNode
    onChange: (selection: SelectValueType<Multiple> | undefined) => void
    disabled?: boolean
    clearable?: boolean
    errorMessage?: ReactNode
    loadingOptions?: boolean
    multiple?: Multiple
    onSearchChange?: (searchValue: string) => void
    searchDebounceTime?: number
    containerClassName?: string
    itemRenderer?: ComponentType<SelectItemRendererProps>
    itemLabelRenderer?: (option: SelectableOption) => ReactNode
    valueRenderer?: ComponentType<SelectValueRendererProps>
    size?: FormFieldSize
    prefixContent?: ReactNode
    onBlur?: FocusEventHandler<HTMLSelectElement>
    id?: string
    name?: string
}) => {
    const [isOpen, setIsOpen] = useState(false)

    // for handling the blur event which is used by Formik
    const nativeInput = useMemo(() => {
        const el = document.createElement('select')
        el.setAttribute('id', id ?? '')
        el.setAttribute('name', name ?? '')
        return el
    }, [id, name])

    const triggerOnBlur = (val: SelectValueType<Multiple> | undefined) => {
        setTimeout(() => {
            onBlur?.({
                ...new FocusEvent('blur'),
                currentTarget: nativeInput,
                target: nativeInput,
                id,
                name,
                value: val,
            } as unknown as React.FocusEvent<HTMLSelectElement>)
        }, 0)
    }

    const changeOpenState = (
        newOpenState: boolean,
        newValue?: SelectValueType<Multiple>
    ) => {
        if (isOpen === newOpenState) {
            // don't do anything if the state is the same
            return
        }
        setIsOpen(newOpenState)
        if (!newOpenState) {
            // trigger blur if we are closing the dropdown
            triggerOnBlur(newValue || value)
        }
    }

    const handleChange = useCallback(
        (val: string) => {
            if (Array.isArray(value)) {
                if (value.includes(val)) {
                    const newValue = value.filter(
                        (item) => item !== val
                    ) as SelectValueType<Multiple>
                    onChange(newValue.length > 0 ? newValue : undefined)
                } else {
                    const newValue = [...value, val]
                    onChange(newValue as SelectValueType<Multiple>)
                }
            } else {
                onChange(val as SelectValueType<Multiple>)
                changeOpenState(false, val as SelectValueType<Multiple>)
            }
        },
        [value, onChange, changeOpenState]
    )

    return (
        <OutsideClickHandler onOutsideClick={() => changeOpenState(false)}>
            <Dropdown className={containerClassName}>
                <DropdownLabelContainer>
                    <DropdownStyledLabel>{label}</DropdownStyledLabel>
                    {additionalLabel && (
                        <DropdownAdditionalLabelContainer>
                            {additionalLabel}
                        </DropdownAdditionalLabelContainer>
                    )}
                </DropdownLabelContainer>
                <DropdownButton
                    onClick={() => {
                        if (!disabled) {
                            changeOpenState(!isOpen)
                        }
                    }}
                    $isOpen={isOpen}
                    $disabled={disabled}
                    $invalid={!!errorMessage}
                    $size={size}
                >
                    <div className={cx('d-flex', 'w-100')}>
                        {prefixContent}
                        <ValueRenderer
                            value={value}
                            valueLabels={valueLabels}
                            options={options}
                            onRemove={(removedValues) => {
                                if (Array.isArray(value)) {
                                    const newValue = difference(
                                        value,
                                        removedValues
                                    ) as SelectValueType<Multiple>
                                    onChange(
                                        newValue.length > 0
                                            ? newValue
                                            : undefined
                                    )
                                    triggerOnBlur(newValue)
                                } else if (removedValues.includes(value)) {
                                    onChange(undefined)
                                    triggerOnBlur(undefined)
                                }
                            }}
                            placeholderText={placeholder}
                            multiple={multiple}
                            onSearchChange={onSearchChange}
                            searchDebounceTime={searchDebounceTime}
                            disabled={disabled}
                        />
                    </div>
                    <IconsContainer>
                        {loadingOptions ? <Spinner name="spinner" /> : null}
                        {value && value.length > 0 && clearable ? (
                            <ClearButton
                                type="button"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    onChange(undefined)
                                    triggerOnBlur(undefined)
                                }}
                            >
                                <Icon name="clear" />
                            </ClearButton>
                        ) : null}
                        <Icon name="dropdownIcon" />
                    </IconsContainer>
                    {isOpen && (
                        <DropdownContent>
                            {loadingOptions ? (
                                <DropdownOptionsLoading>
                                    Loading...
                                </DropdownOptionsLoading>
                            ) : (
                                options.map((option, index) => (
                                    <ItemRenderer
                                        key={index}
                                        option={option}
                                        onChange={handleChange}
                                        type={multiple ? 'checkbox' : 'normal'}
                                        value={value}
                                        itemLabelRenderer={itemLabelRenderer}
                                    />
                                ))
                            )}
                        </DropdownContent>
                    )}
                </DropdownButton>
                {errorMessage && (
                    <ErrorMessage>
                        <Icon name="warningCircle" />
                        {errorMessage}
                    </ErrorMessage>
                )}
            </Dropdown>
        </OutsideClickHandler>
    )
}
