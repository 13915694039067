import React, { useCallback, useState } from 'react'
import { toaster } from 'toasterhea'
import { Field, Form, Formik } from 'formik'
import { object, string } from 'yup'
import { BaseModal, BaseModalProps } from './BaseModal'
import { Button } from '../Button'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { Layer } from '../../utils/layers'
import { useCreateReport } from '../../utils/mutations'

import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { TextField } from '../Form/TextField'
import {
    useAssessmentsQuery,
    useReportTemplatesQuery,
} from '../../utils/queries'
import { Select } from '../Form/Select/Select'

interface CreateReportModalProps extends Omit<BaseModalProps, 'children'> {
    assessmentId?: string
    onConfirm: ({
        name,
        reportTemplateId,
        assessmentId,
    }: {
        name: string
        reportTemplateId: string
        assessmentId: string
    }) => Promise<void>
}

const ReportCreateValidationSchema = object().shape({
    name: string().required('Field is required'),
    reportTemplateId: string().required('Field is required'),
    assessmentId: string().required('Field is required'),
})

const CreateReportModal = ({
    assessmentId: initialAssessmentId,
    onConfirm,
    ...props
}: CreateReportModalProps) => {
    const [name, setName] = useState('')
    const [assessmentId, setAssessmentId] = useState(initialAssessmentId || '')
    const [reportTemplateId, setReportTemplateId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { data: assessments = [] } = useAssessmentsQuery({})
    const assessmentOptions = assessments.map((assessment) => ({
        label: assessment.name,
        value: assessment.id,
    }))
    const { data: reportTemplates = [] } = useReportTemplatesQuery({})
    const reportTemplateOptions = reportTemplates.map((reportTemplate) => ({
        label: reportTemplate.name,
        value: reportTemplate.id,
    }))

    return (
        <BaseModal {...props}>
            <EditorModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Create report</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer>
                    <p>
                        Reports are generated by the AI based on an assessment
                        and a report template.
                    </p>
                    <Formik
                        onSubmit={() => {
                            // todo
                        }}
                        initialValues={{}}
                        validationSchema={ReportCreateValidationSchema}
                    >
                        <Form>
                            <Field name="name">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <TextField
                                        {...field}
                                        value={name || ''}
                                        containerClassName="m-b-30"
                                        label="Report name"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        placeholder="Enter a title for the report"
                                        onChange={(e) => {
                                            field.onChange(e)
                                            setName(e.target.value)
                                        }}
                                    />
                                )}
                            </Field>
                            <Field name="assessmentId">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <Select
                                        {...field}
                                        value={assessmentId}
                                        containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label="Assessment"
                                        options={assessmentOptions}
                                        placeholder="Select an assessment"
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            setAssessmentId(selection)
                                        }}
                                    />
                                )}
                            </Field>
                            <Field name="reportTemplateId">
                                {({
                                    field,
                                    form,
                                }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any) => (
                                    <Select
                                        {...field}
                                        value={reportTemplateId}
                                        containerClassName="m-b-30"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        label="Report template"
                                        options={reportTemplateOptions}
                                        placeholder="Select a report template"
                                        onChange={(selection: string) => {
                                            form.setFieldValue(
                                                field.name,
                                                selection
                                            )
                                            setReportTemplateId(selection)
                                        }}
                                    />
                                )}
                            </Field>
                        </Form>
                    </Formik>
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                setIsLoading(true)
                                await onConfirm({
                                    name,
                                    assessmentId,
                                    reportTemplateId,
                                })
                                props.onResolve?.()
                            } catch (e) {
                                console.error('Error creating report', e)
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                        disabled={
                            !name ||
                            !assessmentId ||
                            !reportTemplateId ||
                            isLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </EditorModalRoot>
        </BaseModal>
    )
}

const modal = toaster(CreateReportModal, Layer.Modal)

export const useCreateReportModal = () => {
    const createReport = useCreateReport()

    return useCallback(
        async (assessmentId?: string) => {
            try {
                await modal.pop({
                    assessmentId,
                    onConfirm: async ({
                        name,
                        reportTemplateId,
                        assessmentId,
                    }) => {
                        await createReport.mutateAsync({
                            name,
                            reportTemplateId,
                            assessmentId,
                        })
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [createReport]
    )
}
