import styled from 'styled-components'
import { Icon } from '../Icon'
import { BaseButton } from '../BaseButton'
import { themeVariables } from '../../themes/themeVariables'

export const EditorModalRoot = styled.div`
    max-width: 520px;
    width: 90vw;
`

export const EditorModalTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 16px;
`
export const EditorModalTitle = styled.h3`
    margin: 0;
`

export const EditorModalCloseButton = styled(BaseButton)`
    color: ${themeVariables.colors.secondary};
    padding: 8px;
    transform: translateX(8px);
    &:hover {
        background-color: inherit;
    }
`

export const EditorModalContentContainer = styled.div`
    padding: 0 24px;
    color: ${themeVariables.colors.secondary};
`

export const EditorModalButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px 24px 24px;
`

export const EditorModalSpinner = styled(Icon)`
    color: ${themeVariables.colors.backgroundSurface};
    width: 14px;
    height: 14px;
    svg {
        width: 14px;
        height: 14px;
    }
`

export const EditorModalSpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`
