import React from 'react'
import { DataRoomDocument } from 'silta-ai-backend'
import styled from 'styled-components'
import cx from 'classnames'
import { toaster } from 'toasterhea'
import { useRequiredParam } from 'utils/misc'
import { downloadDocument } from 'utils/download'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import DataRoomDocumentUploader from '../components/DataRoomDocumentUploader'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import {
    invalidateDataRoomQuery,
    useDataRoomDocumentsQuery,
    useDataRoomQuery,
} from '../utils/queries'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import { route } from '../utils/routes'
import { DotdotdotButton } from '../components/DotdotdotButton'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../components/Form/Select/SelectItemRenderer'
import { Icon } from '../components/Icon'
import { Separator } from '../components/Separator'
import { Table } from '../components/Table'
import { FileTypeIcon } from '../components/FileTypeIcon'
import { DateRenderer } from '../components/renderers/DateRenderer'
import { ConfirmationModal } from '../components/modals/ConfirmationModal'
import { Layer } from '../utils/layers'
import { useDeleteDataRoom, useDeleteDocument } from '../utils/mutations'
import { Tooltip } from '../components/Tooltip'

function DoSearch() {}

const Pad = styled.div`
    padding: 40px;
`

const DropdownIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`

export default function DataRoomShowPage() {
    const id = useRequiredParam('id')

    const { data: dataRoom, isFetching } = useDataRoomQuery(id)
    const { data: documents } = useDataRoomDocumentsQuery(id)
    const deleteDocument = useDeleteDocument()
    const deleteDataRoom = useDeleteDataRoom()

    const confirmModal = toaster(ConfirmationModal, Layer.Modal)

    const triggerDeletePecedentDatabase = async () => {
        try {
            const confirm = await confirmModal.pop({
                title: 'Delete document',
                content: `Are you sure you want to delete data room "${dataRoom?.name}"?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            })
            if (confirm) {
                deleteDataRoom.mutate(id)
            }
        } catch (_) {
            // do nothing
        }
    }

    const triggerDeleteDocument = async (document: DataRoomDocument) => {
        try {
            const confirm = await confirmModal.pop({
                title: 'Delete document',
                content: `Are you sure you want to delete document "${document.originalFileName}"?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            })
            if (confirm) {
                deleteDocument.mutate({
                    documentId: document.id,
                    dataRoomId: id,
                })
            }
        } catch (_) {
            // do nothing
        }
    }

    return (
        <AccessDeniedHandler onSearch={DoSearch}>
            <Layout>
                <div>
                    <ListTopBar onSearch={DoSearch} />
                    <HorizontalLoadingIndicator
                        loading={
                            isFetching ||
                            deleteDocument.isPending ||
                            deleteDataRoom.isPending
                        }
                    />

                    <BreadcrumbsToolbar
                        currentPageName={dataRoom?.name || ''}
                        listPage={{
                            name: 'Precedent Databases',
                            route: route('dataRooms'),
                            icon: 'database',
                        }}
                        lhsAux={
                            <>
                                <Dropdown
                                    trigger={({ onClick }) => (
                                        <DotdotdotButton onClick={onClick} />
                                    )}
                                >
                                    {(dismiss) => (
                                        <DropdownContent>
                                            <Tooltip
                                                content={
                                                    documents &&
                                                    documents.length > 0
                                                        ? 'You cannot delete a non-empty database'
                                                        : ''
                                                }
                                            >
                                                <SelectBasicItem
                                                    type="button"
                                                    disabled={
                                                        documents != null &&
                                                        documents.length > 0
                                                    }
                                                    onClick={() => {
                                                        dismiss()
                                                        triggerDeletePecedentDatabase()
                                                    }}
                                                >
                                                    <SelectBasicItemIconWrap>
                                                        <Icon name="trash" />
                                                    </SelectBasicItemIconWrap>
                                                    Delete database
                                                </SelectBasicItem>
                                            </Tooltip>
                                        </DropdownContent>
                                    )}
                                </Dropdown>
                            </>
                        }
                    />
                    <Pad>
                        {dataRoom && (
                            <>
                                <h1 className="m-b-50">{dataRoom.name}</h1>
                                <DataRoomDocumentUploader
                                    dataRoomId={dataRoom.id}
                                    onDocumentAdded={() => {
                                        invalidateDataRoomQuery(id)
                                    }}
                                    onDocumentDeleted={() => {
                                        invalidateDataRoomQuery(id)
                                    }}
                                />
                                {documents != null && documents.length > 0 && (
                                    <>
                                        <Separator
                                            className={cx('m-t-50', 'm-b-50')}
                                        />
                                        <h3>All documents</h3>
                                        <p>
                                            This database has {documents.length}{' '}
                                            documents
                                        </p>

                                        <Table
                                            columns={[
                                                {
                                                    key: 'originalFileName',
                                                    title: 'Name',
                                                    width: 'minmax(min-content, 90%)',
                                                    render: ({
                                                        originalFileName,
                                                    }) => (
                                                        <div
                                                            className={cx(
                                                                'd-flex',
                                                                'g-10'
                                                            )}
                                                        >
                                                            <FileTypeIcon
                                                                fileName={
                                                                    originalFileName
                                                                }
                                                            />
                                                            {originalFileName}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: 'wordCount',
                                                    title: 'Words',
                                                },
                                                {
                                                    key: 'createdAt',
                                                    title: 'Added on',
                                                    render: ({ createdAt }) => (
                                                        <DateRenderer
                                                            value={createdAt}
                                                        />
                                                    ),
                                                },
                                                {
                                                    key: 'meatballMenu',
                                                    title: '',
                                                    render: (doc) => (
                                                        <>
                                                            <Dropdown
                                                                trigger={({
                                                                    onClick,
                                                                }) => (
                                                                    <DotdotdotButton
                                                                        onClick={
                                                                            onClick
                                                                        }
                                                                        flip
                                                                    />
                                                                )}
                                                            >
                                                                {(dismiss) => (
                                                                    <DropdownContent>
                                                                        <SelectBasicItem
                                                                            type="button"
                                                                            onClick={() => {
                                                                                dismiss()
                                                                                downloadDocument(
                                                                                    doc
                                                                                )
                                                                            }}
                                                                        >
                                                                            <SelectBasicItemIconWrap>
                                                                                <DropdownIcon name="download" />
                                                                            </SelectBasicItemIconWrap>
                                                                            Download
                                                                        </SelectBasicItem>
                                                                        <SelectBasicItem
                                                                            type="button"
                                                                            onClick={() => {
                                                                                dismiss()
                                                                                triggerDeleteDocument(
                                                                                    doc
                                                                                )
                                                                            }}
                                                                        >
                                                                            <SelectBasicItemIconWrap>
                                                                                <DropdownIcon name="trash" />
                                                                            </SelectBasicItemIconWrap>
                                                                            Delete
                                                                        </SelectBasicItem>
                                                                    </DropdownContent>
                                                                )}
                                                            </Dropdown>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                            data={documents}
                                            noHorizontalPadding
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Pad>
                </div>
            </Layout>
        </AccessDeniedHandler>
    )
}
