import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon, IconName } from './Icon'

const InfoBoxContainer = styled.div`
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid transparent;
    display: flex;
    gap: 15px;
`

const InfoBoxIcon = styled(Icon)<{ $iconColor: string }>`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: ${({ $iconColor }) => $iconColor};
`

export const InfoBox = ({
    children,
    iconName,
    borderColor,
    backgroundColor,
    textColor,
    iconColor,
    className,
}: {
    children: ReactNode
    iconName: IconName
    borderColor: string
    backgroundColor: string
    textColor: string
    iconColor: string
    className?: string
}) => (
    <InfoBoxContainer
        className={className}
        style={{ backgroundColor, color: textColor, borderColor }}
    >
        <InfoBoxIcon $iconColor={iconColor} name={iconName} />
        <div>{children}</div>
    </InfoBoxContainer>
)
